import classes from './Auth.module.css';
import AuthBackground from './AuthBackground';

const AuthModal = ({ setShowResetPasswordPage, children, title = '' }) => {
  return (
    <>
      <AuthBackground />
      <div className={classes['adapting-background']}>
        <div style={{ flex: '1 1 10px' }}></div>
        <div className={classes['floating-box']}>
          <div className={classes['login-div']}>{title}</div>
          {children}
        </div>
        <div style={{ flex: '1.7 1 10px' }}></div>
      </div>
    </>
  );
};

export default AuthModal;
