import './VideoContainerForUpload.css';
import ReactPlayer from 'react-player';
import Button from '@mui/material/Button';
import { Container } from '@material-ui/core';
import Control from './Control';
import React, { useState, useRef, useEffect } from 'react';
import { formatTime } from './format';
import classes from './../VideoComponent.module.css';
import { ellipse, scan } from 'ionicons/icons';
import AnnotateVideoForm from '../AnnotateVideoForm';
import { VideoToFrames, VideoToFramesMethod } from './VideoToFrame.ts';
import VideoSeparateUpload from './VideoSeparateUpload';
import Carousel from 'react-elastic-carousel';
import { useSelector } from 'react-redux';
import useFileExists from '../useFileExists';

let count = 0;
const VideoContainerForUpload = ({ videoId, url, onModalClose }) => {
  console.log('🤯 Videocontainer for upload rerender');
  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const ABLoopRef = useRef({
    start: 0,
    end: 1000,
  });

  const [thumbnailFile, setThumbnailFile] = useState(false);

  const currentTime = videoPlayerRef.current
    ? videoPlayerRef.current.getCurrentTime()
    : '00:00';
  const duration = videoPlayerRef.current
    ? videoPlayerRef.current.getDuration()
    : '00:00';

  const [countingComplete, setCountingComplete] = useState(false);
  const [annotationComplete, setAnnotationComplete] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [displayTapButton, setDisplayTapButton] = useState(false);
  const [errorInUpload, setErrorInUpload] = useState(null);
  const [label, setLabel] = useState('basic');
  const saveDuration = useRef();
  // console.log('saveDuration: ', saveDuration.current);

  const timePadding = 0; //0.18;
  // const currentTime = videoPlayerRef.current.getCurrentTime();

  const progressHandler = ({ playedSeconds }) => {
    // console.log({ playedSeconds });
    if (
      playedSeconds > ABLoopRef.current.end + timePadding ||
      playedSeconds < ABLoopRef.current.start - timePadding
    ) {
      videoPlayerRef.current.seekTo(ABLoopRef.current.start);
    } else if (playedSeconds >= videoPlayerRef?.current.getDuration() - 0.1) {
      onEnded();
    } else {
      // console.log({ playedSeconds });
      // const duration = videoPlayerRef?.current.getDuration();
      // console.log({ duration });
    }
  };

  const addBeatWithTap = () => {
    console.log('addbeatwithtap');
    if (beatLog.current?.length === 0) {
      setPlaying(true);
    }
    const currTime = videoPlayerRef.current?.getCurrentTime();
    beatLog.current.push(currTime);
    console.log('taaap');
    console.log(beatLog.current);
  };

  const onEnded = () => {
    console.log('onEnded');
    if (!countingComplete) {
      // if (!countingComplete) {
      console.log('ended from func in videocontainer for upload');
      if (beatLog.current?.length > 1) {
        finsishCountingFunc();
        setCountingComplete(true);
        // setCountingComplete(true);
        setPlaying(true);
      }
      videoPlayerRef.current.seekTo(0);
      setPlaying(true);
      // setVideoState({ ...videoState, playing: true });
    }
  };

  const onReady = () => {
    protoVideo.current.duration = videoPlayerRef.current.getDuration();
    console.log('🎞️ duration: ', videoPlayerRef.current.getDuration());
  };

  let beatLog = useRef([]);

  const beatPointers = useRef({ start: 0, end: 5 });

  const protoVideo = useRef({
    // owner: userId,
    sections: [],
    // folderLocation: [],
    primaryKeywords: [],
    secondaryKeywords: [],
    duration: 0,
  });
  const sections = protoVideo.current.sections;

  const startCountingClickHandler = async () => {
    setDisplayTapButton(true);
    videoPlayerRef.current.seekTo(0);
    setPlaying(false);
    console.log('😍 youre in the startcouning');
    saveDuration.current = videoPlayerRef.current.getDuration();
    function timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    // setVideoState({ ...videoState, playing: false, isCounting: true });
    let counter = document.getElementById('counter-new-vid');
    if (!counter) {
      counter = document.createElement('div');
      counter.className = 'eightCounts';
      counter.innerText =
        'Click the tap button to start the video and tap for each beat. This will help diving up the measures for labeling';
      counter.id = 'counter-new-vid';
      const parent = document.querySelector('.video_container-new-vid');
      if (parent) parent.append(counter);
    }

    // window.addEventListener('keydown', addBeatWithClick);
    console.log('await in start counting');
    await timeout(5000);
    counter.remove();
    // for (let i = 3; i >= 0; i--) {
    //   await timeout(3000);
    //   counter.innerText = i;
    // }

    // videoPlayerRef.current.seekTo(0);
    // setPlaying(true);
  };

  const labelClickHandler = () => {
    console.log('label click handler');
    videoPlayerRef.current.seekTo(ABLoopRef.current.start);
    let countLabel = 'none';
    const radioButtonCounts = document.getElementsByName('count-label');
    radioButtonCounts.forEach((el) => {
      if (el.checked) {
        countLabel = el.value;
      }
    });
    radioButtonCounts.forEach((el) => {
      if (el.value === 'none') {
        if (countLabel === 'none') {
          el.checked = true;
        }
      } else {
        if (el.value != countLabel) el.checked = true;
      }
    });

    // const labelElement = document.getElementById('label-input');
    const labelCopy = label.trim().toLowerCase();
    setLabel('basic');
    // labelElement.value = '';
    // labelElement.focus();
    document.getElementById('label-new-videos').focus();

    let keywordType = 'none';
    const keywordRadios = document.getElementsByName('keyword');
    // let checkedKeywordRadio;
    keywordRadios.forEach((el, index) => {
      if (el.checked) {
        keywordType = el.value;
        // checkedKeywordRadio = index
      }
    });
    // reset this radio value to 'none
    keywordRadios[2].checked = true;

    console.log(keywordType);
    if (keywordType !== 'none') {
      protoVideo.current[`${keywordType}s`].push(labelCopy);
    }

    const fourCountTimes = [];
    for (
      let i = beatPointers.current.start;
      i <= beatPointers.current.end;
      i++
    ) {
      fourCountTimes.push(beatLog.current[i]);
    }

    sections.push({
      fourCountTimes,
      label: labelCopy,
      countLabel,
    });
    console.log('Beat pointer end: ', beatPointers.current.end);
    console.log('beatLog.length - 1: ', beatLog?.current?.length - 1);
    console.log('beatlog  : ', beatLog.current);
    // console.log('fuck');
    if (beatPointers.current.end === beatLog.current?.length - 1) {
      console.log('😶‍🌫️ we in the LOOOOOP');

      setAnnotationComplete(true);
      return;
    }

    beatPointers.current.start = beatPointers.current.end;

    beatPointers.current.end += 4;
    beatPointers.current.end =
      beatPointers.current.start + 4 >= beatLog.current?.length - 1
        ? beatLog.current?.length - 1
        : beatPointers.current.start + 4;

    ABLoopRef.current.start = beatLog.current[beatPointers.current.start];
    ABLoopRef.current.end = beatLog.current[beatPointers.current.end];

    // console.log(sections);
  };

  const handleIncreaseBeadStep = () => {
    beatPointers.current.end++;
    ABLoopRef.current.end = beatLog.current[beatPointers.current.end];
  };

  const handleDecreaseBeadStep = () => {
    beatPointers.current.end--;
    ABLoopRef.current.end = beatLog.current[beatPointers.current.end];
  };

  function finsishCountingFunc() {
    console.log('finsishCountingFunc');
    beatLog.current.push(duration);
    // console.log('beatLog.current');
    // console.log(beatLog.current);

    ABLoopRef.current.start = beatLog.current[beatPointers.current.start];
    ABLoopRef.current.end = beatLog.current[beatPointers.current.end];
    // window.removeEventListener('keydown', addBeatWithClick);
    setCountingComplete(true);
    videoPlayerRef.current.seekTo(ABLoopRef.current.start);
  }

  return (
    <div className="video_container-new-vid">
      {!annotationComplete && (
        <VideoSeparateUpload
          style={{ height: '50%' }}
          ref={videoPlayerRef}
          ABLoopRef={ABLoopRef}
          url={url}
          progressHandler={progressHandler}
          addBeatWithTap={addBeatWithTap}
          setCountingComplete={setCountingComplete}
          countingComplete={countingComplete}
          onEnded={onEnded}
          playing={playing}
          displayTapButton={displayTapButton}
          onReady={onReady}
          onModalClose={onModalClose}
        />
      )}

      {!countingComplete && (
        <div style={{ margin: '15px' }}>
          <p>Would you like to annotate video?</p>
          <div className="annotate-buttons-question-container">
            <button
              className="yes-to-annotate-button"
              onClick={startCountingClickHandler}
            >
              Yes
            </button>
            <button
              className="annotate-secondary"
              onClick={finsishCountingFunc}
            >
              Finish
            </button>
            <button
              className="annotate-secondary"
              onClick={() => {
                console.log('skipaDDDD');
                saveDuration.current = videoPlayerRef.current.getDuration();
                setAnnotationComplete(true);
                setCountingComplete(true);
              }}
            >
              Skip
            </button>
          </div>
        </div>
      )}
      {countingComplete && !annotationComplete && (
        // {countingComplete && !annotationComplete && (
        <div style={{ margin: '10px' }}>
          <AnnotateVideoForm
            onIncreaseBeatStep={handleIncreaseBeadStep}
            onDecreaseBeatStep={handleDecreaseBeadStep}
            onLabelClickHandler={labelClickHandler}
            label={label}
            setLabel={setLabel}
          />
        </div>
      )}
      {/* {annotationComplete && <p>Annotation is completed</p>} */}
      {annotationComplete && (
        <div>
          {errorInUpload && <p style={{ color: 'red' }}>{errorInUpload}</p>}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              marginTop: '35px',
              overflow: 'hidden',
            }}
          >
            <div style={{ fontSize: '1.25rem', textAlign: 'center' }}>
              Select thumbnail image
            </div>
            <Carousel
              // renderPagination={() => {
              //   return <div>{/* Empty, or custom pagination */}</div>;
              // }}
              // renderArrow={customArrow}
              // breakPoints={breakPoints}
              showArrows={false} //{!isMobile}
              // showEmptySlots
              outerSpacing={0}
              // isRTL={true}
              itemPadding={[0, 0]}
            >
              {Array(Math.ceil(Number(saveDuration.current) / 30))
                .fill(1)
                .map((pic, index) => (
                  <img
                    style={{
                      width: '270px',
                      margin: '10px',
                      // borderRadius: '10px',
                      // border: 'none',
                      // index === selectedPicIndex ? '2px solid orange' : '',
                    }}
                    src={`${
                      process.env.REACT_APP_CLOUDFRONT
                    }videos/${url}/thumbs-0000${index + 1}.png`}
                    onClick={() => {
                      // protoVideo.current.thumbnail = pic;
                      console.log('protovideo array: ', protoVideo);
                      console.log('index of foto picked: ', index);
                      setThumbnailFile(`thumbs-0000${index + 1}.png`);
                      protoVideo.current.thumbnail = `thumbs-0000${
                        index + 1
                      }.png`;
                    }}
                  />
                ))}
            </Carousel>
          </div>
        </div>
      )}
      {thumbnailFile && (
        <div style={{ marginRight: '20px', textAlign: 'right' }}>
          <Button
            sx={{ my: 2 }}
            variant="contained"
            // startIcon={<FileUploadIcon />}
            onClick={async () => {
              const formData = new FormData();

              try {
                const response = await fetch(
                  `${process.env.REACT_APP_BACKEND}/api/v1/videos/update/${videoId}`,
                  {
                    method: 'PATCH',
                    credentials: 'include', // include, *same-origin, omit
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      ...protoVideo.current,
                      duration: saveDuration.current,
                    }),
                  }
                );

                console.log({ response });
                if (!response.ok) {
                  console.log('not able to update video');
                } else {
                  // const data = await response.json();
                  // openAnnotate();
                  onModalClose();
                  //   console.log(data.data.user);
                  //   dispatch(authActions.login(data.data.user));
                }
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

function dataURItoBlob(dataURI, callback) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var bb = new Blob([ab]);
  return bb;
}

export default VideoContainerForUpload;
