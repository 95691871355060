import { useEffect, useRef, useState } from 'react';
import classes from './AuthBackground.module.css';

export default function AuthBackground() {
  const imgEl = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const onImageLoaded = () => {
    // alert('loaded');
    setLoaded(true);
  };

  useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener('load', onImageLoaded);
      return () => imgElCurrent.removeEventListener('load', onImageLoaded);
    }
  }, [imgEl]);

  return (
    <div className={classes['background']}>
      <img
        ref={imgEl}
        style={loaded ? {} : { display: 'none' }}
        src="https://www.ubudstudio.com/wp-content/uploads/2019/01/social-dancing-blu-bar-ubud.jpg"
        className={`${classes['background-image']} ${classes['just-loaded']}`}
      ></img>
    </div>
  );
}
