import { margin } from '@mui/system';
import React, { forwardRef, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PracticeCardListItem from './PracticeCard/PracticeCardListItem';
import classes from './PracticeOuterContainer.module.css';
import './DroppableSection.css';

import DurationCounter from './DurationCounter';
import CircularProgressBar from './CircularProgressBar';
import GoalSummary from './GoalSummary';
import Modal from './Modal';
import NewGoal from './NewGoal';
import Card from '@mui/material/Card';
import { AnimatePresence, motion } from 'framer-motion';

const forwardedDroppableSection = forwardRef(function DroppableSection(
  {
    // columns,
    columnKey,
    i,
    items,
    goal,
    startDate,
    onTreeVideoClickHandler,
    practiceSectionsDisplayed,
    columns,
    setColumns,
    onChangeColumn,
    onAddNewColumn,
    setSectionBeingTimed,
    activeSections,
    setActiveSections,
    sectionBeingTimed,
  } // ref
) {
  const [hasGoal, setHasGoal] = useState(false);
  const [newGoal, setNewGoal] = useState(false);

  function updateCardWithCounts(id, count) {
    setColumns((prevCols) => {
      const nextCols = JSON.parse(JSON.stringify(prevCols));
      // const nextCols = [...prevCols];
      const nextSectionToChange = nextCols[i];
      const nextItems = nextSectionToChange.items;
      console.log({ nextItems });
      console.log({ id });
      const indexOfItem = nextItems.findIndex((el) => el.id == id);
      console.log({ indexOfItem });
      nextItems[indexOfItem].count = count;
      return nextCols;
    });
  }

  return (
    <div
      // layout
      // layoutId={`dropable-${i}`}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      {newGoal && (
        <Modal
          // xColor="white"
          fixedHeight={false}
          onModalClose={() => {
            setNewGoal(false);
          }}
        >
          <NewGoal
            sectionIndex={i}
            setColumns={setColumns}
            onClose={() => {
              setNewGoal(false);
            }}
          />
        </Modal>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignContent: 'center',
          width: '100%',
          height: '100%',
          // ref={ref}
        }}
      >
        {/* {i === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="goal-strip">Fuck yea</div>
          </div>
        )} */}
        <div
          className={classes['practice-summary-container']}
          style={{ margin: '0 2.5%' }}
        >
          <div
            className={`${classes['practice-heading']} ${classes['flex-row']}`}
          >
            <div className={classes['heading-practice-list']}>{columnKey}</div>
            {false && (
              <div
                className={`${classes['heading-duration']}`}
                // style={true && { maxWidth: '200px' }}
              >
                {false && columnKey != 'unSorted' && (
                  <>
                    {/* {hasGoal ? ( */}
                    {goal?.active ? (
                      <GoalSummary
                        index={i}
                        minutes={
                          5 *
                          items.reduce((acum, curr) => {
                            return acum + curr.count || 0;
                          }, 0)
                        }
                        goalMinutes={goal.minutes}
                        endDate={goal.endDate}
                        goalLength={goal.duration}
                        setColumns={setColumns}
                      />
                    ) : (
                      // <DurationCounter startDate={startDate} />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          fontSize: '11px',
                          flexBasis: '45px',
                        }}
                        onClick={() => {
                          // setHasGoal(true);
                          setNewGoal(true);
                        }}
                      >
                        <div
                          style={{
                            fontSize: '32px',
                          }}
                        >
                          +
                        </div>
                        <div
                          style={{
                            fontSize: '11px',
                            marginTop: '-6px',
                          }}
                        >
                          Goal
                        </div>
                      </div>
                    )}
                    {/* <CircularProgressBar width={70} percentage={20} /> */}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <Droppable droppableId={`${i}`}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="containing-droppable-container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflowY: 'scroll',
                flexGrow: 1,
                // width: '100%',
                // marginBottom: '60px',
              }}
            >
              {' '}
              <div
                // layout
                // layoutId={`fuckit${i}`}
                style={{
                  minHeight: '200px',
                  width: '95%',
                  // paddingBottom: '50px',
                  // display: 'flex',
                  // flexDirection: 'column',
                  // justifyContent: 'flex-start',
                  // alignItems: 'center',
                  //   minWidth: '200px',
                  // backgroundColor: ['red', 'yellow', 'blue'].at(i),
                  //   backgroundColor: snapshot.isDraggingOver
                  //     ? 'transparent' //'red'
                  //     : 'transparent',
                  // paddingBottom: '50px',
                }}
              >
                {/* <div
                  className={`card-and-stats  ${
                    practiceSectionsDisplayed === 'completedCards'
                      ? 'filter-div-practice-card'
                      : ''
                  }`}
                  // style={{ height: '100%' }}
                  // style={{ perspective: '1000px' }}
                >
                  <Card
                    className="card-sx goal-card"
                    sx={{
                      color: 'var(--text-light-gray)',
                      backgroundColor: 'rgba(0,0,0,.05)',
                      // backgroundColor: 'var(--main-purple-light)',
                      // color: 'var(--main-purple-no-transparancy)',
                    }}
                  >
                    <div
                      style={{
                        padding: '10px',
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      + Goal
                    </div>
                  </Card>
                  <div className="flexible-upvote-outer-container">
                    <div className={`stat-display-and-buttons end-caps`} />
                  </div>
                </div> */}
                {/* <AnimatePresence> */}
                {items.map((item, index) => {
                  if (!item) return;
                  // console.log({ item });
                  return (
                    <Draggable
                      draggableId={item?.id}
                      key={item?.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          // layout
                          // key={`pracCard-${index}-${columnKey}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          // {...provided.dragHandleProps}
                        >
                          <PracticeCardListItem
                            item={item}
                            index={index}
                            columns={columns}
                            setColumns={setColumns}
                            sourceIndex={i}
                            onChangeColumn={(destination) => {
                              onChangeColumn(
                                { droppableId: i, index },
                                destination
                              );
                            }}
                            onAddNewColumn={(name) => {
                              onAddNewColumn({ droppableId: i, index }, name);
                            }}
                            updateCount={(count) => {
                              updateCardWithCounts(item.id, count);
                            }}
                            //   title={section.label}
                            //   notes={section.notes}
                            //   id={section?.id}
                            //   videoData={section.video}
                            //   thumbnail={section.video.thumbnail}
                            //   sections={section.sectionLabels}
                            onTreeVideoClickHandler={onTreeVideoClickHandler}
                            //   sectionIndices={section.sections}
                            practiceSectionsDisplayed={
                              practiceSectionsDisplayed
                            }
                            //   looptime={section.looptime}
                            //   completedTimes={section.completedTimes}
                            dragHandleProps={provided.dragHandleProps}
                            setSectionBeingTimed={setSectionBeingTimed}
                            activeSectionTimer={activeSections.includes(
                              item?.id
                            )}
                            setActiveSections={setActiveSections}
                            isSectionBeingTimed={
                              sectionBeingTimed?.id === item?.id
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {/* </AnimatePresence> */}

                <div style={{ height: '52px', width: '100%' }}></div>
                {provided.placeholder}
              </div>{' '}
            </div>
          )}
        </Droppable>
      </div>
    </div>
    // </div>
  );
});

export default React.memo(forwardedDroppableSection);
