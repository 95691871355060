import React from 'react';
import './ThumbnailBadge.css';

export default function ThumbnailBadge({ text }) {
  return (
    // <div class="badge-overlay">
    //   <span class="top-left badge blue">{text}</span>
    // </div>
    // <div class="ribbon">
    <span class="ribbon4">{text}</span>
    // </div>
  );
}
