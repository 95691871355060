import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import SearchBar from './components/SearchBar';
import SearchResult from './components/Search/SearchResult';
import useFetchOnDemand from './hooks/useFetchOnDemand';
import { videoActions } from './store/video';
import { AnimatePresence, motion } from 'framer-motion';
import './SearchPage.css';
import ShareVideosViaEmail from './ShareVideosViaEmail';
import './components/video_components/Control.css';
import { useLocalStorage } from './hooks/useStorage';
import Chip from '@mui/material/Chip';
import ShareIcon from '@mui/icons-material/Share';
import ClearIcon from '@mui/icons-material/Clear';
import StudioRow from './StudioRow';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export default function SearchPage({ onTreeVideoClickHandler }) {
  const resultsContainerRef = useRef(null);
  const [filteredVideos, allStudios] = useSelector(
    (state) => [state.video.filteredVideos, state.video.allStudios],
    isEqual
  );
  const [query, setQuery] = useState({});
  const [checkedVideos, setCheckedVideos] = useState([]);
  const [shareVideosModal, setShareVideosModal] = useState(false);
  const [preview, setPreview] = useState(null);

  const dispatch = useDispatch();

  console.log({ checkedVideos });

  console.log({ filteredVideos });

  const sortByDate = (a, b) => {
    return new Date(b.dateRecorded) - new Date(a.dateRecorded);
  };

  const [autoCompleteValue, role] = useSelector(
    (state) => [state.video.autoCompleteValue, state.auth.user?.role],
    isEqual
  );

  // alert(role);

  const [searchHistoryArr, setSearchHistoryArr, removeSearchHistory] =
    useLocalStorage(
      'searchHistoryArr',
      role === 'demo'
        ? [
            { label: 'completo', group: 'Terms' },
            { label: 'tiktok', group: 'Terms' },
          ]
        : []
    );
  const [showHistory, setShowHistory] = useState(true);

  useEffect(() => {
    console.log({ autoCompleteValue });
    // updateSearchArray(value);
    const value = autoCompleteValue.map((el) => el.label);
    setSearchHistoryArr(
      [
        ...autoCompleteValue,
        ...searchHistoryArr.filter(
          (searchTerm) => !value.includes(searchTerm.label)
        ),
      ].slice(0, 14)
    );

    // console.log('🔬 search history: ', searchHistoryArr);
  }, [autoCompleteValue]);

  const {
    loading: loadingQuery,
    error: errorQuery,
    sendData: sendQuery,
  } = useFetchOnDemand(
    `${process.env.REACT_APP_BACKEND}/api/v1/videos/getFilteredVideos`,
    (data) => {
      dispatch(videoActions.setFilteredVideos(data.data.allVideos));
    },
    {
      method: 'POST',
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    },
    [query]
  );

  useEffect(() => {
    console.log('in query effect');
    console.log({ query });
    if (Object.values(query).length > 0 && sendQuery) {
      sendQuery();
      //   console.log('query SENT!');
    }
  }, [query]);

  const checkedVideosIds = checkedVideos.map((vid) => vid.id);

  return (
    <motion.div className="outer-container-content">
      {shareVideosModal && (
        <ShareVideosViaEmail
          onModalClose={() => {
            setShareVideosModal(false);
          }}
          checkedVideos={checkedVideos}
        />
      )}
      <SearchBar setQuery={setQuery} />

      {Object.values(query).length === 0 &&
        !filteredVideos?.length > 0 &&
        !loadingQuery &&
        !errorQuery && (
          <div
            style={{
              margin: '20px 20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
            }}
          >
            {allStudios && Object.keys(allStudios)?.length > 1 && (
              <StudioRow allStudios={allStudios} />
            )}
            {showHistory && searchHistoryArr?.length > 0 && (
              <>
                <div>
                  <div
                    className="pre-search-heading"
                    style={{
                      // margin: '25px 5px 5px ',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignContent: 'center',
                    }}
                  >
                    <div>Search History</div>
                    <div
                      style={{
                        fontSize: '16px',
                        color: 'var(--text-lighter-gray)',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        fontWeight: 'normal',
                        gap: '5px',
                        // color: 'red',
                      }}
                      onClick={() => {
                        setSearchHistoryArr([]);
                      }}
                    >
                      <div>clear</div>
                      <CloseOutlinedIcon style={{ fontSize: '20px' }} />
                    </div>
                  </div>
                  <div
                    className={`pre-search-container ${
                      !filteredVideos?.length == 0
                        ? 'container-to-hide-scroll'
                        : ''
                    }`}
                  >
                    <div
                      className={`recent-search-chip-container ${
                        !filteredVideos?.length == 0 ? 'one-row' : ''
                      }`}
                    >
                      {searchHistoryArr
                        .filter(
                          (el) =>
                            !autoCompleteValue
                              .map((val) => val.label)
                              .includes(el.label)
                        )
                        .map((el, index) => (
                          <Chip
                            key={el.label}
                            label={el.label}
                            style={{
                              backgroundColor: hashColorFunc(el.label),
                              // colorArray[searchHistoryArr.length - 1 - index],
                              // colorArray[index],
                              color: 'white',
                              margin: '5px',
                            }}
                            onClick={(e) => {
                              // alert(
                              //   JSON.stringify(
                              //     searchHistoryArr.filter(
                              //       (el) => el.label === e.currentTarget.innerText
                              //     )
                              //   )
                              // );
                              dispatch(
                                videoActions.updateAutoCompleteValue([
                                  ...autoCompleteValue,
                                  searchHistoryArr.filter(
                                    (el) =>
                                      el.label === e.currentTarget.innerText
                                  )[0],
                                ])
                              );
                            }}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      {filteredVideos?.length > 0 ? (
        // <FadeTopWrapper sectionListRef={resultsContainerRef}>

        <div
          className="search-faded-top"
          ref={resultsContainerRef}
          style={{
            position: 'relative',
            flex: '1 0 200px',
            height: '100%',
            overflowY: 'scroll',
            paddingBottom: '50px',
            // WebkitMaskImage: `-webkit-gradient(
            //   linear,
            //   left top,
            //   left 100%,
            //   color-stop(0%, rgba(0, 0, 0, 0)),
            //   color-stop(3.5%, rgba(0, 0, 0, 1))
            // )` ,
          }}
        >
          {(filteredVideos ? [...filteredVideos].sort(sortByDate) : []).map(
            (videoData) => (
              <SearchResult
                videoData={videoData}
                onTreeVideoClickHandler={onTreeVideoClickHandler}
                checkedVideos={checkedVideos}
                setCheckedVideos={setCheckedVideos}
                isChecked={checkedVideosIds.includes(videoData.id)}
              />
            )
          )}
        </div>
      ) : (
        // </FadeTopWrapper>
        <div
          style={{
            height: '180px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          {loadingQuery && <p>Loading...</p>}
          {errorQuery && <p>{`Error: ${errorQuery}`}</p>}
          {!loadingQuery && !errorQuery && Object.values(query).length > 0 && (
            <p>No search results 😢</p>
          )}
          {!loadingQuery &&
            !errorQuery &&
            Object.values(query).length === 0 && (
              // <SearchBackground />
              <></>
              // <div style={{ opacity: 0.5 }}>
              //   {/* <img
              //     src={`${process.env.REACT_APP_CLOUDFRONT}/searching-clipart-2.png`}
              //     style={{ height: '300px', opacity: 1 }}
              //   ></img> */}

              //   <SearchIcon
              //     style={{
              //       fontSize: '180px',
              //       color: 'var(--main-purple-no-transparancy)',
              //     }}
              //   />
              // </div>
            )}
          <div style={{ height: '200px', flexShrink: 1 }}></div>
        </div>
      )}
      <AnimatePresence>
        {checkedVideos.length > 0 && (
          <motion.div
            className="share-question-container"
            initial={{ transform: 'translateY(100%)' }}
            animate={{ transform: 'translateY(0)' }}
            exit={{ transform: 'translateY(100%)' }}
          >
            {/* <div > */}
            {/* <div className="video-share-count">{`${checkedVideos.length} videos`}</div> */}

            <div
              className="clear-all-search-shares"
              onClick={() => {
                setCheckedVideos([]);
              }}
            >
              {/* <div>X</div> */}
              <ClearIcon style={{ fontSize: '30px' }} />
            </div>
            {/* <div style={{ flexGrow: 1, overflow: 'hidden', height: '100%' }}> */}
            <div className="mini-thumb-container">
              {checkedVideos.map((vid) => (
                <div style={{ position: 'relative' }}>
                  <img
                    className="send-video-mini-thumb"
                    src={vid.thumbnail}
                  ></img>
                  {/* <div className="cancel-mini">
                    <ClearIcon style={{ fontSize: '15px' }} />
                  </div> */}
                </div>
              ))}
              {/* </div> */}
            </div>
            {/* </div> */}
            <button
              className="share-button"
              onClick={() => {
                setShareVideosModal(true);
              }}
            >
              <ShareIcon />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export function hashColorFunc(label) {
  const colorArray = [
    '#1E90FF', // DodgerBlue
    // '#32CD32', // LimeGreen
    // '#FF4500', // O
    '#8A2BE2', // BlueViolet
    // '#FF6347', // Tomato
    '#4682B4', // SteelBlue
    // '#9ACD32', // YellowGreen
    '#FF1493', // DeepPink
    '#00CED1', // DarkTurquoise
    // '#ADFF2F', // GreenYellow
    '#FF69B4', // HotPink
    // '#00FF7F', // SpringGreen
    '#DA70D6', // Orchid
    '#7B68EE', // MediumSlateBlue
    '#20B2AA', // LightSeaGreen
    // '#FF8C00', // DarkOrange
    '#40E0D0', // Turquoise
    // '#BA55D3', // MediumOrchid
    '#6495ED', // CornflowerBlue
    // '#7FFF00', // Chartreuse
  ];
  return colorArray[
    (label?.length + label?.at(0).codePointAt(0)) % colorArray.length
  ];
}
