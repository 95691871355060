import React, { forwardRef, useRef } from 'react';
import './AnnotateVideoForm.css';
import AutoCompleteForNewVideo from './AutoCompleteForNewVideo';

export default function AnnotateVideoForm({
  onIncreaseBeatStep,
  onDecreaseBeatStep,
  onLabelClickHandler,
  label,
  setLabel,
}) {
  return (
    <>
      <div style={{ display: 'block' }}>
        <div>
          <span>Adjust End Beat </span>
          <button
            className="increment-beat-button"
            onClick={onDecreaseBeatStep}
          >
            -
          </button>
          <button
            className="increment-beat-button"
            onClick={onIncreaseBeatStep}
          >
            +
          </button>
        </div>
        <div style={{ margin: '0px' }}>What counts are here:</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            // padding: '10px',
            gap: '5px',
            height: '40px',
          }}
        >
          <input type="radio" id="1234" name="count-label" value="1234" />
          <label for="1234">1234</label>
          {/* <br /> */}
          <input type="radio" id="5678" name="count-label" value="5678" />
          <label for="5678">5678</label>
          {/* <br /> */}
          <input
            type="radio"
            id="none"
            name="count-label"
            value="none"
            defaultChecked
          />
          <label for="none">none</label>
        </div>
      </div>
      <p style={{ margin: '3px' }}>Name and assign label:</p>
      {/* <input id="label-input" placeholder="label this move..."></input> */}
      <AutoCompleteForNewVideo label={label} setLabel={setLabel} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          // padding: '10px',
          gap: '5px',
          height: '40px',
        }}
      >
        <input
          type="radio"
          id="primaryKeyword"
          name="keyword"
          value="primaryKeyword"
        />
        <label for="primaryKeyword">Primary</label>
        {/* <br /> */}
        <input
          type="radio"
          id="secondaryKeyword"
          name="keyword"
          value="secondaryKeyword"
        />
        <label for="secondaryKeyword">Secondary</label>
        {/* <br /> */}
        <input type="radio" id="none-keyword" name="keyword" value="none" />
        <label for="none-keyword">none</label>
      </div>
      <button className="assign-button" onClick={onLabelClickHandler}>
        Assign Label
      </button>
    </>
  );
}
