import React, { useState, useEffect } from 'react';
import './StopWatch.css';
import './Timer.css';
import './StopwatchLog.css';
import Timer from './Timer';
import ControlButtons from './ControlButtons';
import { IonProgressBar } from '@ionic/react';
import Modal from '../Modal';
import { formatTime } from './TimerOverlay';
import PauseIcon from '@mui/icons-material/Pause';

// local storage element
const stopwatchExample = {
  startTime: 123213213,
  // inProgress: false,
  isActive: true,
  isPaused: true,
};

function StopwatchLog({ section, removeLog }) {
  const [showFinishModal, setShowFinishModal] = useState(false);
  // console.log('😻😻😻😻 stopwatch rerender');

  const [finishTimes, setFinishedTimes] = useState(null);

  function handleFinishTimes() {
    const storedTimes = JSON.parse(localStorage.getItem('stopwatch-new'));
    console.log({ storedTimes });
    if (storedTimes) {
      const nextFinishTimes = Object.keys(storedTimes).map((id) => {
        const { label, startTime, savedTime } = storedTimes[id];
        return (
          <div className="single-log-container">
            <div className="log-label timed-section-title">{label}</div>
            <div className="log-time">
              {formatTime(
                savedTime || (new Date().getTime() - startTime) / 1000
              )}
            </div>
          </div>
        );
      });
      console.log({ nextFinishTimes });
      setFinishedTimes(nextFinishTimes);
    }
    setShowFinishModal(true);
  }

  function clearPracticeCache() {
    removeLog();
    localStorage.setItem('stopwatch-new', JSON.stringify(null));
  }
  // const timeStorage = JSON.parse(localStorage.getItem('stopwatch-new'));
  // let startTime = timeStorage?.startTime || 0;
  // const savedTime = timeStorage?.savedTime;
  // const [isActive, setIsActive] = useState(() => {
  //   return timeStorage?.isActive;
  // });
  // const [isPaused, setIsPaused] = useState(() => {
  //   return timeStorage?.isPaused;
  // });

  // const [time, setTime] = useState(() => {
  //   if (isActive) {
  //     if (isPaused) {
  //       return savedTime;
  //     } else {
  //       return (new Date().getTime() - startTime) / 1000;
  //     }
  //   } else return 0;
  // });

  // useEffect(() => {
  //   let interval = null;
  //   if (isActive && isPaused === false) {
  //     interval = setInterval(() => {
  //       setTime((time) => time + 1);
  //     }, 1000);
  //   } else {
  //     clearInterval(interval);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [isActive, isPaused]);

  // const handleStart = () => {
  //   setIsActive(true);
  //   setIsPaused(false);
  //   setTime(0);
  //   let startTime = new Date().getTime();
  //   localStorage.setItem(
  //     'stopwatch',
  //     JSON.stringify({
  //       isActive: true,
  //       isPaused: false,
  //       startTime,
  //     })
  //   );
  // };

  // const handlePauseResume = () => {
  //   console.log('🐶 pause handler: ', isPaused);
  //   if (isPaused) {
  //     localStorage.setItem(
  //       'stopwatch',
  //       JSON.stringify({
  //         startTime: new Date().getTime() - time * 1000,
  //         isPaused: false,
  //         isActive,
  //       })
  //     );
  //   } else {
  //     localStorage.setItem(
  //       'stopwatch',
  //       JSON.stringify({
  //         startTime,
  //         isPaused: true,
  //         isActive,
  //         savedTime: time,
  //       })
  //     );
  //   }
  //   setIsPaused(!isPaused);
  // };

  // const handleReset = () => {
  //   setIsActive(false);
  //   setTime(0);
  //   localStorage.setItem('stopwatch', JSON.stringify(null));
  // };

  return (
    <>
      {showFinishModal && (
        <Modal
          fixedHeight={false}
          xColor="var(--text-lighter-gray)"
          onModalClose={() => {
            setShowFinishModal(false);
          }}
        >
          <div className="outer-log-container">
            <div className="timer-report-heading">Practice Summary</div>
            <div style={{ marginBottom: '25px' }}>{finishTimes}</div>
            <div
              style={{
                flexGrow: 1,
                width: '100%',
                flexBasis: '42px',
                flexShrink: 1,
              }}
            />
            <div className="clear-timers-button-container">
              <div
                onClick={clearPracticeCache}
                style={{
                  background: 'none',
                  color: 'var(--main-purple-no-transparancy)',
                  border: '1px solid var(--main-purple-no-transparancy)',
                }}
              >
                Clear all
              </div>
              <div onClick={clearPracticeCache}>Save</div>
            </div>
          </div>
        </Modal>
      )}
      <div className="stop-watch">
        <div className="timed-section-title-container">
          <div className="timed-section-title">{section}</div>
        </div>
        {/* <div className="timed-section-title">{`${
   section.length > 20 ? section.slice(0, 19) + '..' : section
 }`}</div> */}
        <div className="timer" style={{ fontSize: '1rem', color: 'white' }}>
          <div id="on-top-timer"></div>
        </div>
        <div className="top-timer-buttons-container">
          {/* <div
            className="pause-container-timeruptop"
            onClick={handleFinishTimes}
          >
            <PauseIcon style={{ color: 'white' }} />
          </div> */}
          <div className="finish-timer-log" onClick={handleFinishTimes}>
            Finish
          </div>
        </div>
        {/* <ControlButtons
   active={isActive}
   isPaused={isPaused}
   handleStart={handleStart}
   handlePauseResume={handlePauseResume}
   handleReset={handleReset}
 /> */}
      </div>
    </>
  );
}

export default StopwatchLog;
