export default function swipeDetect(el, callback, elOpacity, movement = true) {
  let surface = el;
  let startX = 0;
  let startY = 0;
  let distX = 0;
  let distY = 0;
  let diffx = 0;
  let diffy = 0;
  let initialLeft; //= el.getBoundingClientRect().x;
  //   console.log('📚initialLeft');
  //   console.log(initialLeft);
  let commitedToSwipe = false;
  let commitedToScroll = false;
  //   const sectionListElement = document.querySelector;

  surface.addEventListener('touchstart', function (e) {
    initialLeft = el.getBoundingClientRect().x;
    startX = e.changedTouches[0].pageX;
    startY = e.changedTouches[0].pageY;
    commitedToSwipe = false;
    console.log('swipe Detect start');
  });

  surface.addEventListener('touchmove', function (e) {
    // e.preventDefault();
    // if (swipeable === false) return;

    diffx = e.changedTouches[0].pageX - startX;
    diffy = Math.abs(e.changedTouches[0].pageY - startY);

    if (!commitedToSwipe && diffy > 5) {
      commitedToScroll = true;
    }

    if (commitedToScroll) return;
    // if (el.style) {
    // el.style.opacity = 0.5;
    // }

    // if (diffy >= diffx) swipeable = false;
    // console.log(' ');
    // console.log('diffx: ', diffx);
    // console.log('diffy: ', diffy);

    if (commitedToSwipe || (diffx > 5 && diffx > 7 * diffy)) {
      commitedToSwipe = true;
      if (movement) {
        const xTravel = e.changedTouches[0].pageX - startX;
        if (xTravel > 0) {
          el.style.left = `${initialLeft + xTravel}px`;
          elOpacity.style.opacity = Math.max(0, 1 - xTravel / 100);
        } else {
          el.style.left = `${initialLeft}px`;
        }
      }

      // if (distX > 70) {
      //   callback();
      // }
      //   console.log('left: ', el.getBoundingClientRect());
      //   console.log('📍 touchmove listner');
      //   console.log(e);
    }

    // console.log('swipe right');
    // el.remove();
    // else {
    //   el.style.left = `${initialLeft}px`;
    //   swipeable = false;
    // }
  });

  surface.addEventListener(
    'touchend',
    function (e) {
      if (commitedToScroll) {
        commitedToScroll = false;
        return;
      }

      distX = e.changedTouches[0].pageX - startX;
      distY = e.changedTouches[0].pageY - startY;

      // if (Math.abs(distX) > Math.abs(distY)) {
      if (distX > 70 && commitedToSwipe) {
        // console.log('swipe right');
        // el.remove();
        callback();
      } else {
        el.style.left = `${initialLeft}px`;
        elOpacity.style.opacity = 1;
        // console.log('swipe left');
      }
      // }
    },
    true
  );
}
