import React, { useEffect, useState } from 'react';
import Modal from './components/Modal';
import classes from './components/CreateNewUserByAdmin.module.css';
import useFetchOnDemand from './hooks/useFetchOnDemand';
import useInput from './hooks/use-input';
import { useDispatch, useSelector } from 'react-redux';
import { videoActions } from './store/video';
import AutoCompleteNamesEmails from './components/AutoCompleteNamesEmails';

export default function ShareVideosViaEmail({ onModalClose, checkedVideos }) {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [lastNewUser, namesAndEmails, userRole] = useSelector((state) => [
    state.video.lastNewUser,
    state.video.namesAndEmails,
    state.auth.user.role,
  ]);

  const {
    enteredValue: enteredEmail,
    hasError: emailHasError,
    onBlurHandler: emailBlurHandler,
    onChangeHandler: emailChangeHandler,
    isValidValue: enteredEmailIsValid,
  } = useInput(
    (value) => value.includes('@'),
    !!lastNewUser ? lastNewUser : ''
  );

  let formIsValid = false;
  if (selectedUsers.length > 0) formIsValid = true;

  const shareVideosHandler = (event) => {
    event.preventDefault();

    if (formIsValid) {
      sendVideos();
    }
  };

  const {
    loading: sending,
    error: errorSending,
    sendData: sendVideos,
  } = useFetchOnDemand(
    `${process.env.REACT_APP_BACKEND}/api/v1/users/shareVideosByEmail`,
    (data) => {
      //   dispatch(videoActions.setFilteredVideos(data.data.allVideos));
      onModalClose();
    },
    {
      method: 'PATCH',
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        videosToShare: checkedVideos.map((vid) => vid.id),
        emails: selectedUsers.map((user) => user.email),
      }),
    },
    [checkedVideos, selectedUsers]
  );

  const {
    loading: loadingNames,
    error: errorForNames,
    sendData: sendForNames,
  } = useFetchOnDemand(
    `${process.env.REACT_APP_BACKEND}/api/v1/users/getAllNamesAndEmails`,
    (data) => {
      //   dispatch(videoActions.setFilteredVideos(data.data.allVideos));
      // onModalClose();
      console.log(data.data.users);
      dispatch(videoActions.setNamesAndEmails(data.data.users));
    },
    {
      // method: 'PATCH',
      credentials: 'include', // include, *same-origin, omit
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      // body: JSON.stringify({
      //   videosToShare: checkedVideos,
      //   email: enteredEmail,
      // }),
    }
    // [checkedVideos, enteredEmail]
  );

  useEffect(() => {
    console.log({ userRole });
    if (userRole === 'admin' && namesAndEmails === null) {
      console.log('we fucking amdmin');
      sendForNames();
    }
  }, [userRole]);

  return (
    <Modal fixedHeight={false} onModalClose={onModalClose}>
      <div style={{ margin: '25px 20px' }}>
        <form onSubmit={shareVideosHandler}>
          <div>
            <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Send Videos!
            </h1>
          </div>
          <div className={classes['form-elements']}>
            {/* <div className={classes.control}>
              <label htmlFor="email">User's Email</label>
              <input
                value={enteredEmail}
                onBlur={emailBlurHandler}
                onChange={emailChangeHandler}
                type="email"
                id="email"
                // defaultValue={'asdfasdf'}
              />
              {emailHasError && (
                <p className={classes['error-text']}>Not valid email</p>
              )}
            </div> */}
          </div>
          <AutoCompleteNamesEmails
            value={selectedUsers}
            setValue={setSelectedUsers}
          />
          {sending && <div>sending...</div>}
          {errorSending && <div style={{ color: 'red' }}>error</div>}
          <div className={classes['button-container']}>
            <button className={classes['login-button']}>Share Videos</button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
