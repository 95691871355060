import React, { useEffect, useRef, useState } from 'react';
import classes from './VideoBoxAndThumb.module.css';

import { Thumbnail } from './Thumbnail';
import ThumbnailBadge from './ThumbnailBadge';
import VideoPreview from './video_components/VideoPreview';

export default function ThumbAndTitle({
  videoThumbnail,
  children,
  videoTest,
  videoData,
}) {
  const [showPreview, setShowPreview] = useState(false);
  const imageContainerRef = useRef(null);

  useEffect(() => {
    const handleMouseEnter = () => {
      // setTimeout(() => {
      //   setShowPreview(true);
      // }, 500);
      setShowPreview(true);
    };
    const handleMouseLeave = () => setShowPreview(false);

    const element = imageContainerRef.current;

    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
      element.addEventListener('touchstart', handleMouseEnter);
      // element.addEventListener('touchend', handleMouseLeave);
    }

    // Cleanup event listeners when the component unmounts
    return () => {
      if (element) {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
        element.removeEventListener('touchstart', handleMouseEnter);
        // element.removeEventListener('touchend', handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    function onClickOutside(event) {
      const element = imageContainerRef.current;

      const outsideClick = !element.contains(event.target);
      if (outsideClick) {
        setShowPreview(false);
      }
    }

    if (showPreview) {
      document.addEventListener('touchstart', onClickOutside);
    } else {
      document.removeEventListener('touchstart', onClickOutside);
    }
    return () => {
      document.removeEventListener('touchstart', onClickOutside);
    };
  }, [showPreview]);

  return (
    <div style={{ position: 'relative' }}>
      <div ref={imageContainerRef} className="image-container">
        {children}
        <div style={{ height: '160px', width: '100%' }}>
          <Thumbnail
            src={videoThumbnail}
            borderRadius="8px"
            style={{
              display: 'inline-block',

              objectFit: 'cover',
              height: '100%',
              width: '100%',
              objectPosition: '50% 30%',
            }}
          />

          {showPreview && <VideoPreview videoData={videoData} userId />}

          {/* <ThumbnailBadge text="new" /> */}
        </div>
      </div>

      {/* <div className={classes['title-div']}>
        {title.map((el) => (
          <div className={classes['title-line']}>{el}</div>
        ))}
      </div> */}
    </div>
  );
}
