// parameters will be the callback functions
function determineClickCountFactory() {
  console.log('func being createed 👶');
  let count = 0;
  let singleClickTimer;
  return (singleClickFunc, doubleClickFunc, maxTimeForDoubleClick = 250) => {
    count++;
    console.log('click detected in determineClickFactory');
    if (count === 1) {
      singleClickTimer = setTimeout(() => {
        singleClickFunc();
        count = 0;
      }, maxTimeForDoubleClick);
    } else if (count === 2) {
      clearTimeout(singleClickTimer);
      doubleClickFunc();
      count = 0;
    }
  };
}

export default determineClickCountFactory;
