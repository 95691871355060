// import { createStore } from 'redux';
import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import tourReducer from './tour';
import counterReducer from './counter';
import playerReducer from './playerControls';
import settingsReducer from './settings';
import videoReducer from './video';
import friendReducer from './friend';
import thunk from 'redux-thunk';

const store = configureStore(
  {
    reducer: {
      counter: counterReducer,
      auth: authReducer,
      player: playerReducer,
      settings: settingsReducer,
      video: videoReducer,
      friend: friendReducer,
      tour: tourReducer,
    },
  },
  applyMiddleware(thunk)
);

export default store;

// const counterReducer = (state = initialState, action) => {
//     if (action.type === 'INCREMENT') {
//       console.log('action.payload');
//       console.log(action.payload);
//       return {
//         counter: state.counter + action.payload,
//         showCounter: state.showCounter,
//       };
//     }

//     if (action.type === 'DECREMENT') {
//       return {
//         counter: state.counter - 1,
//         showCounter: state.showCounter,
//       };
//     }

//     if (action.type === 'TOGGLE') {
//       return {
//         ...state,
//         showCounter: !state.showCounter,
//       };
//     }

//     return state;
