import { useState, useEffect, useCallback, useRef } from 'react';

const useFileExists = (url) => {
  const [fileExists, setFileExists] = useState(null);
  const [error, setError] = useState(null);
  const [retries, setRetries] = useState(0);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => (isMounted.current = false);
  }, []);

  const checkFileExists = (() => {
    let retriesCount = 0;

    return async () => {
      try {
        console.log({ retriesCount });
        const response = await fetch(url, { method: 'HEAD' });
        if (response.ok) {
          setFileExists(true);
        } else {
          throw new Error('File not found');
        }
      } catch (err) {
        if (!isMounted.current) {
          return;
        } else if (retriesCount < 15) {
          console.log({ retries });
          setTimeout(() => {
            setRetries((prevRetries) => prevRetries + 1);
            retriesCount++;
            checkFileExists();
          }, 5000);
        } else {
          console.log('not found in x tries');
          setError('File not found after 15 tries');
        }
      }
    };
  })();

  useEffect(() => {
    checkFileExists();
  }, [url]);

  return { fileExists, error, retries };
};

export default useFileExists;
