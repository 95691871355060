import React from 'react';
import { useDispatch } from 'react-redux';
import EditableText from './EditableText';
import { videoActions } from './video';

export default function SingleNote({
  label,
  notes,
  index,
  userVideoDataId,
  isEditingParent,
  setisEditingParent,
}) {
  const dispatch = useDispatch();
  function updateSection(type, text) {
    const dataObj = {};
    dataObj[type] = text;
    console.log('dataobj: ', dataObj);
    //   console.log('sectionId: ', sectionId);
    sendSectionData(index, dataObj, dispatch);
  }

  return (
    <div style={{ paddingBottom: '20px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div>
          {' '}
          <EditableText
            //   sectionId={id}
            disabled={true}
            initialText={label}
            fontSize="1.5rem"
            onSave={updateSection}
            type="label"
          />
        </div>
      </div>
      <EditableText
        //   sectionId={id}
        initialText={notes}
        fontSize="1rem"
        color="#999999"
        onSave={updateSection}
        type="notes"
        index={index}
        userVideoDataId={userVideoDataId}
        setisEditingParent={setisEditingParent}
        isEditingParent={isEditingParent}
      />
    </div>
  );
}

async function sendSectionData(id, section, dispatch) {
  console.log('sending data!');
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/sections/update/${id}`,
      {
        method: 'PATCH',
        // mode: 'cors',
        // withCredentials: true,

        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(section),
      }
    );
    console.log('📞 response');
    console.log(response);
    const data = await response.json();
    if (!response.ok) {
      console.log('we failed to update sections. Sorry!');
      //   alert('fail to update section');
      //   return false;
    } else {
      console.log('💩 update received: ', data.data.updatedSection);
      dispatch(videoActions.updateSavedSelection(data.data.updatedSection));
    }
  } catch (err) {
    console.log('err: ', err);
    // return false;
  }
}
