import React from 'react';
import './ControlButton.css';
import { IonIcon } from '@ionic/react';
import { eyeOffOutline } from 'ionicons/icons';

export default function ControlButton({
  source,
  icon,
  muiIcon,
  onClickHandler,
  background = '#7b2cbf',
  disabled = false,
  padding = 10,
  imageScale = 1,
  buttonScale = 1,
  iconFontSize = 20,
  invertColor = true,
  color = 'white',
  highlight = false,
  className = '',
}) {
  return (
    <div
      className={`control-button-container ${className}`}
      style={{
        position: 'relative',
        zIndex: 190,
        padding: padding + 'px',
      }}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          onClickHandler();
        }}
        style={{
          //   position: 'absolute',
          //   top: '25%',
          //   left: '5px',
          //   zIndex: 300,
          fontSize: '2rem',
          background: `${disabled ? 'gray' : background}`,
          padding: '0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          height: `${40 * buttonScale}px`,
          width: `${40 * buttonScale}px`,
          borderRadius: '100px',
          //   backgroundPosition: 'center center',
          //   backgroundRepeat: 'no-repeat'
        }}
      >
        {source && (
          <>
            {/* <div style={{ background: `url(source)` }} /> */}

            <img
              className={`${
                invertColor ? 'slowmo-button' : ''
              } colorize-button`}
              style={{
                height: `${100 * imageScale}%`,
                width: `${100 * imageScale}%`,
                margin: 'auto',
                // textAlign: 'center',
                // display: 'table-cell',
              }}
              src={source}
            />
          </>
        )}
        {muiIcon && (
          <div
            className={`${
              highlight ? 'mui-icon-w-shadow' : 'mui-icon-w-shadow'
            }`}
            style={{
              color: highlight ? 'var(--highlight-color-1)' : 'white',
              fontSize: '44px',
            }}
          >
            {muiIcon}
          </div>
        )}
        {icon && (
          <div
            style={{
              // height: `${100 * imageScale}%`,
              // width: `${100 * imageScale}%`,
              margin: 'auto',
              // textAlign: 'center'
              fontSize: `${iconFontSize}px`,
              // display: 'table-cell',
              color,
            }}
            // className="slowmo-button"
          >
            {/* <div style={{}}> */}
            <IonIcon
              icon={icon}
              size="medium"
              color="purple"
              // style={{ color: 'white', display: 'block' }}
            ></IonIcon>
            {/* </div> */}
          </div>
        )}
      </button>
    </div>
  );
}
