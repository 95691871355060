import './StopWatchApp.css';
import StopWatch from './StopWatch.js';

function StopWatchApp() {
  return (
    <div className="App">
      <StopWatch />
    </div>
  );
}

export default StopWatchApp;
