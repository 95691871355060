import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';

export default function ForMobileTooltip({ children, isMobile }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip
      title={isMobile ? '' : 'Tutorials currently available for mobile only'}
      PopperProps={{
        disablePortal: true,
      }}
      onClose={() => {
        setTimeout(() => {
          setShowTooltip(false);
        }, 300);
      }}
      open={showTooltip}
      TransitionComponent={Zoom}
    >
      <span
        onClick={() => {
          setShowTooltip(true);
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
}
