import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitSection, videoActions } from '../../store/video';
import IconTextButton from '../Buttons/IconTextButton';
import ModalFullTop from '../ModalFullTop';
import ChangeABLoop from './ChangeABLoop';
import './MoreBarOptions.css';
import NewAndEditNote from './NewAndEditNote';

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { tourActions } from '../../store/tour';

const cloudfrontPath = 'https://d2qxuoym2zs537.cloudfront.net/icons';

export default function MoreBarOptions({
  slider,
  onSeek,
  played,
  duration,
  onSeekMouseUp,
  onMouseDown,
  setTemporarilyHideControls,
  setShowPracticeForm,
}) {
  const dispatch = useDispatch();
  const [showSlider, setShowSlider] = useState(false);
  const [addingNote, setAddingNote] = useState(false);
  const [looptime, notesArray, currentBar] = useSelector((state) => [
    state.video.looptime,
    state.video.notesArray,
    state.video.selectedSections[0],
  ]);

  return (
    <>
      {/* <ModalFullTop
        onModalClose={() => {
          dispatch(videoActions.setShowBarOptions());
        }}
      > */}
      <div
        style={{
          width: '100%',
          zIndex: 402,
          position: 'absolute',
          // height: '100vh',
          height: '100%',
          pointerEvents: 'none',
        }}
      >
        <div className="more-bar-options-container">
          {showSlider && (
            <ChangeABLoop
              setShowSlider={setShowSlider}
              onSeek={onSeek}
              onSeekMouseUp={onSeekMouseUp}
              onMouseDown={onMouseDown}
              duration={duration}
            />
          )}
          {!showSlider && (
            <>
              <IconTextButton
                muiIcon={<CloseOutlinedIcon style={{ fontSize: '25px' }} />}
                text="Close"
                onClick={() => {
                  if (showSlider) {
                    setShowSlider(false);
                  } else {
                    dispatch(videoActions.resetShowBarOptions());
                  }
                }}
              />
              <div style={{ flexGrow: 1 }}></div>

              <IconTextButton
                muiIcon={
                  <OpenInFullOutlinedIcon style={{ fontSize: '25px' }} />
                }
                text="Crop"
                onClick={() => {
                  setShowSlider(true);
                  console.log(looptime[0] / 60);
                  onSeek(undefined, (looptime[0] / 60) * 100);
                }}
              />
              <IconTextButton
                muiIcon={
                  <LibraryAddOutlinedIcon style={{ fontSize: '25px' }} />
                }
                className="add-note-button"
                text="Practice"
                onClick={() => {
                  console.log('section being submitted');
                  setShowPracticeForm((prev) => !prev);
                  dispatch(tourActions.handleLinkedAction('clickAddPractice'));
                }}
              />
              <IconTextButton
                muiIcon={<EditNoteOutlinedIcon style={{ fontSize: '35px' }} />}
                text="Note"
                onClick={() => {
                  setAddingNote((prev) => !prev);
                  setTemporarilyHideControls((prev) => !prev);
                }}
              />
              {/* <IconTextButton
                muiIcon={<MoreVertOutlinedIcon style={{ fontSize: '25px' }} />}
                text=""
                width="40px"
              /> */}
            </>
          )}
        </div>
        {addingNote && (
          <NewAndEditNote
            initialText={notesArray[currentBar]}
            setTemporarilyHideControls={setTemporarilyHideControls}
            setAddingNote={setAddingNote}
          />
        )}
      </div>
      {/* </ModalFullTop> */}
    </>
  );
}
