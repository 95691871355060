import React from 'react';
import classes from './IconTextButton.module.css';

export default function IconTextButton({
  muiIcon,
  text,
  onClick,
  height = '70px',
  width = '60px',
  className = '',
}) {
  return (
    <div
      className={classes['button-container'] + ' ' + className}
      onClick={onClick}
      style={{ height, width }}
    >
      <div className={classes['sub-container']}>
        <div className={`${classes['icon-holder']} ${text}-icon`}>
          {muiIcon}
        </div>
        <div className={classes['text-holder']}>{text}</div>
      </div>
    </div>
  );
}
