import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classes from './VideoBoxAndThumb.module.css';
import { videoActions } from '../store/video';
import { authActions } from '../store/auth';

export default function VideoClickWrapper({
  id,
  videoData,
  selection,
  children,
  onTreeVideoClickHandler,
  className = '',
}) {
  const dispatch = useDispatch();

  // console.log('👨🏻‍💻  videoData', videoData);
  return (
    <div
      className={className}
      // className={classes['main-container']}
      onClick={() => {
        onTreeVideoClickHandler(id, selection);
        // dispatch(videoActions.updateRecentVideos(videoData));
        // dispatch(authActions.updateNewVideos(videoData.id));
      }}
      // style={{ flexGrow }}
    >
      {children}
    </div>
  );
}
