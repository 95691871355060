import React from 'react';
import './VideoTitleBlocks.css';

export default function VideoTitleBlock({
  title,
  addSearchObject,
  studio,
  icon,
  badges,
  timeElapsed,
}) {
  return (
    <div style={{ padding: '3px 8px' }}>
      {/* {timeElapsed} */}
      {badges}
      {/* <div className="icon-n-title-container">
        <img
          src={icon}
          className="icon-img"
          onClick={() => {
            addSearchObject([{ label: title[0], group: 'Studios' }]);
          }}
        ></img> */}
      <div className="video-title-block">
        <div
        // style={{ height: '19.5px', overflow: 'hidden' }}
        >
          {' '}
          {title.slice(1).map((el) => (
            <>
              {' '}
              <span>{el}</span>
            </>
          ))}
        </div>
        <span style={{ fontWeight: 'bold' }}>·</span>
        {studio && (
          <div
            style={{ color: 'blue' }}
            onClick={() => {
              addSearchObject([
                { label: studio.name, group: 'Studios', id: studio._id },
              ]);
            }}
          >
            {studio.name}
          </div>
        )}
      </div>
      {/* </div> */}
      {timeElapsed}
    </div>
  );
}
