import { Fragment } from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import classes from './Modal.module.css';
import classesGoal from './NewGoal.module.css';
import { height } from '@mui/system';
import { CreateButton } from './NewGoal';

const Backdrop = ({ onClose }) => {
  return <div className={classes.backdrop} onClick={onClose} />;
};

const ModalOverlay = ({
  submit,
  onClose,
  // children,
  // fixedHeight,
  // xColor,
  // hideX,
  // paddingLeft,
  // paddingRight,
}) => {
  const newColumnNameRef = useRef(null);
  const [newName, setNewName] = useState(null);

  useEffect(() => {
    if (newColumnNameRef.current) {
      newColumnNameRef.current.focus();
    }
  });

  return (
    <div
      className={`${classes['text-input-modal']}`}
      style={
        {
          // left: paddingLeft,
          // right: paddingRight,
        }
      }
    >
      <div
        className={classes.content}
        // className={fixedHeight ? classes.content : classes['content-flexible']}
      >
        <div className={classes['outer-container']}>
          <div
            className={classes['inner-container']}
            style={{ padding: '20px' }}
          >
            <div
              className={classesGoal.heading}
              style={{ justifyContent: 'left', fontSize: '22px' }}
            >
              <div>Create New Group</div>
            </div>
            {/* <div style={{ margin: '20px', position: 'relative' }}>
            <h3>New Column Name</h3> */}
            <input
              ref={newColumnNameRef}
              type="text"
              onChange={(e) => {
                setNewName(e.target.value);
              }}
              value={newName}
              style={{ width: '100%', marginBottom: '20px' }}
            ></input>
            <div style={{ flex: '1 0 15px' }}></div>
            <div
              className={classesGoal['button-container']}
              style={{ justifyContent: 'flex-end' }}
            >
              <div className={classesGoal.cancel} onClick={onClose}>
                cancel
              </div>
              <CreateButton
                onClick={() => {
                  submit(newName);
                  onClose();
                }}
                // style={{
                //   width: 'fit-content',
                //   padding: '2px 10px',
                //   backgroundColor: 'purple',
                //   color: 'white',
                //   borderRadius: '25px',
                //   position: 'absolute',
                //   bottom: 0,
                //   right: 0,
                // }}
                // onClick={()=>{}}
              >
                Create
              </CreateButton>
            </div>
          </div>
        </div>
      </div>

      <button
        className={classes['exit-button']}
        onClick={onClose}
        // style={{ color: xColor }}
      >
        <IonIcon icon={closeOutline} size="medium" color="white"></IonIcon>
      </button>
    </div>
  );
};

const portalElement = document.getElementById('overlays');

const ModalTextInput = ({
  onModalClose,
  children,
  fixedHeight = true,
  xColor = 'black',
  paddingLeft = '5%',
  paddingRight = '5%',
  hideX = false,
  submit,
}) => {
  const handleUserKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === 27) {
      onModalClose();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, []);

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={onModalClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onClose={onModalClose}
          fixedHeight={fixedHeight}
          xColor={xColor}
          hideX={hideX}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          submit={submit}
        >
          {children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default ModalTextInput;
