import { createSlice } from '@reduxjs/toolkit';

const initialVideoState = {
  friends: [],
};

const friendSlice = createSlice({
  name: 'friend',
  initialState: initialVideoState,
  reducers: {
    initialize(state, action) {
      state.friends = [...action.payload];
    },
    addFriends(state, action) {
      state.friends = [...action.payload, ...state.friends];
    },
    updateFriends(state, action) {
      state.friends = action.payload;
    },

    sortFriends(state, action) {
      const videoId = action.payload;
      state.friends = state.friends
        .slice()
        .sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          return 0;
        })
        .sort(
          (a, b) =>
            a.sharedWithMeVideos.includes(videoId) -
            b.sharedWithMeVideos.includes(videoId)
        );

      // console.log('🤡 friend in: ', state.friends[0]['photo']);
    },
  },
});

export const friendActions = friendSlice.actions;
export default friendSlice.reducer;

export function submitFriend(friendTag, setAddFriendError) {
  return async (dispatch, getState) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/users/addFriend`,
      {
        method: 'POST',
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ friendTag: friendTag }),
      }
    );
    const responseComplete = await response.json();
    if (!response.ok) {
      console.log('in failed if');

      console.log('ResponseComplete?.message ');
      console.log(responseComplete?.message);

      setAddFriendError(responseComplete?.message || 'Generic error');
    } else {
      dispatch(friendActions.addFriends([responseComplete.data]));
      setAddFriendError(null);
    }
  };
}

export function shareVideo(videoId, friendTag, onModalClose) {
  console.log('👨🏼‍🏭 in thunk: ', videoId, friendTag);

  return async (dispatch, getState) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/users/shareVideo`,
      {
        method: 'POST',
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videoId, friendTag: friendTag }),
      }
    );
    const data = await response.json();
    console.log(data);
    console.log(response);
    if (!response.ok) {
      console.log('in failed if');
      alert('fail');
    } else {
      onModalClose();
    }
  };
}
