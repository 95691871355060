import classes from './CreateNewUserByAdmin.module.css';
import useInput from '../hooks/use-input';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { authActions } from '../store/auth';
import { useParams, Link, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import useFetchOnDemand from '../hooks/useFetchOnDemand';
import { videoActions } from '../store/video';

const CreateNewUserByAdmin = ({ onModalClose }) => {
  // const params = useParams();
  // const navigate = useNavigate();
  // const { resetToken, name } = params;
  // alert(resetToken);

  const [resetLink, setResetLink] = useState(null);

  const {
    enteredValue: enteredName,
    hasError: nameHasError,
    onBlurHandler: nameBlurHandler,
    onChangeHandler: nameChangeHandler,
    isValidValue: enteredNameIsValid,
  } = useInput((value) => value.length >= 1);

  const {
    enteredValue: enteredEmail,
    hasError: emailHasError,
    onBlurHandler: emailBlurHandler,
    onChangeHandler: emailChangeHandler,
    isValidValue: enteredEmailIsValid,
  } = useInput((value) => value.includes('@'));

  const dispatch = useDispatch();

  let formIsValid = false;
  if (enteredNameIsValid && enteredEmailIsValid) formIsValid = true;

  // const navigateHandler = () => {
  //   navigate('/');
  // };
  // alert(window.location.hostname);

  const {
    loading: sending,
    error: errorSending,
    sendData: send,
  } = useFetchOnDemand(
    `${process.env.REACT_APP_BACKEND}/api/v1/users/signupByAdmin`,
    (data) => {
      dispatch(videoActions.setLastNewUser(enteredEmail));
      onModalClose();
    },
    {
      method: 'POST',
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: enteredName,
        email: enteredEmail,
      }),
    },
    [enteredEmail, enteredName]
  );

  const loginHandler = (event) => {
    event.preventDefault();

    if (formIsValid) {
      send();
      // const sendLoginInfo = async () => {
      //   const response = await fetch(
      //     `${process.env.REACT_APP_BACKEND}/api/v1/users/signupByAdmin`,
      //     {
      //       method: 'POST',
      //       credentials: 'include', // include, *same-origin, omit
      //       headers: {
      //         'Content-Type': 'application/json',
      //       },
      //       body: JSON.stringify({
      //         name: enteredName,
      //         email: enteredEmail,
      //       }),
      //     }
      //   );
      //   console.log('before the if fail check');
      //   console.log(response);
      //   if (!response.ok) {
      //     console.log('in failed if');
      //     alert('fail in create');
      //   } else {
      //     const data = await response.json();
      //     console.log(data.data);
      //     setResetLink(window.location.hostname + '/' + data.data?.resetURL);
      //     // alert(JSON.stringify(data));
      //     // dispatch(authActions.login(data.data.user));
      //     // navigateHandler();
      //   }
      // };

      // sendLoginInfo();
    }
    // dispatch(authActions.login());
  };

  return (
    <div style={{ margin: '25px 20px' }}>
      <form onSubmit={loginHandler}>
        <div>
          <h1 style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Let's Signup a New User!
          </h1>
        </div>
        <div className={classes['form-elements']}>
          <div className={classes.control}>
            <label htmlFor="name">Name</label>
            <input
              value={enteredName}
              onBlur={nameBlurHandler}
              onChange={nameChangeHandler}
              type="text"
              id="name"
            />
            {nameHasError && (
              <p className={classes['error-text']}>Enter valid name</p>
            )}
          </div>
          <div className={classes.control}>
            <label htmlFor="email">Email</label>
            <input
              value={enteredEmail}
              onBlur={emailBlurHandler}
              onChange={emailChangeHandler}
              type="email"
              id="email"
            />
            {emailHasError && (
              <p className={classes['error-text']}>Not valid email</p>
            )}
          </div>
        </div>
        {sending && <div>sending...</div>}
        {errorSending && <div style={{ color: 'red' }}>error</div>}
        {/* {resetLink && <div style={{ wordWrap: 'break-word' }}>{resetLink}</div>} */}
        <div className={classes['button-container']}>
          <button className={classes['login-button']}>Create User</button>
        </div>
      </form>
    </div>
  );
};

export default CreateNewUserByAdmin;
