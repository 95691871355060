import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { videoActions } from './store/video';
import './StudioRow.css';

export default function StudioRow({ allStudios }) {
  //   const allStudios = useSelector((state) => state.video.allStudios);

  const dispatch = useDispatch();

  return (
    <div>
      {' '}
      <div className="pre-search-heading">Studios</div>
      <div className="all-studios-container pre-search-container">
        {allStudios &&
          Object.keys(allStudios).map((studio) => (
            <div
              className="studio-container"
              onClick={() => {
                dispatch(
                  videoActions.updateAutoCompleteValue([
                    {
                      label: studio,
                      group: 'Studios',
                      id: allStudios[studio]._id,
                    },
                  ])
                );
              }}
            >
              <img src={allStudios[studio].icon} />
              <div>
                {allStudios[studio].shortName || allStudios[studio].name}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
