import React from 'react';
import classes from './BadgeList.css';
import Badge from './Badge';

function ridDuplicates(arr) {
  return [...new Set([...arr])];
}

const styleColors = ['pink', '#00AA00', 'red'];

export default function BadgeList({
  danceStyle,
  primary,
  secondary,
  primaryColor = 'red',
  secondaryColor = 'pink',
}) {
  const derivedColor =
    styleColors[danceStyle?.trim().length % styleColors.length];
  return (
    <div className="badge-container">
      {primary && (
        <>
          {danceStyle && (
            <Badge
              key="dance-style"
              keyword={danceStyle.trim()}
              color={derivedColor}

              // style={{
              //   color: derivedColor,
              //   // backgroundColor: 'transparent',
              //   backgroundColor: `${derivedColor}33`,
              // }}
            />
          )}
          {ridDuplicates(primary).map((keyword, index) => (
            <Badge
              key={keyword + index}
              keyword={keyword}
              color="var(--main-purple-no-transparancy)"
              // color="black"
            />
          ))}
        </>
      )}
      {false &&
        secondary &&
        ridDuplicates(secondary).map((keyword, index) => (
          <Badge key={keyword + index} keyword={keyword} color={'pink'} />
        ))}
      {/* {selectionLabels &&
        selectionLabels.map((keyword) => (
          <Badge key={keyword} keyword={keyword} color={'blue'} />
        ))} */}
    </div>
  );
}
