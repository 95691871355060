import classes from './VideoSeparateUpload.module.css';
import ReactPlayer from 'react-player';
import { Container, Hidden } from '@material-ui/core';
import Control from './Control';
import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { formatTime } from './format';
// import classes from './../VideoComponent.module.css';
import { scan } from 'ionicons/icons';
import AnnotateVideoForm from '../AnnotateVideoForm';
import { VideoToFrames, VideoToFramesMethod } from './VideoToFrame.ts';
import CircleLoader from '../Loaders/CircleLoader';
import CircleLoaderKeyframes from '../Loaders/CircleLoaderKeyframes';
import useDebounce from '../../hooks/useDebounce';
import useTimeout from '../../hooks/useTimeout';
import Hls from 'hls.js';
import useFileExists from '../useFileExists';
import ModalOnlyText from '../ModalOnlyText';

const HLSConfig = {
  forceKeyFrameOnDiscontinuity: false,
  maxBufferLength: 30, // for example, set to 30 seconds
  maxMaxBufferLength: 30, // hard upper limit for maxBufferLength. This does not seem to update in the reactPlayer even when HLSConfig changes
  // Set a maximum buffer size to control the amount of data preloaded.
  maxBufferSize: 6 * 1024 * 1024, // for example, set to 10MB
};

let count = 0;
const VideoSeparateUpload = forwardRef(
  (
    {
      ABLoopRef,
      url,
      progressHandler,
      addBeatWithTap,
      setCountingComplete,
      onEnded,
      countingComplete,
      playing,
      displayTapButton,
      onReady,
      onModalClose,
      // playHandler
    },
    videoPlayerRef
  ) => {
    // alert(`${process.env.REACT_APP_CLOUDFRONT}videos/${url}/filename.m3u8`);
    // console.log('🤯 Videocontainer for upload rerender');
    //   const videoPlayerRef = useRef(null);
    const controlRef = useRef(null);

    const currentTime = videoPlayerRef.current
      ? videoPlayerRef.current.getCurrentTime()
      : '00:00';
    const duration = videoPlayerRef.current
      ? videoPlayerRef.current.getDuration()
      : '00:00';

    const formatCurrentTime = formatTime(currentTime);
    const formatDuration = formatTime(duration);

    // useDebounce(
    //   () => {
    //     if (buffer) {
    //       setBufferDebounced(true);
    //     } else setBufferDebounced(false);
    //   },
    //   100,
    //   [buffer]
    // );

    const bufferStartHandler = () => {
      console.log('Bufering.......');
      setVideoState({ ...videoState, buffer: true });
    };

    const bufferEndHandler = () => {
      console.log('buffering stoped ,,,,,,play');
      setVideoState({ ...videoState, buffer: false });
    };

    const [videoState, setVideoState] = useState({
      // playing: true,
      muted: false,
      volume: 0.5,
      playbackRate: 1.0,
      played: 0,
      seeking: false,
      buffer: true,
      pointAplayed: 0,
      pointBplayed: 10000000,
      isCounting: false,
      // countingComplete: false,
      annotationComplete: false,
    });

    //Destructuring the properties from the videoState
    const {
      // playing,
      muted,
      volume,
      playbackRate,
      played,
      seeking,
      buffer,
      pointAplayed,
      pointBplayed,
      isCounting,
      // countingComplete,
      annotationComplete,
    } = videoState;

    // const [bufferDebounced, setBufferDebounced] = useState(true);

    // const { clear, reset } = useTimeout(() => {
    //   console.log('ACTUALLY HIT THE SET BUFFER TO FALSE');
    //   setBufferDebounced(false);
    // }, 500);

    // useEffect(() => {
    //   console.log('👑👑 Buffer changed');
    //   if (buffer) {
    //     setBufferDebounced(true);
    //     clear();
    //   } else {
    //     reset();
    //   }
    // }, [buffer]);

    // const onEnded = () => {
    //   console.log('on ENeded');
    //   // setCountingComplete(true);
    //   // setVideoState({ ...videoState, played: 0, playing: true });
    // };

    //   const [snapshots, setSnapshots] = useState([]);
    const rippleButtonRef = useRef(null);

    // useEffect(() => {
    //   btn = rippleButtonRef.current;
    //   btn.addEvent;
    // }, []);

    const timePadding = 0; //0.18;

    const videoUrl = `${process.env.REACT_APP_CLOUDFRONT}videos/${url}/filename.m3u8`;
    const { fileExists, error, retries } = useFileExists(videoUrl);

    return (
      <div className={classes['container-video-separate']}>
        {error && <ModalOnlyText onModalClose={onModalClose} />}
        {/* wanted to use !annotationComplete as a conditional instead of true */}
        {true && (
          // <Container disableGutters={true} maxWidth="md" justify="center">
          <div
            className={classes['player__wrapper']}
            // onMouseMove={mouseMoveHandler}
            // style={{ height: '200px' }}
          >
            {displayTapButton && (
              <div className={classes['ripple-button-container']}>
                <button
                  ref={rippleButtonRef}
                  style={{ display: countingComplete ? 'none' : 'block' }}
                  className={classes['button-ripple']}
                  onClick={() => {
                    addBeatWithTap();
                    console.log('ripple click');
                    // rippleButtonRef.current;
                    let expandCircle = document.createElement('div');
                    expandCircle.classList.add(classes['expand-circle']);
                    const containerElement = document.querySelector(
                      `.${classes['ripple-button-container']}`
                    );
                    containerElement.appendChild(expandCircle);
                    setTimeout(() => {
                      document
                        .querySelector(`.${classes['expand-circle']}`)
                        .remove();
                    }, 2000);
                  }}
                >
                  Tap
                </button>
              </div>
            )}
            <ReactPlayer
              // style={{ margin: '2rem' }}
              id="myVideo"
              ref={videoPlayerRef}
              className={classes['player']}
              // url={url}
              // url="https://d2qxuoym2zs537.cloudfront.net/videos/filename.m3u8"
              url={fileExists && videoUrl}
              onStart={() => {
                console.log('ONSTART');
              }}
              config={{
                // hlsOptions: hlsConfig,
                // file: { forceVideo: true },
                file: {
                  forceHLS: true,
                  hlsOptions: HLSConfig,
                  hlsVersion: Hls.version,
                  hlsjs: Hls,
                },
              }}
              width="100%"
              height="100%"
              // style={{ overflow: 'hidden' }}
              playing={playing}
              volume={volume}
              muted={muted}
              // muted={true} //muted}
              onProgress={progressHandler}
              onBuffer={bufferStartHandler}
              onBufferEnd={bufferEndHandler}
              playbackRate={playbackRate}
              progressInterval={200}
              onReady={onReady}
              // nicks shit
              // loop={true}
              // controls
              // onEnded={onEnded}
              onPlay={() => console.log('onPLay')}
            />

            {buffer && (
              <CircleLoader>
                <>
                  <div>waiting for video conversion...</div>
                  <div>{`fetch attempt: ${retries + 1}/15`}</div>
                </>
              </CircleLoader>
            )}
          </div>
          // </Container>
        )}
      </div>
    );
  }
);

export default VideoSeparateUpload;
