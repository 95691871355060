import styledEngine from '@mui/styled-engine';
import React from 'react';
import classes from './Badge.module.css';

const Badge = ({ keyword, color = 'white', style = {} }) => {
  return (
    <div style={{ background: color, ...style }} className={classes.badge}>
      {/* {keyword.toUpperCase()} */}
      {capitalize(keyword)}
    </div>
  );
};

function capitalize(sentence) {
  return sentence
    .trim()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

export default Badge;
