import { Fragment } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import classes from './ModalFullTop.module.css';
import { height } from '@mui/system';

const Backdrop = ({ onClose }) => {
  return <div className={classes.backdrop} onClick={onClose} />;
};

const ModalOverlay = ({ onClose, children, fixedHeight }) => {
  return (
    <div
      className={`${classes.modal} ${
        fixedHeight
          ? classes['modal-fixed-height']
          : classes['modal-flexible-height']
      }`}
    >
      <div className={classes.content}>{children}</div>
      <button className={classes['exit-button']} onClick={onClose}>
        <IonIcon icon={closeOutline} size="medium" color="white"></IonIcon>
      </button>
    </div>
  );
};

const portalElement = document.getElementById('overlays');

const ModalFullTop = ({ onModalClose, children, fixedHeight = true }) => {
  const handleUserKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === 27) {
      onModalClose();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, []);

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={onModalClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay onClose={onModalClose} fixedHeight={fixedHeight}>
          {children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default ModalFullTop;
