import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './InputStyles.css';
import { useScroll } from 'framer-motion';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { videoActions } from '../store/video';

export default function AutoCompleteForNewVideo({
  label,
  setLabel,
  defaultLabel = 'basic',
}) {
  React.useEffect(() => {
    setLabel(defaultLabel);
  }, []);
  const [inputValue, setInputValue] = React.useState('');
  const autoCompleteInput = useSelector(
    (state) => state.video.autoCompleteInput
  );

  const options = autoCompleteInput?.labels || [];

  const resultHandler = (e, value) => {
    // console.log('result input rh: ', inputValue);
    // console.log('result value rh: ', value);
    setLabel(value);
  };

  return (
    <Autocomplete
      freeSolo
      id="label-new-videos"
      options={options}
      inputValue={label}
      // inputValue={inputValue}
      // onInputChange={(event, newInputValue) => {
      //   console.log('result input iv: ', inputValue);
      //   console.log('result input: ', newInputValue);
      //   console.log('result value: ', label);
      //   setInputValue(newInputValue);
      //   setLabel(null);
      // }}
      //   options={options.sort((a, b) => -b.group.localeCompare(a.group))}
      // groupBy={(option) => option.group}
      getOptionLabel={(option) => option}
      sx={{}}
      renderInput={(params) => (
        <TextField {...params} label="" placeholder={defaultLabel} />
      )}
      // multiple={true}
      onInputChange={resultHandler}
    />
  );
}
