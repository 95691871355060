import React, { useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import './Thumbnail.css';

export const Thumbnail = ({
  src,
  type = 'square',
  borderRadius = 0,
  style = {},
  children,
}) => {
  const imgEl = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const onImageLoaded = () => setLoaded(true);

  React.useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener('load', onImageLoaded);
      return () => imgElCurrent.removeEventListener('load', onImageLoaded);
    }
  }, [imgEl]);

  if (type === 'square') {
    return (
      <>
        <img
          className="main-thumb"
          ref={imgEl}
          src={src}
          alt="a placeholder"
          style={loaded ? style : { display: 'none' }}
        />
        {loaded && children}
        {!loaded && (
          <div style={{ borderRadius: borderRadius, overflow: 'hidden' }}>
            <Skeleton variant="rounded" height={160} />
          </div>
        )}
      </>
    );
  } else if (type === 'circle') {
    return (
      <>
        <img
          ref={imgEl}
          className="main-thumb"
          src={src}
          alt="a placeholder"
          style={
            loaded
              ? {
                  display: 'inline-block',
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                  borderRadius: '40px',
                }
              : { display: 'none' }
          }
        />
        {loaded && children}
        {!loaded && <Skeleton variant="circular" height="100%" />}
      </>
    );
  }
};
