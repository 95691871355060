import React from 'react';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';

const portalElement = document.getElementById('video');

const Backdrop = ({ onClose }) => {
  return <div className={classes['backdrop-radial']} onClick={onClose} />;
};

export default function VideoContainerModal({ children, onModalClose }) {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={onModalClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(children, portalElement)}
    </>
  );
}
