import { useEffect, useRef, useState } from 'react';
import useMousePosition from './useMousePosition';
import { drawCircle } from './drawCircle';

const TrackingBall = ({
  onNewCoord,
  onClearCoords,
  onPlayPause,
  setShowVisibleSpeed,
  slowmoCoords,
}) => {
  const canvasRef = useRef(null);
  const [coords, handleCoords] = useMousePosition();
  const [showInstructions, setShowInstructions] = useState(() => {
    return slowmoCoords ? false : true;
  });
  const [traceComplete, setTraceComplete] = useState(true);

  // if there exist coordinates already, draw them
  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    if (slowmoCoords) {
      slowmoCoords.forEach((coord) => {
        console.log(coord);
        // requestAnimationFrame(function ball() {
        drawCircle(ctx, {
          radius: 4,
          lineWidth: 3,
          strokeStyle: 'rgb(92, 37, 202)',
          colorFill: 'rgb(92, 37, 202)',
          startY: coord.y,
          startX: coord.x,
          // });
        });
      });
    }
  }, []);

  useEffect(() => {
    if (canvasRef.current && coords.x) {
      const ctx = canvasRef.current.getContext('2d');

      requestAnimationFrame(function ball() {
        drawCircle(ctx, {
          radius: 4,
          lineWidth: 3,
          strokeStyle: 'rgb(92, 37, 202)',
          colorFill: 'rgb(92, 37, 202)',
          startY: coords.y,
          startX: coords.x,
        });
      });
    }

    onNewCoord(coords);
  }, [coords.x, coords.y]);

  return (
    <>
      {/* <h1>Tracking ball</h1> */}
      <div
        style={{
          // position: 'absolute',
          // backgroundColor: 'red',
          zIndex: '156',
          // bottom: '0px',
          // left: '0',
          //   margin: 'auto',
          //   width: 'fit-content',
          // width: '100vw',
          // height: '150px',
          // bottom: '10px',
          //   height: '150px',
          // padding: '10px',
          marginBottom: '16px',
        }}
      >
        <div
          style={{
            position: 'relative',
            zIndex: '156',
            bottom: '0px',
            left: '0',
            //   margin: 'auto',
            //   width: 'fit-content',
            width: '300px',
            height: '150px',
            margin: 'auto',
            // backgroundColor: 'blue',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              // position: 'absolute',
              // left: '-50px',
              width: '50px',
              paddingRight: '5px',
              // top: 0,
              height: '100%',
              // backgroundColor: 'orange',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            {['2x', '1x', '0.1x'].map((el) => (
              <div
                style={{ color: 'rgb(170,170,170)', whiteSpace: 'nowrap' }}
              >{`${el} -`}</div>
            ))}
          </div>
          <div style={{ position: 'relative' }}>
            {showInstructions && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-43%, -50%)',
                  zIndex: '180',
                  color: 'gray',
                  fontSize: '1.5rem',
                  padding: '-100px',
                  pointerEvents: 'none',
                  //   wordWrap: 'normal',
                  //   margin: '0',
                }}
              >
                Trace path to manipulate
              </div>
            )}
            <canvas
              id="canvas2"
              ref={canvasRef}
              position="absolute"
              onTouchStart={() => setTraceComplete(false)}
              onTouchMove={(e) => {
                console.log('moviement');
                handleCoords(e);
                setShowInstructions(false);
              }}
              onTouchEnd={() => {
                onPlayPause();
                setTraceComplete(true);
              }}
              style={{
                border: '2px solid black',
                //   zIndex: '156',
                background: 'white',
                borderRadius: '15px',
                // position: 'absolute',
                // bottom: '0',
              }}
            ></canvas>
            {!showInstructions && traceComplete && (
              <button
                style={{
                  border: 'none',
                  // border: '2px solid black',
                  // borderRadius: '25px',
                  zIndex: '159',
                  position: 'absolute',
                  color: 'var(--main-purple-no-transparancy)',
                  backgroundColor: 'transparent',
                  fontSize: '20px',
                  // fontWeight: 'bold',
                  textDecoration: 'underline',
                  right: '5px',
                  bottom: '2px',
                }}
                onClick={() => {
                  if (canvasRef.current) {
                    const ctx = canvasRef.current.getContext('2d');
                    ctx?.clearRect(0, 0, 400, 150);
                  }
                  onClearCoords();
                  setShowInstructions(true);
                }}
              >
                clear
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingBall;
