import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { deepOrange, deepPurple } from '@mui/material/colors';
import './AccountMenu.css';
import Modal from '../Modal';
import CreateNewUserByAdmin from '../CreateNewUserByAdmin';
import QuizIcon from '@mui/icons-material/Quiz';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import { useDispatch, useSelector } from 'react-redux';
import { videoActions } from '../../store/video';
import { tourActions } from '../../store/tour';
import AvatarPhotoOrLetters from './AvatarPhotoOrLetters';
import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import ForMobileTooltip from '../ForMobileTooltip';

export default function AccountMenu({
  userPhoto,
  userName,
  userRole,
  onLogout,
  onSettings,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [createUser, setCreateUser] = React.useState(false);
  const [openTutorial, setOpenTutorial] = React.useState(false);
  const [isMobile] = useSelector((state) => [state.settings.isMobile]);
  // const open = true;
  const open = Boolean(anchorEl);
  const savedLeftAnchor = React.useRef();

  React.useEffect(() => {
    // console.log({ anchorEl });
    // console.log('offsetLeft: ', anchorEl?.offsetLeft);
    // if (!savedLeftAnchor.current) {
    //   savedLeftAnchor.current = anchorEl;
    // }
  }, [anchorEl]);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenTutorial(false);
  };

  const toggleSubmenu = () => {
    setOpenTutorial(!openTutorial);

    // Force the menu to recalculate its position by resetting anchorEl

    // if (anchorEl) {
    //   // setAnchorEl(4);
    //   setTimeout(() => {
    //     setAnchorEl((prev) => {
    //       // const next = JSON.parse(JSON.stringify(prev));
    //       const next = React.cloneElement(prev);
    //       // const next = { ...prev };
    //       // next.offsetLeft = next.offsetLeft - 2;
    //       return next;
    //     });
    //   }, 500);
    // }
  };

  return (
    <React.Fragment>
      {createUser && (
        <Modal
          fixedHeight={false}
          onModalClose={() => {
            setCreateUser(false);
          }}
        >
          <CreateNewUserByAdmin
            onModalClose={() => {
              setCreateUser(false);
            }}
          />
        </Modal>
      )}

      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          // sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AvatarPhotoOrLetters userPhoto={userPhoto} userName={userName} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {userRole === 'admin' && (
          <>
            <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setCreateUser(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Create account
            </MenuItem>

            <MenuItem
              onClick={() => {
                onSettings();
                handleClose();
              }}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => {
            // onSettings();
            // handleClose();
            // setOpenTutorial(!openTutorial);
            // console.log('anchor: ', anchorEl);
            // setAnchorEl(savedAnchor.current);
            toggleSubmenu();
          }}
        >
          <ListItemIcon>
            <QuizIcon fontSize="small" />
          </ListItemIcon>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            <div>Tutorials</div>
            {openTutorial ? <ExpandLess /> : <ExpandMore />}
          </div>
        </MenuItem>
        <Collapse in={openTutorial} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItemButton > */}
            {[
              {
                tourName: 'usingAnnotatedList',
                tourLabel: 'Video player navigation',
                icon: <CircleIcon style={{ fontSize: '10px' }} />,
              },
              {
                tourName: 'creatingSection',
                tourLabel: 'Create New Section',
                icon: <CircleIcon style={{ fontSize: '10px' }} />,
              },
            ].map(({ tourName, tourLabel, icon }) => (
              <ForMobileTooltip isMobile={isMobile}>
                <MenuItem
                  disabled={!isMobile}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    dispatch(videoActions.showFirstAvailableVideo());
                    dispatch(tourActions.startTour(tourName));
                    handleClose();
                  }}
                  style={{
                    // color: 'var(--text-light-gray)',
                    backgroundColor: 'var(--background-light-gray)',
                  }}
                >
                  {/* <ListItemIcon>{icon}</ListItemIcon> */}
                  {tourLabel}
                </MenuItem>
              </ForMobileTooltip>
            ))}
          </List>
        </Collapse>
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
