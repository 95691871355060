import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { useDispatch } from 'react-redux';

export default function MenuExtraCardOptions({
  onDelete,
  // practiceSectionsDisplayed,
  // setPracticeSectionsDisplayed,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          fontSize: '28px',
          color: 'black',
          height: '40px',
          width: '40px',
          minWidth: '40px',
        }}
      >
        <MoreVertOutlinedIcon style={{ fontSize: '25px' }} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {/* <MenuItem
          onClick={() => {
            handleClose();
            // setPracticeSectionsDisplayed('unFinishedToday');
          }}
          // disabled={practiceSectionsDisplayed === 'unFinishedToday'}
        >
          Archive
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            handleClose();
            onDelete();
          }}
          // disabled={practiceSectionsDisplayed === 'finishedToday'}
        >
          Delete
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </>
  );
}
