import React, { useEffect, useRef, useState } from 'react';
import './EditableText.css';
import determineClickCountFactory from '../components/determineClickCount';
import { useUpdateNote } from './video';
const determineClickCount = determineClickCountFactory();
let count = 0;
let singleClickTimer;

const EditableText = ({
  initialText,
  fontSize = '1rem',
  color = 'black',
  type = '',
  onSave,
  disabled = false,
  index,
  userVideoDataId,
  isEditingParent = false,
  setisEditingParent = () => {},

  //   fontWeight = 'normal',
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [sentSuccessfully, setSentSuccessfully] = useState(false);
  const textRef = useRef(null);

  // state for sending note to mongoDB to update in database
  // const [isSending, setIsSending] = useState(false)
  // const [errorSending, setErrorSending] = useState(null)

  useEffect(() => {
    // console.log('👩🏻‍🦳 textREF: ', textRef.current?.focus);
    if (textRef.current) {
      textRef.current.focus();
      textRef.current.setSelectionRange(
        textRef.current.value.length,
        textRef.current.value.length
      );
    }
  }, [isEditing]);

  useEffect(() => {
    // console.log('👩🏻‍🦳 textREF: ', textRef.current?.focus);
    setisEditingParent(isEditing);
  }, [isEditing]);

  // useEffect(() => {
  //   // console.log('sentSuccessfully: ', sentSuccessfully);
  //   if (sentSuccessfully) {
  //     setTimeout(() => {
  //       setSentSuccessfully(false);
  //     }, 1000);
  //   }
  // }, [sentSuccessfully]);

  // if()

  const {
    loading: isSending,
    error: errorSending,
    sendData: sendDataFromHook,
  } = useUpdateNote(userVideoDataId, index, text, [text], () => {
    setSentSuccessfully(true);
    setTimeout(() => {
      setSentSuccessfully(false);
    }, 1000);
  });

  useEffect(() => {
    console.log('in FUCKNG USE EFFT FOR SENDING NOTE');
    console.log('isSending:', isSending);
    console.log('errorSending:', errorSending);
  }, [isSending, errorSending]);
  // { $set: { "some_key.param2" : new_info  }

  const textStyle = {
    fontSize,
    // color,
  };

  const handleClick = () => {
    if (disabled) return;
    determineClickCount(
      () => {},
      () => {
        setIsEditing(true);
      }
    );
    // count++;
    // if (count === 1) {
    //   if (isEditing) return;
    //   setIsEditing(true);
    //   singleClickTimer = setTimeout(() => {
    //     // singleClickFunc();
    //     setIsEditing(false);
    //     count = 0;
    //   }, 250);
    // } else if (count === 2) {
    //   clearTimeout(singleClickTimer);
    //   //   doubleClickFunc();
    //   console.log('double lclickckckk');
    //   count = 0;
    // }
  };

  const handleChange = (event) => {
    console.log(JSON.stringify({ text: text }));
    setText(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // onSave(type, text);
    console.log('about to hit send data from hok');
    if (textRef) {
      sendDataFromHook();
    }
    // Save the changes or perform any required actions here
  };

  return (
    <div
      onClick={handleClick}
      style={{
        position: 'relative',
        width: '100%',
        // marg
        // height: '150px',
        // backgroundColor: 'green',
        // overflow: 'auto',
      }}
    >
      <div
        style={{
          left: '0',
          top: '0',
          right: '0',
        }}
      >
        {isEditing && (
          <textarea
            ref={textRef}
            className="editable-text"
            type="text"
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            style={textStyle}
          />
        )}
        <p className="text-content-p" style={{ ...textStyle, color }}>
          {`${text.length > 0 ? text : ' '}${text.endsWith('\n') ? ' ' : ''}`}
          <span style={{ color: 'lightgray' }}>
            {!disabled && !isEditing ? '  ✎' : ''}
          </span>
        </p>
        {isSending && <div className="status-message">saving...</div>}
        {sentSuccessfully && <div className="status-message">saved!</div>}
        {errorSending && (
          <div className="status-message" style={{ color: 'red' }}>
            <span>failed to save!</span>
            <span className="retry-send-button" onClick={sendDataFromHook}>
              retry
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableText;
