import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './PracticeCard.css';
import { videoActions } from '../../store/video';
import { useDispatch } from 'react-redux';
import MenuExtraCardOptions from './MenuExtraCardOptions';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Thumbnail } from '../Thumbnail';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

let count = 0;

function PracticeCard({
  title,
  notes,
  id,
  videoData,
  thumbnail,
  sections,
  onTreeVideoClickHandler,
  sectionIndices,
  practiceSectionsDisplayed,
  // practiceIndex,
  hidden,
  idAttribute,
  // frequency
  swipeIn,
  completedTimes,
  looptime,
  hideFunc,
}) {
  const dispatch = useDispatch();

  console.log('👠 Practice card rerender: ', count++, title);
  // console.log('props: ', {
  //   title,
  //   notes,
  //   id,
  //   // videoData,
  //   sections,
  //   // onTreeVideoClickHandler,
  //   sectionIndices,
  //   practiceSectionsDisplayed,
  //   // practiceIndex,
  //   hidden,
  //   idAttribute,
  //   // frequency
  //   swipeIn,
  //   // completedTimes,
  // });

  const onXClickHandler = (e, id) => {
    // e.stopPropagation();
    // console.log();
    return async (dispatch, getState) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/sections/${id}`,
        {
          method: 'DELETE',
        }
      );
      if (!response.ok) {
        console.log('in failed if');
        alert('fail');
      } else {
        // alert('success in deletion of section');
        dispatch(videoActions.removeSavedSelection(id));
      }
    };
  };

  const chartDates = generateChartDates(16);
  const daysOfWeek = getDayOfWeekShort(chartDates);
  const indices = findIndices(completedTimes, chartDates);
  // console.log({ indices });
  indices.forEach((obj) => {
    daysOfWeek[obj.index] = (
      <div className="single-day-block complete">
        {obj.obj.action === 'complete' ? '' : ''}
      </div>
    );
    const freq = obj.obj.frequency;
    for (let i = 1; i < freq; i++) {
      daysOfWeek[obj.index + i] = <div className="single-day-block rest"></div>;
    }
  });
  daysOfWeek[daysOfWeek.length - 1] =
    practiceSectionsDisplayed === 'unFinishedToday' ? (
      <div className="single-day-block current-day"></div>
    ) : (
      <div className="single-day-block complete"></div>
    );

  // console.log({ practiceSectionsDisplayed });

  return (
    <div
      // id={id}
      idAttribute={idAttribute}
      // key={idAttribute}
      className={`practice-container-outer ${hidden ? 'hidden' : ''} ${
        swipeIn ? 'swipe-in' : ''
      }`}
      style={{ position: 'relative' }}
    >
      {/* <Confetti /> */}

      <div
        className={`${
          practiceSectionsDisplayed === 'completedCards'
            ? 'filter-div-practice-card'
            : ''
        }`}
        style={{ height: '100%' }}
        // style={{ perspective: '1000px' }}
      >
        <Card
          sx={{
            maxWidth: 305,
            width: '80vw',

            margin: 0,
            backgroundColor: 'white',
            color: 'black',
            height: '97%',
            // height: '60vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            // boxShadow: 'none',
            boxShadow: '2px 1px 14px 0 rgb(207, 207, 207)',
            // margin: '20px',
          }}
        >
          <div style={{ position: 'relative' }}>
            <Thumbnail
              src={thumbnail}
              borderRadius="0px"
              style={{
                height: '190px',
                width: '100%',
                objectFit: 'cover',
                objectPosition: '50% 25%',
              }}
            >
              <div
                className="play-icon-container"
                onClick={() => {
                  onTreeVideoClickHandler(
                    videoData?.id,
                    sectionIndices,
                    looptime
                    // practiceIndex
                  );
                }}
              >
                <PlayArrowRoundedIcon
                  style={{
                    fontSize: '70px',
                    color: '#FFFFFF77',
                    filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.41))',
                  }}
                />
              </div>
            </Thumbnail>
            {/* <div className="completed-days-container">{daysOfWeek}</div> */}

            {/* <Liked /> */}
          </div>
          <div
            style={{
              flexGrow: 1,
              position: 'relative',
              height: '50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CardContent style={{ flexGrow: 1, overflow: 'scroll' }}>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  zIndex: 500,
                }}
              >
                <MenuExtraCardOptions
                  onDelete={(e) => {
                    dispatch(onXClickHandler(e, id));
                    hideFunc();
                  }}
                />
              </div>
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  // justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {title}
                </Typography>
                {/* <div className="completed-days-container">{daysOfWeek}</div> */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  // style={{ flexGrow: 1, height: '100px', overflow: 'scroll' }}
                >
                  {notes}
                </Typography>
                <div>
                  <div className="label-container-practice">
                    {sections?.map((label, index) => (
                      <>
                        <div className="label-in-practice-cards">
                          {label.length === 0 ? '??' : label}
                        </div>
                        {sections?.length - 1 != index && (
                          <div className="arrow-between-labels"></div>
                          // <div className="arrow-between-labels">→</div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </CardContent>
            {/* <div className="completed-days-container">{daysOfWeek}</div> */}
          </div>
        </Card>
      </div>
    </div>
  );
}

function getBrightness(hex) {
  var rgb =
    'rgb(' +
    (hex = hex.replace('#', ''))
      .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
      .map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16);
      })
      .join(',') +
    ')';

  // Get array of RGB values
  rgb = rgb.replace(/[^\d,]/g, '').split(',');

  var r = rgb[0],
    g = rgb[1],
    b = rgb[2];

  var brightness = Math.floor((r * 299 + g * 587 + b * 114) / 1000);

  return brightness;
}

function indexOfSmallest(a) {
  return a.indexOf(Math.min.apply(Math, a));
}

// if (videoData.dominantColors) {
//     backgroundColor =
//       videoData.dominantColors[
//         indexOfSmallest(
//           videoData.dominantColors.map((color) => getBrightness(color))
//         )
//       ];
//   }
function generateChartDates(days) {
  const chartDates = [];
  const now = new Date();
  now.setHours(0, 0, 0, 0); // Set to midnight

  for (let i = 0; i < days; i++) {
    const date = new Date(now);
    date.setDate(now.getDate() - i);
    chartDates.unshift(date);
  }

  return chartDates;
}
function getDayOfWeekShort(chartDates) {
  const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']; // Array of day abbreviations

  return chartDates.map((date) => {
    const dayIndex = date.getDay(); // Get the day index (0-6, 0 = Sunday, 6 = Saturday)
    return (
      <div className="single-day-block default">{daysOfWeek[dayIndex]}</div>
    );
  });
}

function findIndices(importedArray, chartDates) {
  const indices = [];

  importedArray.forEach((obj) => {
    const objDate = new Date(obj.dateCompleted);
    objDate.setHours(0, 0, 0, 0); // Set to midnight

    if (objDate < chartDates[0]) {
      return; // Ignore dates less than the first chart date
    }

    for (let i = 0; i < chartDates.length - 1; i++) {
      if (objDate >= chartDates[i] && objDate < chartDates[i + 1]) {
        indices.push({ obj, index: i });
        break;
      }
    }

    // Handle case where objDate is exactly equal to the last date in chartDates
    if (objDate.getTime() === chartDates[chartDates.length - 1].getTime()) {
      indices.push({ obj, index: chartDates.length - 1 });
    }
  });

  return indices;
}

export default React.memo(PracticeCard);
