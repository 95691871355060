import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { TourProvider } from '@reactour/tour';

import './index.css';
import App from './App';
import store from './store/index';
import { DragDropContext } from 'react-beautiful-dnd';
import { Mask } from '@reactour/mask';
// import dotenv from 'dotenv';
// dotenv.config();

const steps = [
  {
    selector: '.section-bar-5',
    content: 'This is my first Step',
  },
  // ...
];
const sizes = {
  width: 100,
  height: 100,
  // top: 100,
  // left: 100,
};
// Bootstrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
// import 'bootstrap/dist/js/bootstrap.bundle.min';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TourProvider
    // className="tour-double-click"
    // highlightedAreaClassName="tour-double-click"
    highlightedMaskClassName="lll"
    steps={steps}
    styles={{
      // maskArea: (base) => ({ ...base, rx: '12px' }),
      // highlightedArea: (base, { x, y, width, height }) => {
      //   console.log({ width });
      //   return {
      //     ...base,
      //     display: 'block',
      //     stroke: '#f00',
      //     strokeWidth: 4,
      //     width: width + 140,
      //     height: height + 140,
      //     rx: 10,
      //     x: x - 7,
      //     y: y - 7,
      //     pointerEvents: 'none',
      //   };
      // },
      maskWrapper: (base) => ({ ...base, color: 'transparent' }),
    }}
  >
    {/* <Mask highlightedAreaClassName="tour-double-click" /> */}
    <Provider store={store}>
      {/* <DragDropContext> */}
      <App />
      {/* </DragDropContext> */}
    </Provider>
  </TourProvider>
);
