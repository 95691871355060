import { createSlice } from '@reduxjs/toolkit';

const intialPlayerState = {
  //   isAuthenticated: false,
  //   user: null,
  primarySelected: false,
};

const playerSlice = createSlice({
  name: 'playerControls',
  initialState: intialPlayerState,
  reducers: {
    togglePrimary(state) {
      state.primarySelected = !state.primarySelected;
    },
    // login(state, action) {
    //   state.isAuthenticated = true;
    //   state.user = action.payload;
    // },
    // logout(state) {
    //   state.isAuthenticated = false;
    // },
  },
});

export const playerActions = playerSlice.actions;
export default playerSlice.reducer;
