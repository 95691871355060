import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../components/Modal';
import ModalInline from '../components/ModalInline';
import EditableText from './EditableText';

import SingleNote from './SingleNote';

export default function Notes({ onModalClose }) {
  const [notesArray, sections, userVideoDataId] = useSelector((state) => [
    state.video.notesArray,
    state.video.currentVideo.sections,
    state.video.userVideoData._id,
  ]);

  const [isEditingParent, setisEditingParent] = useState(false);
  // const allSectsWNotes = useSelector(
  //   (state) => state.video.currentSavedSelections
  // );

  console.log('in fucking notes');
  console.log('notesArray');
  console.log(notesArray);
  console.log('sections');
  console.log(sections);
  // console.log('allSectsWNotes: ', allSectsWNotes);

  return (
    <ModalInline
      onModalClose={onModalClose}
      fixedHeight={!isEditingParent}
      // keyboardHeight={isEditingParent}
    >
      {/* <Modal onModalClose={onModalClose}> */}
      <main
        className="outsideContainer"
        style={{
          position: 'absolute',
          top: '25px',
          bottom: '15px',
          left: 0,
          right: 0,
          // backgroundSize: '40px 20px',
          // backgroundImage:
          //   'linear-gradient(to bottom, #99ccff 1px, transparent 1px)',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',

            // marginTop: '35px',
            padding: '0 25px 0',
            // margin: '10px',
            // border: '1px solid gray',
            boxSizing: 'border-box',
            overflowY: 'scroll',
          }}
        >
          <div>
            {notesArray?.map((note, index) => {
              // const { id, labnote, notes } = el;
              if (note) {
                return (
                  <SingleNote
                    // id={index}
                    label={sections.at(index)?.label}
                    notes={note}
                    index={index}
                    userVideoDataId={userVideoDataId}
                    isEditingParent={isEditingParent}
                    setisEditingParent={setisEditingParent}
                  />
                );
              } else return '';
            })}
            {notesArray.join('').length === 0 && <p>No notes yet!</p>}
          </div>

          {/* <p
            style={{
              textAlign: 'center',
              borderTop: '1px solid #999999',
              paddingTop: '10px',
              marginTop: '15px',
              color: '#999999',
            }}
          >
            + Add New Note
          </p> */}
        </div>
      </main>
    </ModalInline>
  );
}
