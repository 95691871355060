import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './PracticeCardListItem.css';
import { videoActions } from '../../store/video';
import { useDispatch } from 'react-redux';
import MenuExtraCardOptions from './MenuExtraCardOptions';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import MenuForChangeColumn from './MenuForChangeColumn';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import CountIncrement from './CountIncrement';
import Divider from '@mui/material/Divider';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { motion } from 'framer-motion';
import TimerOverlay from '../StopWatch/TimerOverlay';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';

let renderCount = 0;

function PracticeCardListItem({
  item,
  onTreeVideoClickHandler,
  practiceSectionsDisplayed,
  dragHandleProps,
  columns,
  setColumns,
  onChangeColumn,
  onAddNewColumn,
  sourceIndex,
  index,
  updateCount,
  isSectionBeingTimed,
  setSectionBeingTimed,

  activeSectionTimer,
  setActiveSections,
}) {
  const {
    label,
    notes,
    id,
    video,
    sectionLabels,
    sections,
    completedTimes,
    looptime,
    count,
  } = item;

  const dispatch = useDispatch();

  const [expandedStopwatch, setExpandedStopwatch] = useState(() => {
    const data = JSON.parse(localStorage.getItem('stopwatch-new'));
    const timeStorage = data ? (data[id] ? true : false) : false;
    return timeStorage;
  });

  console.log('👠 Practice card rerender: ', renderCount++, label);

  function onXClickHandler(e, id) {
    // e.stopPropagation();
    // console.log();
    return async (dispatch, getState) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/sections/delete/${id}`,
        {
          method: 'PATCH',
          credentials: 'include', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log({ response });
      const data = await response.json();
      console.log({ data });
      if (!response.ok) {
        console.log('in failed if');
        alert('fail');
      } else {
        // alert('success in deletion of section');
        console.log(
          'practice dictoniary pre dis: '
          // data.data.practiceDictionary
        );
        dispatch(
          videoActions.removeSavedSelection({
            id,
            practiceDictionary: data.data.practiceDictionary,
          })
        );
      }
    };
  }

  const chartDates = generateChartDates(16);
  const daysOfWeek = getDayOfWeekShort(chartDates);
  const indices = findIndices(completedTimes, chartDates);
  // console.log({ indices });
  indices.forEach((obj) => {
    daysOfWeek[obj.index] = (
      <div className="single-day-block complete">
        {obj.obj.action === 'complete' ? '' : ''}
      </div>
    );
    const freq = obj.obj.frequency;
    for (let i = 1; i < freq; i++) {
      daysOfWeek[obj.index + i] = <div className="single-day-block rest"></div>;
    }
  });
  daysOfWeek[daysOfWeek.length - 1] =
    practiceSectionsDisplayed === 'unFinishedToday' ? (
      <div className="single-day-block current-day"></div>
    ) : (
      <div className="single-day-block complete"></div>
    );

  const playVideoHandler = () => {
    onTreeVideoClickHandler(
      video?.id,
      sections,
      looptime

      // practiceIndex
    );
  };
  // console.log({ practiceSectionsDisplayed });

  return (
    // <div
    //   // id={id}
    //   idAttribute={idAttribute}
    //   // key={idAttribute}
    //   className={`practice-container-outer ${hidden ? 'hidden' : ''} ${
    //     swipeIn ? 'swipe-in' : ''
    //   }`}
    //   style={{ position: 'relative' }}
    // >

    <div
      className={`card-and-stats ${
        practiceSectionsDisplayed === 'completedCards'
          ? 'filter-div-practice-card'
          : ''
      }`}
      style={{ height: '100%' }}
      // style={{ perspective: '1000px' }}
    >
      {/* <div className={`end-caps`} /> */}
      <div
        className="card-sx card-sx-specific"
        sx={
          {
            // borderRadius: '8px',
            // width: '320px',
            // minWidth: '290px',
            // maxWidth: '370px',
            // flexGrow: 1,
            // margin: 1,
            // backgroundColor: 'white',
            // color: 'black',
            // height: '160px',
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'space-between',
            // boxShadow: '2px 1px 4px 0 rgb(207, 207, 207)',
            // position: 'relative',
          }
        }
      >
        {/* {expandedStopwatch && ( */}
        {activeSectionTimer && (
          <TimerOverlay
            id={id}
            label={label}
            setSectionBeingTimed={setSectionBeingTimed}
            setActiveSections={setActiveSections}
            isSectionBeingTimed={isSectionBeingTimed}
            playVideoHandler={playVideoHandler}
          />
        )}
        <div
          className="main-content-card"
          style={{
            width: '100%',
            position: 'relative',
            // height: '110px',
            // minHeight: '110px',
            // height: '100%',
            // minHeight: '100%',
            display: 'flex',
            flexDirection: 'row',
            // gap: '10px',
          }}
        >
          <div
            style={{
              position: 'relative',
              // height: '100%',
              width: '85px',
              minWidth: '85px',

              // objectFit: 'cover',
              // objectPosition: '50% 25%',
            }}
          >
            {/* <div className="circle-overlap">
              <PlayArrowRoundedIcon
                style={{
                  fontSize: '60px',
                  color: 'var(--text-light-gray)',
                  // color: '#FFFFFFAA',
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.41))',
                }}
              />
            </div> */}
            <img
              src={video.thumbnail}
              borderRadius="0px"
              style={{
                height: '100%',
                width: '100%',
                // width: '75px',
                // aspectRatio: 1,

                objectFit: 'cover',
                objectPosition: '50% 25%',
                // objectViewBox: 'inset(7% 7% 16% 0)',
                // borderRadius: '4px',
              }}
            ></img>

            <div className="play-icon-container" onClick={playVideoHandler}>
              <PlayArrowRoundedIcon
                style={{
                  fontSize: '60px',
                  color: '#FFFFFFAA',
                  // color: '#FFFFFFAA',
                  filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.41))',
                }}
              />
            </div>
          </div>
          <CardContent
            style={{ flexGrow: 1, overflow: 'hidden', padding: '12px' }}
          >
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 500,
                // padding: 0,
              }}
            >
              <MenuExtraCardOptions
                onDelete={(e) => {
                  dispatch(onXClickHandler(e, id));
                }}
              />
            </div>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                // justifyContent: 'space-between',
                height: '100%',
                // padding: 0,
              }}
            >
              <div
                className="flex-row-align-center"
                style={{ marginBottom: '5px' }}
              >
                <Typography
                  gutterBottom
                  // variant="h6"
                  component="div"
                  fontSize={15}
                  style={{ marginBottom: 0 }}
                >
                  {label}
                </Typography>
                {/* <div
                  className="flex-row-align-center play-text-and-icon"
                  onClick={() => {
                    onTreeVideoClickHandler(
                      video?.id,
                      sections,
                      looptime

                      // practiceIndex
                    );
                  }}
                >
                  <VideocamIcon />
                  <div>PLAY</div>
                </div> */}
              </div>
              {/* <div className="completed-days-container">{daysOfWeek}</div> */}
              <Typography
                variant="body2"
                color="text.secondary"
                // style={{ flexGrow: 1, height: '100px', overflow: 'scroll' }}
              >
                {notes}
              </Typography>
              <div style={{ flexGrow: 1, pointerEvents: 'none' }}>
                <div
                  className="label-container-practice-card"
                  style={{ gap: '0 6px' }}
                >
                  {sectionLabels?.map((label, index) => {
                    if (index > 0 && label === sectionLabels[index - 1]) return;
                    return (
                      <>
                        <div className="label-in-practice-cards-col">
                          {label.length === 0 ? '??' : label}
                        </div>
                        {/* {sectionLabels?.length - 1 != index && (
                          <div className="arrow-between-labels">·</div>
                        )} */}
                      </>
                    );
                  })}
                </div>
              </div>
              {/* <Divider sx={{ color: 'black', opacity: 0.6 }} /> */}
              <div
                className="card-button-container"
                style={
                  {
                    // flexGrow: 1,
                    // position: 'relative',
                    // height: '100%',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'space-between',
                    // zIndex: 2000,
                    // padding: 0,
                  }
                }
              >
                {/* <div className="completed-days-container">{daysOfWeek}</div> */}
                <div className="card-button-bottom">
                  <TimerOutlinedIcon
                    style={{
                      color: 'var(--text-lighter-gray)',
                      fontSize: '28px',
                    }}
                    onClick={() => {
                      setActiveSections((prev) => [...prev, id]);
                      // setExpandedStopwatch((prev) => !prev);
                      setSectionBeingTimed({ label: label, id: id });
                    }}
                  />
                </div>
                <div className="card-button-bottom">
                  <BarChartOutlinedIcon
                    style={{
                      color: 'var(--text-lighter-gray)',
                      fontSize: '28px',
                    }}
                  />
                </div>
                {/* {expandedStopwatch && (
                  <div className="stop-watch-time">5:34</div>
                )} */}
                {/* <div className="move-button-container"> */}
                <div style={{ flexGrow: 1 }}></div>
                <MenuForChangeColumn
                  item={item}
                  columns={columns}
                  setColumns={setColumns}
                  onChangeColumn={onChangeColumn}
                  sourceIndex={sourceIndex}
                  onAddNewColumn={onAddNewColumn}
                >
                  <div
                    className="card-button-bottom "
                    // className="drag-button"
                  >
                    <DriveFileMoveOutlinedIcon
                      style={{
                        color: 'var(--text-lighter-gray)',
                        fontSize: '27px',
                      }}
                    />
                  </div>
                </MenuForChangeColumn>
                <div
                  className="card-button-bottom "
                  //  className="drag-button"
                  {...dragHandleProps}
                >
                  <DragHandleIcon
                    style={{
                      color: 'var(--text-lighter-gray)',
                      fontSize: '30px',
                    }}
                  />
                </div>
                {/* </div> */}
              </div>
              {/* <div className="logo-and-info">
                <div>
                  <AvatarPhotoOrLetters
                    userName="AS"
                    userPhoto={undefined}
                    // border="4px solid white"
                  />
                </div>
                <div>
                  <div style={{ fontWeight: '' }}>Art of Sensual Bachata</div>
                  <div className="class-description">Int/Adv</div>
                </div>
              </div> */}
            </div>
          </CardContent>
          {/* <div className="completed-days-container">{daysOfWeek}</div> */}

          {/* <Liked /> */}
        </div>
        {false && (
          <div
            initial={{ height: '0', y: '-73px' }}
            animate={{ height: 'auto', y: '0px' }}
            // initial={{ y: '-100px' }}
            // animate={{ y: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="stopwatch-more-options"
          >
            <div style={{ flexGrow: 1 }} />
            <div className="laptime-container">
              <div>3:40</div>
              <div>12:05</div>
              <div>5:25</div>
            </div>
            <div className="save-time-button">save</div>
          </div>
        )}
        {/* <Divider variant="middle" sx={{ color: 'black', opacity: 0.8 }} /> */}
      </div>
      {/* <div className="flexible-upvote-outer-container">
        <CountIncrement
          count={count}
          id={id}
          setColumns={setColumns}
          updateCount={updateCount}
        />
      </div> */}
    </div>
    // </div>
  );
}

// function getBrightness(hex) {
//   var rgb =
//     'rgb(' +
//     (hex = hex.replace('#', ''))
//       .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
//       .map(function (l) {
//         return parseInt(hex.length % 2 ? l + l : l, 16);
//       })
//       .join(',') +
//     ')';

//   // Get array of RGB values
//   rgb = rgb.replace(/[^\d,]/g, '').split(',');

//   var r = rgb[0],
//     g = rgb[1],
//     b = rgb[2];

//   var brightness = Math.floor((r * 299 + g * 587 + b * 114) / 1000);

//   return brightness;
// }

function indexOfSmallest(a) {
  return a.indexOf(Math.min.apply(Math, a));
}

// if (video.dominantColors) {
//     backgroundColor =
//       video.dominantColors[
//         indexOfSmallest(
//           video.dominantColors.map((color) => getBrightness(color))
//         )
//       ];
//   }
function generateChartDates(days) {
  const chartDates = [];
  const now = new Date();
  now.setHours(0, 0, 0, 0); // Set to midnight

  for (let i = 0; i < days; i++) {
    const date = new Date(now);
    date.setDate(now.getDate() - i);
    chartDates.unshift(date);
  }

  return chartDates;
}
function getDayOfWeekShort(chartDates) {
  const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']; // Array of day abbreviations

  return chartDates.map((date) => {
    const dayIndex = date.getDay(); // Get the day index (0-6, 0 = Sunday, 6 = Saturday)
    return (
      <div className="single-day-block default">{daysOfWeek[dayIndex]}</div>
    );
  });
}

function findIndices(importedArray, chartDates) {
  const indices = [];

  importedArray.forEach((obj) => {
    const objDate = new Date(obj.dateCompleted);
    objDate.setHours(0, 0, 0, 0); // Set to midnight

    if (objDate < chartDates[0]) {
      return; // Ignore dates less than the first chart date
    }

    for (let i = 0; i < chartDates.length - 1; i++) {
      if (objDate >= chartDates[i] && objDate < chartDates[i + 1]) {
        indices.push({ obj, index: i });
        break;
      }
    }

    // Handle case where objDate is exactly equal to the last date in chartDates
    if (objDate.getTime() === chartDates[chartDates.length - 1].getTime()) {
      indices.push({ obj, index: chartDates.length - 1 });
    }
  });

  return indices;
}

export default React.memo(PracticeCardListItem);

function interpolateColor(rgb1, rgb2, percentage) {
  // Function to parse RGB string to array
  function parseRgb(rgb) {
    let result = rgb.match(/\d+/g);
    return result ? result.map(Number) : null;
  }

  // Function to format RGB array to string
  function formatRgb(rgbArray) {
    return `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
  }

  // Parse the input RGB strings
  let color1 = parseRgb(rgb1);
  let color2 = parseRgb(rgb2);

  if (!color1 || !color2) {
    throw new Error('Invalid RGB input');
  }

  // Interpolate between the two colors
  let r = Math.round(color1[0] + (color2[0] - color1[0]) * percentage);
  let g = Math.round(color1[1] + (color2[1] - color1[1]) * percentage);
  let b = Math.round(color1[2] + (color2[2] - color1[2]) * percentage);

  // Format the result as an RGB string
  return formatRgb([r, g, b]);
}
