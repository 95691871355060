import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useDispatch, useSelector } from 'react-redux';
import { videoActions } from '../../store/video';

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
timeZone = timeZone.replace('/', '%2F');

export default function MenuForPractice({
  practiceSectionsDisplayed,
  setPracticeSectionsDisplayed,
  // sendDataFromHook,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector((state) => state.auth.user);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          fontSize: '28px',
          color: 'inherit',
          height: '40px',
          width: '40px',
          minWidth: '40px',
        }}
      >
        ⋮
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setPracticeSectionsDisplayed('todaysCards');
          }}
          // disabled={practiceSectionsDisplayed === 'todaysCards'}
          style={{
            color: `${
              practiceSectionsDisplayed === 'todaysCards'
                ? 'var(--main-purple-no-transparancy)'
                : 'black'
            }`,
          }}
        >
          Todays Sections
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            setPracticeSectionsDisplayed('completedCards');
          }}
          // disabled={practiceSectionsDisplayed === 'completedCards'}
          style={{
            color: `${
              practiceSectionsDisplayed === 'completedCards'
                ? 'var(--main-purple-no-transparancy)'
                : 'black'
            }`,
          }}
        >
          Completed Sections
        </MenuItem>

        <MenuItem
          onClick={() => {
            console.log('🐸 user date: ', user);
            setPracticeSectionsDisplayed('tomorrowsCards');
            // sendDataFromHook();
            handleClose();
          }}
          // disabled={false}
          style={{
            color: `${
              practiceSectionsDisplayed === 'tomorrowsCards'
                ? 'var(--main-purple-no-transparancy)'
                : 'black'
            }`,
          }}
        >
          Tomorrow's Sections
        </MenuItem>
        <MenuItem onClick={handleClose} disabled={true}>
          Inactive Sections
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </>
  );
}
