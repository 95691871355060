import React, { useState } from 'react';
import classes from './VideoFormStyleNStudio.module.css';
import Button from '@mui/material/Button';

export default function VideoFormStyleNStudio({ videoId, openAnnotate }) {
  const [style, setStyle] = useState(null);
  const [studio, setStudio] = useState(null);
  const [subfolders, setSubfolders] = useState(null);

  const onSubmit = () => {
    updateStyles(videoId, style, studio, subfolders, openAnnotate);
  };

  return (
    <div className={classes['form-components']}>
      <div className={classes['label-and-input']}>
        <span>
          <label for="style">Style</label>
          <input
            type="text"
            id="style"
            name="style"
            value={style}
            onChange={(e) => {
              setStyle(e.target.value);
            }}
          />
        </span>
      </div>
      <div className={classes['label-and-input']}>
        <span>
          <label for="studio">Studio</label>
          <input
            type="text"
            id="studio"
            name="studio"
            value={studio}
            onChange={(e) => {
              setStudio(e.target.value);
            }}
          />
        </span>
      </div>
      <div className={classes['label-and-input']}>
        <span>
          <label for="subfolders">Subfolders</label>
          <input
            type="text"
            id="subfolders"
            name="subfolders"
            value={subfolders}
            onChange={(e) => {
              setSubfolders(e.target.value);
            }}
          />
        </span>
      </div>

      <Button
        sx={{ my: 2 }}
        style={{ alignSelf: 'center' }}
        variant="contained"
        // startIcon={<FileUploadIcon />}
        onClick={() => {
          onSubmit();
          //   hiddenFileInput.current.click();
        }}
      >
        Submit
      </Button>
    </div>
  );
}

async function updateStyles(videoId, style, studio, subfolders, openAnnotate) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/videos/update/${videoId}`,
      {
        method: 'PATCH',
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          style,
          folderLocation: [studio, ...subfolders.split(',')],
        }),
      }
    );

    console.log({ response });
    if (!response.ok) {
      console.log('not able to update video');
    } else {
      const data = await response.json();
      openAnnotate();
      //   console.log(data.data.user);
      //   dispatch(authActions.login(data.data.user));
    }
  } catch (err) {
    console.log(err);
  }
}
