import { move } from 'ionicons/icons';
import { useCallback, useEffect, useRef, useState } from 'react';

function preventBehavior(e) {
  e.preventDefault();
}

const useDragSelection = (
  ref,
  barIndex,
  callback,
  scrollContainerRef,

  { shouldPreventDefault = true, delay = 300 } = {}
) => {
  const [currentBarIndex, setCurrentBarIndex] = useState(barIndex);
  const startY = useRef(null);
  const diffy = useRef(null);
  const inMove = useRef(false);
  const initialDirection = useRef('down');
  const currentDirection = useRef(null);

  // console.log(
  //   'barIndex, startY, diffy,  inMove, initialDirection: ',
  //   barIndex,
  //   startY.current,
  //   diffy.current,
  //   inMove.current,
  //   initialDirection.current

  // );

  // useEffect(() => {
  //   function preventBehavior(e) {
  //     e.preventDefault();
  //   }

  //   if (barIndex < 5) {
  //     ref.addEventListener('touchmove', preventBehavior, {
  //       passive: false,
  //     });
  //   }
  // }, []);

  useEffect(() => {
    // console.log('original barIndex: ', barIndex);
  }, []);

  useEffect(() => {
    // console.log('bar index: ', barIndex);
    if (inMove.current) {
      callback({
        startingBarIndex: barIndex,
        currentBarIndex,
        initialDirection: initialDirection.current,
        currentDirection: currentDirection.current,
      });
    }
  }, [currentBarIndex]);

  const start = (e) => {
    // e.preventDefault();

    if (ref) {
      ref.addEventListener('touchmove', preventBehavior, {
        passive: false,
      });
    }
    // console.log('on start👁️');
    // console.log('start, currentBar inde: ', currentBarIndex);
    inMove.current = true;

    if (scrollContainerRef) {
      scrollContainerRef.style.overflow = 'hidden';
    }

    if (e.changedTouches) {
      startY.current = e.changedTouches[0].pageY;
    } else {
      startY.current = e.pageY;
    }
  };
  const move = (e) => {
    // e.preventDefault();
    if (e.changedTouches) {
      diffy.current = e.changedTouches[0].pageY - startY.current;
    } else {
      diffy.current = e.pageY - startY.current;
    }
    if (!initialDirection.current) {
      if (diffy.current > 5) {
        initialDirection.current = 'down';
      } else if (diffy.current < 5) {
        initialDirection.current = 'up';
      }
    }
    // console.log('diffy: ', diffy.current);
    if (diffy.current > 40 - 20) {
      startY.current += 40;
      currentDirection.current = 'down';
      setCurrentBarIndex((prev) => prev + 1);
    } else if (diffy.current < -(40 - 20)) {
      currentDirection.current = 'up';
      startY.current -= 40;

      setCurrentBarIndex((prev) => prev - 1);
    }
  };
  const end = (e) => {
    // console.log('on end');
    if (ref) {
      ref.removeEventListener('touchmove', preventBehavior, {
        passive: false,
      });
    }

    if (scrollContainerRef) {
      scrollContainerRef.style.overflow = 'auto';
    }
    startY.current = null;
    diffy.current = null;
    inMove.current = false;
    initialDirection.current = null;

    setCurrentBarIndex(barIndex);
  };

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseLeave: (e) => {
      if (e.button) {
        alert('fukcing succes');
      }
    },
    // onMouseLeave: (e) => clear(e, false),
    onTouchMove: (e) => move(e),
    onMouseMove: (e) => move(e),

    onTouchEnd: (e) => end(e),
    onMouseUp: (e) => end(e),
    // onClick: (e) => {
    //   e.stopPropagation();
    //   e.preventDefault();
    // },
  };
};

// const isTouchEvent = (event) => {
//   return 'touches' in event;
// };

// const preventDefault = (event) => {
//   if (!isTouchEvent(event)) return;

//   if (event.touches.length < 2 && event.preventDefault) {
//     event.preventDefault();
//   }
// };

export default useDragSelection;
