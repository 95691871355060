import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './VideoBoxAndThumb.module.css';
import { videoActions } from '../store/video';

export default function VideoInfoWrapper({ passKeyThrough, children }) {
  const dispatch = useDispatch();

  //   console.log('👨🏻‍💻  videoData', videoData);
  return (
    <div
      className={classes['main-container']}
      // style={{ overflow: 'visible' }}
      key={passKeyThrough}
      //   onClick={() => {
      //     onTreeVideoClickHandler(id, selection);
      //     dispatch(videoActions.updateRecentVideos(videoData));
      //     // dispatch(videoActions.addVideos(data.data));
      //   }}
    >
      {children}
    </div>
  );
}
