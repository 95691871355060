import classes from './Header.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { videoActions } from '../store/video';
import Grouped from './Grouped';
import isEqual from 'lodash/isEqual';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';

const SearchBar = ({ setQuery }) => {
  const dispatch = useDispatch();
  // const [inputArray, setInputArray] = useState([]);
  const [
    allVideosDEP,
    sharedWithMeVideos,
    myUploads,
    searchArray,
    autoCompleteValue,
    autoCompleteInput,
  ] = useSelector(
    (state) => [
      state.video.allVideos,
      state.video.sharedWithMeVideos,
      state.video.myUploads,
      state.video.searchArray,
      state.video.autoCompleteValue,
      state.video.autoCompleteInput,
    ],
    isEqual
  );
  const [allVideos, setAllVideos] = useState(() => [
    ...sharedWithMeVideos,
    ...myUploads,
    ...allVideosDEP,
  ]);

  useEffect(() => {
    setAllVideos([...sharedWithMeVideos, ...myUploads, ...allVideosDEP]);
  }, [sharedWithMeVideos, myUploads, allVideosDEP]);

  const singularAndPhrases = useRef({});

  useEffect(() => {
    const labelsAndCounts = {};
    const stylesAndCounts = {};
    const studiosAndCounts = {};
    const classNames = {};
    const studioObjs = {};

    if (allVideos.length > 0) {
      allVideos.forEach((vid) => {
        if (vid.style) {
          if (stylesAndCounts[vid.style]) {
            stylesAndCounts[vid.style]++;
          } else {
            stylesAndCounts[vid.style] = 1;
          }
        }
        if (vid.studio) {
          if (studiosAndCounts[vid.studio?.name.trim()]) {
            studiosAndCounts[vid.studio.name.trim()]++;
          } else {
            studiosAndCounts[vid.studio.name.trim()] = 1;
            studioObjs[vid.studio.name.trim()] = { ...vid.studio };
          }
        }
        // if (vid.folderLocation[0]) {
        //   if (studiosAndCounts[vid.folderLocation[0]?.trim()]) {
        //     studiosAndCounts[vid.folderLocation[0].trim()]++;
        //   } else {
        //     studiosAndCounts[vid.folderLocation[0].trim()] = 1;
        //   }
        // }
        if (vid.folderLocation) {
          for (let i = 1; i < vid.folderLocation.length - 1; i++) {
            if (classNames[vid.folderLocation[i]]) {
              classNames[vid.folderLocation[i]]++;
            } else {
              classNames[vid.folderLocation[i]] = 1;
            }
          }
        }

        vid.sections?.forEach((section) => {
          const label = section.label?.trim();
          if (label?.length > 0) {
            if (labelsAndCounts[label]) {
              labelsAndCounts[label]++;
            } else {
              labelsAndCounts[label] = 1;
            }
          }
        });
      });
    }
    console.log('🧮 label and counts: ', labelsAndCounts);
    const uniqueLabels = [
      ...new Set(Object.keys(labelsAndCounts).map((el) => el.toLowerCase())),
    ];
    const uniqueStudios = [...new Set(Object.keys(studiosAndCounts))];
    const uniqueStyles = [
      ...new Set(
        Object.keys(stylesAndCounts).map((el) => el.trim().toLowerCase())
      ),
    ];
    const uniqueClassNames = [
      ...new Set(Object.keys(classNames).map((el) => el.trim().toLowerCase())),
    ];

    function countSpaces(str) {
      return (str.match(/ /g) || []).length;
    }

    // Custom sort function
    // function sortBySpaces(arr) {
    //   return arr.sort((a, b) => {
    //     return countSpaces(a) - countSpaces(b);
    //   });
    console.log({ uniqueLabels });

    uniqueLabels.sort((a, b) => countSpaces(a) - countSpaces(b));
    // uniqueLabels.sort((a, b) => -b.localeCompare(a));
    console.log({ uniqueClassNames });
    dispatch(
      videoActions.setAutoCompleteInput({
        labels: uniqueLabels,
        styles: uniqueStyles.length > 1 ? uniqueStyles : [],
        studios: uniqueStudios.length > 1 ? uniqueStudios : [],
        classNames: uniqueClassNames.length > 1 ? uniqueClassNames : [],
      })
    );
    dispatch(videoActions.setAllStudios(studioObjs));
    // setInputArray({
    //   labels: uniqueLabels,
    //   styles: uniqueStyles.length > 1 ? uniqueStyles : [],
    //   studios: uniqueStudios.length > 1 ? uniqueStudios : [],
    // });
  }, [sharedWithMeVideos, myUploads, allVideos]);

  useEffect(() => {
    //in progress test
    // want to test out having key work and then prhasese
    const studiosAndCounts = {};
    // const stylesAndCounts
    const labelsAndCounts = {};
    if (allVideos.length > 0) {
      allVideos.forEach((vid) => {
        vid.sections?.forEach((section) => {
          // console.log('section: ', section.label);
          if (section.label) {
            const sectionSplit = section.label.split(' ');
            // console.log('sectionSplit: ', sectionSplit);
            sectionSplit.forEach((singleWord) => {
              if (singleWord?.length > 0) {
                if (singularAndPhrases.current[singleWord.toLowerCase()]) {
                  singularAndPhrases.current[singleWord.toLowerCase()].add(
                    section.label
                  );
                } else {
                  singularAndPhrases.current[singleWord] = new Set([
                    section.label,
                  ]);
                }
              }
            });
          }
          // const label = section.label?.trim();
        });
      });
    }
    console.log('🧮 singlular and phrases: ', singularAndPhrases.current);
  }, [allVideos]);

  useEffect(() => {
    async function getVideosByQuery() {
      const query = {};

      const terms = autoCompleteValue
        .filter((obj) => obj.group === 'Terms')
        .map((obj) => obj.label);

      dispatch(videoActions.updateSearchArray(terms));

      const styles = autoCompleteValue
        .filter((obj) => obj.group === 'Styles')
        .map((obj) => obj.label);

      const studios = autoCompleteValue
        .filter((obj) => obj.group === 'Studios')
        .map((obj) => obj.id);

      const classNames = autoCompleteValue
        .filter((obj) => obj.group === 'Class')
        .map((obj) => obj.label);

      if (terms?.length > 0) {
        console.log('🧉 terms: ', terms);
        query['sections.label'] = {
          $all: terms.map((search) => `/${search}/`),
        };
      }
      if (styles?.length > 0) {
        query['style'] = { $all: styles.map((search) => `/${search}/`) };
      }
      if (studios?.length > 0) {
        query['studio'] = { _id: studios[0] };
        // query['folderLocation.0'] = {
        //   $all: studios.map((search) => `/${search}/`),
        // };
      }

      if (classNames?.length > 0) {
        // alert('classname found');
        query['folderLocation'] = {
          $all: classNames.map((search) => `/${search}/`),
        };
      }

      console.log('👅 query: ', query);
      setQuery(query);
    }

    if (autoCompleteValue?.length > 0) {
      console.log('going to call the search!!');
      getVideosByQuery();
    } else {
      console.log('trigger the nullification of filteredvideos');
      // console.log('searcharray is : ', searchArray);
      setQuery({});

      dispatch(videoActions.setFilteredVideos(null));
    }
  }, [autoCompleteValue]);
  console.log('🔎 ', searchArray);

  return (
    <div className={classes['search-bar-container'] + ' search-bar-ya'}>
      <div className={classes['search-bar']}>
        <Grouped
          inputArray={autoCompleteInput}
          autoCompleteValue={autoCompleteValue}
        />
      </div>
      {/* <div style={{ color: 'var(--text-lighter-gray)', marginRight: '10px' }}>
        <TuneRoundedIcon fontSize="large" />
      </div> */}
    </div>
  );
};

export default SearchBar;
