import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './InputStyles.css';
import { useScroll } from 'framer-motion';
import Chip from '@mui/material/Chip';
import { useDispatch } from 'react-redux';
import { videoActions } from '../store/video';
import { hashColorFunc } from '../SearchPage';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

const placeholderText = 'Search styles, studios, and keywords';

// const StyledAutocomplete = styled(Autocomplete)`
//   .MuiAutocomplete-root {
//     /* width: 100%;
//     max-width: 100%; */

//     flex-grow: 1;
//     /* height: 100%; */
//     height: auto;
//     overflow: visible;
//     margin: 10px;
//     border-radius: var(--border-radius-search);
//     background-color: white;
//     box-shadow: 0 1px 6px 0 rgb(190, 190, 190);
//   }
// `;

export default function Grouped({
  inputArray,
  autoCompleteValue,
  //   setAutoCompleteValue,
}) {
  //   console.log('input array in grouped: ', inputArray);
  const dispatch = useDispatch();
  const [isMobile, allStudios] = useSelector((state) => [
    state.settings.isMobile,
    state.video.allStudios,
  ]);
  const [open, setOpen] = useState(false);

  // const styles = ['bachata', 'salsa', 'traditional', 'sensual'];

  // const studios = ['AOS', 'Nieves', 'Jeff & Mayra'];

  const options = [
    ...(inputArray?.styles || []).map((style) => {
      return { label: style, group: 'Styles' };
    }),
    ...(inputArray?.studios || []).map((studio) => {
      return {
        label: studio,
        group: 'Studios',
        id: allStudios[studio.trim()].id,
      };
    }),
    ...(inputArray?.labels || []).map((term) => {
      return { label: term, group: 'Terms' };
    }),
    ...(inputArray?.classNames || []).map((classNames) => {
      return { label: classNames, group: 'Class' };
    }),
  ];

  const [placeholder, setPlaceHolder] = React.useState(placeholderText);

  const resultHandler = (e, value) => {
    // if (value.length > 0) {
    //   setPlaceHolder('');
    // } else {
    //   setPlaceHolder(placeholderText);
    // }
    dispatch(videoActions.updateAutoCompleteValue(value));

    console.log({ value });

    // console.log({ styles });
    // setIsOpen(false);
  };

  React.useEffect(() => {
    if (autoCompleteValue.length > 0) {
      setPlaceHolder('');
    } else {
      setPlaceHolder(placeholderText);
    }
  }, [autoCompleteValue]);

  return (
    <div>
      <Autocomplete
        //   style={{ float: 'right' }}
        limitTags={2}
        id="search-bar-terms"
        options={options}
        value={autoCompleteValue}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        // open
        //   options={options.sort((a, b) => -b.group.localeCompare(a.group))}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.label}
        sx={{
          backgroundColor: 'white',
          flexGrow: 1,
          height: 'auto',
          overflow: 'visible',
          margin: '10px',
          borderRadius: 'var(--border-radius-search)',
          boxShadow: '0 1px 6px 0 rgb(210, 210, 210)',
          // boxShadow:
          //   '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
          borderBottomLeftRadius: open ? 0 : 'var(--border-radius-search)',
          borderBottomRightRadius: open ? 0 : 'var(--border-radius-search)',

          '& .Mui-expanded': {},
        }}
        componentsProps={{
          paper: {
            sx: {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 'var(--border-radius-search)',
              borderBottomRightRadius: 'var(--border-radius-search)',
              boxShadow: '0 1px 6px 0 rgb(210, 210, 210)',
              clipPath: 'inset(0px -10px -10px -10px)',
              borderTop: '1px solid rgb(225,225,225)',
            },
          },
        }}
        renderInput={(params) => (
          <TextField {...params} label="" placeholder={placeholder} />
        )}
        multiple={true}
        onChange={resultHandler}
        ListboxProps={{
          style: { maxHeight: `${isMobile ? 'calc(100vh - 500px)' : '15rem'}` },
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.label}
              label={option.label}
              sx={{
                '& .MuiChip-deleteIcon': {
                  color: '#FFFFFF99',
                },
              }}
              {...getTagProps({ index })}
              // style={{ backgroundColor: 'your-desired-color', color: 'white' }}
              // color={`${
              //   option.group === 'Styles'
              //     ? 'primary'
              //     : option.group === 'Studios'
              //     ? 'secondary'
              //     : 'default'
              // }`}
              style={{
                backgroundColor: hashColorFunc(option.label),
                color: 'white',
              }}
            />
          ))
        }
        //   open={isOpen} // Set the initial open state
        //   onOpen={() => setIsOpen(true)} // Ensure it stays open initially
        //   onClose={() => setIsOpen(false)} // Close handler to set isOpen to false after initial selection
        //   open={false}
      />
    </div>
  );
}
