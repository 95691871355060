import React, { useState } from 'react';
import {
  makeStyles,
  Slider,
  withStyles,
  Button,
  Tooltip,
  Popover,
  Grid,
} from '@material-ui/core';
import './SlowmoButton.css';
import TrackingBall from '../TrackingBall';
import { motion } from 'framer-motion';

const PrettoSlider = withStyles({
  root: {
    height: 20,
    '&$vertical': {
      width: 8,
    },
    color: '#9556CC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#9556CC',
    border: '2px solid currentColor',
    marginTop: -1,
    marginLeft: -9,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 4,
    width: 60,
  },
  rail: {
    height: 10,
    // width: 5,
    borderRadius: 4,
  },
  vertical: {
    '& $rail': {
      width: 8,
    },
    '& $track': {
      width: 8,
    },
    // '& $thumb': {
    //   marginLeft: -10,
    //   marginBottom: 0,
    // },
  },
})(Slider);

export default function SlowmoSelect({
  onSetPlayback,
  playbackRate,
  variableSpeedComponent,
  isVariableSlowMo,
  handleClearCoords,
  setShowSlowmoSelect,
}) {
  const [showVariableSpeed, setShowVariableSpeed] = useState(isVariableSlowMo);
  //   const [playback, setPlayback] = useState(1);
  //   const [showSlider, setShowSlider] = useState(false);

  // console.log('olaybak', playbackRate);
  return (
    <>
      <div
        onClick={() => {
          // alert('clcoled');
          setShowSlowmoSelect(false);
        }}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          // zIndex: -1,
          height: '100vh',
          width: '100vw',
          zIndex: 554,
          // height: '100px',
          // backgroundColor: 'red',
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          // zIndex: -1,
          width: '100%',
          // width: '100vw',
          zIndex: 555,
          // height: '100px',
          backgroundColor: 'var(--background-color)',
          // textAlign: 'center',
          // verticalAlign: 'middle',
          // background: 'blue',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: 'white',
            paddingLeft: '5px',
            paddingRight: '5px',
            // margin: '10px',
            width: '100%',
            maxWidth: '400px',
            margin: 'auto',
          }}
        >
          <div style={{ fontSize: '18px', color: 'white' }}>Playback Rate</div>
          <div
            onClick={() => {
              setShowVariableSpeed((prev) => {
                if (prev === true) {
                  // onSetPlayback(null, 100);
                  handleClearCoords();
                }
                return !prev;
              });
            }}
            style={{ textDecoration: 'underline', color: 'white' }}
          >
            {showVariableSpeed ? '(standard)' : '(variable)'}
          </div>
        </div>
        {!showVariableSpeed && (
          <div
            style={{
              // height: '40px',
              // width: '100vw',
              // position: 'absolute',
              // bottom: 0,
              // left: 0,
              padding: '10px 15px',

              // transform: 'translateY(-50%)',
              //   pointerEvents: 'none',
              //   zIndex: '-1',
              // transform: 'translateX(-50%)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                color: 'white',
              }}
            >
              <div>0.2</div>
              <div
                style={{
                  fontSize: '22px',
                  fontWeight: 'bold',
                  // position: 'relative',
                  // right: '6%',
                }}
              >
                {playbackRate}
              </div>
              <div>1.8</div>
            </div>
            <PrettoSlider
              // orientation="vertical"
              marks
              step={10.0}
              min={20}
              max={180}
              //   zindex={3}
              //   onChange={(e, value) => {
              //     console.log(value);
              //   }}
              onChange={onSetPlayback}
              // onTouchEnd={() => {
              //   // setShowSlider(false);
              //   setShowButton(true);
              // }}
              value={playbackRate * 100}
              //   style={{ display: 'inline-block' }}
              //   value={played * 100}
              //   onChange={onSeek}
              //   onChangeCommitted={onSeekMouseUp}
              //   onMouseDown={onMouseSeekDown}
            />
          </div>
        )}

        {showVariableSpeed && variableSpeedComponent}
      </div>
    </>
  );
}

// https://icons.veryicon.com/png/o/miscellaneous/offerino-icons/list-bullet.png
