import classes from './Auth.module.css';
import useInput from '../hooks/use-input';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { authActions } from '../store/auth';
import { useParams, Link, useNavigate } from 'react-router-dom';
import AuthModal from './AuthModal';
import { useState } from 'react';
import useFetchOnDemand from '../hooks/useFetchOnDemand';
import TextField from '@mui/material/TextField';

const ResetPassword = ({}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { resetToken, name } = params;
  // alert(resetToken);

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const dispatch = useDispatch();

  let formIsValid = false;
  if (password.length >= 6 && password === passwordConfirm) {
    formIsValid = true;
  }

  const navigateHandler = () => {
    navigate('/');
  };

  const {
    loading: sending,
    error: error,
    sendData: authenticate,
  } = useFetchOnDemand(
    `${process.env.REACT_APP_BACKEND}/api/v1/users/resetPassword/${resetToken}`,
    (data) => {
      dispatch(authActions.login(data.data.user));
      navigateHandler();
    },
    {
      method: 'PATCH',
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
        passwordConfirm,
      }),
    },
    [password, passwordConfirm]
  );

  const loginHandler = (event) => {
    event.preventDefault();

    if (formIsValid) {
      authenticate();
    }
  };

  const textFieldStyle = { width: '100%', borderRadius: '8px' };

  return (
    // <div className={classes['background']}>
    <AuthModal>
      <main className={classes.auth}>
        <section>
          <form className={classes['auth-form']} onSubmit={loginHandler}>
            <div>
              <h1 className={classes.greeting}>{`Welcome ${name}!`}</h1>
              <p className={classes.instructions}>
                Please create a password to access your account
              </p>
            </div>
            <div className={classes['form-elements']}>
              <TextField
                value={password}
                onChange={(e) => {
                  // console.log('set email');
                  setPassword(e.target.value);
                }}
                id="standard-basic"
                label="Create Password"
                type="password"
                variant="outlined"
                style={{ ...textFieldStyle, marginBottom: '10px' }}
                // InputLabelProps={{ shrink: true }}
              />{' '}
              <TextField
                value={passwordConfirm}
                onChange={(e) => {
                  // console.log('set email');
                  setPasswordConfirm(e.target.value);
                }}
                id="standard-basic"
                label="Password Confirm"
                type="password"
                variant="outlined"
                style={textFieldStyle}
                // InputLabelProps={{ shrink: true }}
              />
            </div>
            <button className={classes['login-button']}>Login</button>
          </form>
        </section>
      </main>
    </AuthModal>
    // </div>
  );
};

export default ResetPassword;
