import React from 'react';
import './LabelContext.css';

export default function LabelContext({
  sections,
  index,
  indexSpan,
  isLast,
  uniqueKeyCount,
  searchArr = ['infinity', 'shadow'],
}) {
  const numOfSections = indexSpan === 1 ? 4 : indexSpan + 2;
  return (
    <div
      style={{
        minHeight: '40px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: isLast ? 'none' : '1px solid rgb(200,200,200)',
      }}
    >
      {' '}
      <div
        className="flow-chart-container"
        style={{
          // backgroundColor:
          //   uniqueKeyCount === searchArr.length ? '#fffcc4' : 'none',
          // fontWeight: uniqueKeyCount === searchArr.length ? 'bold' : 'none',
          fontStyle:
            uniqueKeyCount === searchArr.length && uniqueKeyCount > 1
              ? 'italic'
              : 'none',
        }}
      >
        {/* {searchArr.length > 1 && uniqueKeyCount === searchArr.length && (
    <span style={{ color: 'purple' }}>★</span>
  )} */}
        {new Array(numOfSections).fill(1).map((el, ind) => {
          const label = sections[index + ind - 1]?.label;
          // const searchTerm = searchArr[0];
          // const keywordIndex = label?.match(new RegExp(searchTerm))?.index;

          function findKeywords(keywordArr, label) {
            const keyWordIndiceArr = [];
            const colorArr = ['blue', 'red', 'green', 'purple'];
            keywordArr.forEach((keyword, i) => {
              const keywordIndex = label?.match(
                new RegExp(keyword, 'i')
              )?.index;
              if (keywordIndex >= 0) {
                keyWordIndiceArr.push({
                  keyword,
                  start: keywordIndex,
                  end: keywordIndex + keyword.length,
                  color: colorArr[i],
                });
              }
            });
            keyWordIndiceArr.sort((a, b) => a - b);
            return keyWordIndiceArr;
          }
          const stopStartArray = findKeywords(searchArr, label);
          // console.log('stopStartArray: ', stopStartArray);

          if (stopStartArray.length > 0) {
            return (
              <>
                <div className="label-holder-oval">
                  <span>{label.slice(0, stopStartArray[0].start)} </span>
                  {stopStartArray.map((el, i) => {
                    return (
                      <span>
                        <span
                          style={{
                            color: el.color,
                          }}
                        >
                          {/* {el.keyword} */}
                          {label.slice(el.start, el.end)}
                        </span>
                        <span>
                          {label.slice(el.end, stopStartArray[i + 1]?.start)}
                        </span>
                      </span>
                    );
                  })}
                </div>
                <span>{` → `}</span>
              </>
            );
            //   return (
            //     <>
            //       {/* <span>{keywordIndex}</span> */}
            //       <span> </span>
            //       <span>{label.slice(0, keywordIndex)}</span>
            //       <span
            //         style={{
            //           // backgroundColor: 'red',
            //           color: 'red',
            //           textDecoration: 'underline',
            //         }}
            //       >
            //         {`${label.slice(
            //           keywordIndex,
            //           keywordIndex + searchTerm.length
            //         )}`}
            //       </span>
            //       <span>{label.slice(keywordIndex + searchTerm.length)}</span>
            //       <span>{` → `}</span>
            //     </>
            //   );
          } else {
            return (
              label && (
                <>
                  {' '}
                  <div className="label-holder-oval">{` ${label}`}</div>
                  {ind !== numOfSections - 1 && <span>{' → '}</span>}
                </>
              )
            );
          }
        })}
      </div>{' '}
    </div>
  );
}
