import React, { useEffect, useRef, useState } from 'react';
import './video_components/Control.css';
let count = 0;

export default function FadeInOutWrapper({ children, sectionListRef }) {
  console.log('fade in out wrapper RERENDER: ', count++);
  const divSizes = useRef({});
  const [scrollFade, setScrollFade] = useState({ top: false, bottom: false });

  useEffect(() => {
    if (sectionListRef?.current) {
      console.log('change in client height');
      const clientHeight = sectionListRef?.current.clientHeight;
      document.documentElement.style.setProperty(
        `--sections-box`,
        `${clientHeight - 40}px`
      );
      const clientHeightInner = document.querySelector(
        '.full-length-sectionlist'
      ).clientHeight;
      // document.documentElement.style.setProperty(
      //   `--sections-box-long`,
      //   `${clientHeightInner}px`
      // );
      divSizes.current = {
        ...divSizes.current,
        clientHeight,
        clientHeightInner,
      };
      const topAndBottPadding = 23;
      const percentDiff = (100 * topAndBottPadding) / clientHeight;
      document.documentElement.style.setProperty(
        `--percent-diff`,
        `${percentDiff}%`
      );
    }
  }, [sectionListRef.current?.clientHeight]);

  useEffect(() => {
    const scrollFunc = () => {
      if (sectionListRef.current.scrollTop > 2 && setScrollFade) {
        if (scrollFade.top !== true) {
          console.log('trigger 1');
          setScrollFade((prev) => {
            return { ...prev, top: true };
          });
        }

        if (
          divSizes.current.clientHeightInner <
          divSizes.current.clientHeight + sectionListRef.current.scrollTop - 65
        ) {
          if (scrollFade.bottom !== true) {
            console.log('trigger 2');
            setScrollFade((prev) => {
              return { ...prev, bottom: true };
              //     console.log('FUCK wee made it tot the fukcing end');
              //   });
            });
          }
          // console.log('FUCK scrolltop: ', sectionListRef.current.scrollTop);
        } else {
          if (scrollFade.bottom !== false) {
            console.log('trigger 3');
            setScrollFade((prev) => {
              return { ...prev, bottom: false };
            });
          }
        }
      } else {
        if (scrollFade.top !== false) {
          console.log('trigger 4');
          setScrollFade((prev) => {
            return { ...prev, top: false };
          });
        }
        // setScrollFade(false);
        // console.log('remove class');
        // sectionListRef.current.classList.remove('faded-top');
      }
    };

    if (sectionListRef.current) {
      console.log('📜 scrooll height: ', sectionListRef.current.scrollTop);
      sectionListRef.current.addEventListener('scroll', scrollFunc);
      // if(sectionListRef.current.clientHeight- sectionListRef.current.scrollTop)
      // console.log('add class');
      // sectionListRef.current.classList.remove('faded-top');
      // sectionListRef.current.classList.add('faded-top');
    }
    return () => {
      sectionListRef?.current?.removeEventListener('scroll', scrollFunc);
    };
  }, [scrollFade]);

  useEffect(() => {
    if (scrollFade.top) {
      document.documentElement.style.setProperty(`--fade-top`, `25px`);
    } else {
      // clearInterval(topFadInterval);
      document.documentElement.style.setProperty(`--fade-top`, `0px`);
    }
  }, [scrollFade.top]);

  useEffect(() => {
    if (scrollFade.bottom) {
      document.documentElement.style.setProperty(`--fade-bottom`, `25px`);
    } else {
      // clearInterval(topFadInterval);
      document.documentElement.style.setProperty(`--fade-bottom`, `0px`);
    }
  }, [scrollFade.bottom]);

  return <>{children}</>;
}
