import React from 'react';
import {
  makeStyles,
  Slider,
  withStyles,
  Button,
  Tooltip,
  Popover,
  Grid,
} from '@material-ui/core';

const marks = [
  {
    value: -1.0,
    label: '-1.0s',
  },
  {
    value: -0.5,
    label: '-0.5s',
  },
  {
    value: 0,
    label: '0s',
  },
  {
    value: 0.5,
    label: '+0.5s',
  },
  {
    value: 1.0,
    label: '+1.0s',
  },
];

const PrettoSlider = withStyles({
  root: {
    height: '20px',
    color: 'white', //'#9556CC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2px',
    // gridArea: { gridArea },
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: 'white', //#9556CC',
    border: '2px solid currentColor',
    marginTop: -3,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  markLabel: {
    color: '#AAA',
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
    borderRadius: 4,
    width: '100%',
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

export default function ABSlider({
  onChange,
  onChangeCommitted,
  onMouseDown,
  // gridArea,
}) {
  return (
    <PrettoSlider
      min={-1.5}
      max={1.5}
      step={0.1}
      marks={marks}
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      onMouseDown={onMouseDown}
    />
  );
}
