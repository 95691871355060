import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { tourActions } from '../../store/tour';
import { motion } from 'framer-motion';
// import CloseIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import './Popup.css';
import { AnimatePresence } from 'framer-motion';

const emoticonArray = ['🎉', '🎊', '🥳', '👏', '🏆', '✨', '💫', '🌟'];
const Container = styled.div`
  position: relative;
  //   top: 10px;
  //   left: 10px;
  width: 380px;
  max-width: 100%;
  padding: 15px;
  padding-top: 35px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
`;

const Title = styled.h2`
  margin: 0 0 10px 0;
`;

const Text = styled.p`
  margin: 0 0 0px 0;
  text-align: center;
  min-height: 50px;
`;

const Button = styled.button`
  padding: 8px 16px;
  //   margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ForwardButton = styled(Button)`
  background-color: ${({ allowContinue }) =>
    allowContinue ? '#007bff' : '#007bff44'};

  color: white;
  // position: absolute;
  // bottom: 10px;
  // right: 10px;
`;

const BackButton = styled(Button)`
  background-color: #f0f0f0;
  //   position: absolute;
  //   bottom: 10px;
  //   left: 10px;
`;

const ExitButton = styled(Button)`
  color: rgb(210, 210, 210);
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  margin: 5px;
  //   align-self: flex-end;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  //   margin-top: 10px;
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: ${(props) => (props.active ? '#007bff' : '#ccc')};
  border-radius: 50%;
  display: inline-block;
`;

const Popup = ({ onClose }) => {
  const dispatch = useDispatch();

  const [
    currentStep,
    currentTour,
    allowContinue,
    welcomeSlide,
    showWelcome,
    highlightCenter,
  ] = useSelector((state) => [
    state.tour.currentStep,
    state.tour.currentTour,
    state.tour.allowContinue,
    state.tour.currentTour?.welcomeSlide,
    state.tour.showWelcome,
    state.tour.highlightCenter,
  ]);
  const isLast = currentStep === currentTour.steps.length - 1;

  const [isStarted, setIsStarted] = useState(() => !showWelcome);

  const { title, text, toComplete, justifyContent } =
    currentTour?.steps?.at(currentStep);

  //   const [allowContinue, setAllowContinue] = useState(()=>{justifyContent ? true : false});
  //   const [completed, setCompleted] = toComplete ? false : true;

  //   console.log({ completed });
  let secondaryText;
  const totalSteps = currentTour?.steps?.length;
  //   if (toComplete && allowContinue) {
  if (toComplete) {
    secondaryText = toComplete.text;
  }

  useEffect(() => {
    if (isStarted) {
      dispatch(tourActions.goTo(0));
    }
  }, [isStarted]);

  const closeHandler = () => {
    onClose();
  };

  useEffect(() => {
    return () => {
      dispatch(tourActions.close());
      fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/users/updateToursViewed`,

        {
          method: 'PATCH',
          credentials: 'include', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tourViewed: 'usingAnnotatedList',
          }),
        }
      );
    };
  }, []);

  useEffect(() => {
    if ((!text && allowContinue) || (!secondaryText && allowContinue)) {
      console.log('skip to next after complete');
      dispatch(tourActions.next());
    }
  }, [text, allowContinue]);

  if (!isStarted) {
    return (
      <div className="flex-container" style={{ justifyContent: 'center' }}>
        {/* <AnimatePresence> */}
        <motion.div
          style={{ zIndex: 1000 }}
          initial={{ scale: 0.4, opacity: 0 }}
          animate={{ scale: [0, 1.2, 1], opacity: 1 }}
          // exit={{ scale: 0.4, opacity: 0 }}

          transition={{ duration: 0.5 }}
        >
          <Container style={{ top: '-15%', width: '300px' }}>
            <ExitButton onClick={onClose}>
              <CloseIcon fontSize="small" />
            </ExitButton>
            <Title>{welcomeSlide?.title}</Title>
            <Text>{welcomeSlide?.text}</Text>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '20px',
              }}
            >
              <BackButton onClick={onClose}>No</BackButton>
              <ForwardButton
                onClick={() => setIsStarted(true)}
                disabled={false}
                allowContinue={true}
                // isStarted={isStarted}
              >
                Yes!
              </ForwardButton>
            </div>
          </Container>
        </motion.div>
        {/* </AnimatePresence> */}
      </div>
    );
  }

  return (
    <motion.div
      className="flex-container"
      initial={{ opacity: 1 }}
      // animate={{ x: 100, y: 100 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      style={{
        justifyContent,
        // backgroundImage: `radial-gradient(
        //     circle at ${highlightCenter?.at(0) || 0}px ${
        //   highlightCenter?.at(1) || 0
        // }px ,
        //     transparent 0%,
        //     transparent 5%,
        //     #000000AA 50%,
        //     #000000AA 100%
        //   )`,
        // transition: ' background-image 0.8s',
      }}
    >
      <motion.div
        className="test-radial-movement"
        // transformTemplate={template}
        // initial={{ x: '50%', y: '50%' }}
        initial={{ x: highlightCenter?.at(0), y: highlightCenter?.at(1) }}
        // animate={{ x: 100, y: 100 }}
        animate={{
          x: highlightCenter?.at(0),
          y: highlightCenter?.at(1),
        }}
        // exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          // left: `${highlightCenter?.at(0)}px`,
          // left: '-100%',
          // top: '-100%',
          left: '-120%',
          top: '-120%',
          transform: 'translate(-100%, -100%)',
          // top: `${highlightCenter?.at(1)}px`,
        }}
      ></motion.div>
      {text && (
        <Container>
          <ExitButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </ExitButton>
          {/* <Title>{title}</Title> */}

          <div className="flip-card">
            <div
              className={`flip-card-inner ${allowContinue ? 'fliiip' : ''}`}
              style={{
                transition: allowContinue ? 'transform 0.8s' : '',
              }}
            >
              <div className="flip-card-front">{text}</div>
              <div className="flip-card-back">
                <div className="emoticon-div">
                  {secondaryText &&
                    emoticonArray[secondaryText.length % emoticonArray.length]}
                </div>
                <div>{secondaryText}</div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <BackButton onClick={() => dispatch(tourActions.prev())}>
              Back
            </BackButton>
            <Pagination>
              {Array.from({ length: totalSteps }).map((_, index) => (
                <Dot
                  key={index}
                  active={index <= currentStep}
                  onClick={() => {
                    dispatch(tourActions.goTo(index));
                  }}
                />
              ))}
            </Pagination>
            {isLast ? (
              <ForwardButton
                onClick={onClose}
                disabled={!allowContinue}
                allowContinue={allowContinue}
              >
                Finish
              </ForwardButton>
            ) : (
              <ForwardButton
                onClick={() => dispatch(tourActions.next())}
                disabled={!allowContinue}
                allowContinue={allowContinue}
              >
                Continue
              </ForwardButton>
            )}
          </div>
        </Container>
      )}
    </motion.div>
  );
};

function template({ left, top }) {
  return `translateX(${left}) translateY(${top}`;
}

function getRandomElement(array) {
  if (array.length === 0) return null; // Handle empty array case
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export default Popup;
