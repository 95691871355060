import './VideoContainer.css';
import ReactPlayer from 'react-player';
import Control from './Control';
import { useState, useRef, useEffect, useCallback } from 'react';
import { formatTime } from './format';
import { motion, AnimatePresence, m } from 'framer-motion';
import determineClickCountFactory from '../determineClickCount';
import TrackingBall from '../TrackingBall';
import { useDispatch, useSelector } from 'react-redux';
import { videoActions } from '../../store/video';
import { friendActions } from '../../store/friend';
import CircleLoader from '../Loaders/CircleLoader';
import { useColor } from 'color-thief-react';
import { useTour } from '@reactour/tour';

import useImageColor from 'use-image-color';
import Hls from 'hls.js';
import { tourActions } from '../../store/tour';
import Popup from './Popup';

import ReactDOM from 'react-dom';

let count = 0;
const progressInterval = 1000;

function VideoPreview({
  videoData,
  addSectionHandler,
  userId,
  onModalClose,
  initialLooptime,
  // setSearchArray,
}) {
  const [isMobile] = useSelector((state) => [
    state.settings.isMobile,
    // state.auth.user.friendTag,
  ]);

  const outerContainerRef = useRef(null);

  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const firstLoad = useRef(true);

  const countForBackdrop = useRef(null);
  const [annotations, setAnnotations] = useState(null);

  const slowmoCoords = useRef([]);

  // const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSections, currentBar] = useSelector((state) => [
    // state.video.looptime,
    state.video.selectedSections,
    state.video.currentBar,
    // state.auth.user._id,
  ]);

  const looptime = [0, 5];

  // console.log('show me selectedSections?.length: ', selectedSections?.length);
  const HLSConfig = {
    forceKeyFrameOnDiscontinuity: false,
    maxBufferLength: 2, // for example, set to 30 seconds
    maxMaxBufferLength: 2.5, // hard upper limit for maxBufferLength. This does not seem to update in the reactPlayer even when HLSConfig changes
    // Set a maximum buffer size to control the amount of data preloaded.
    maxBufferSize: 6 * 1024 * 1024, // for example, set to 10MB
  };

  // console.log('show me HLSConfig: ', HLSConfig);
  const onReady = useCallback(() => {
    // if (firstLoad.current) {
    //   firstLoad.current = false;
    //   videoPlayerRef.current.seekTo(
    //     Math.max(looptime[0] - delayFix, 0),
    //     'seconds'
    //   );
    // }
  }, [videoPlayerRef.current]);

  const { colors } = useImageColor(videoData.thumbnail, {
    cors: true,
    colors: 5,
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      `--dominant-colors-1`,
      videoData?.dominantColors?.at(0) || `#000000`
    );
    document.documentElement.style.setProperty(
      `--dominant-colors-2`,
      videoData?.dominantColors?.at(1) || `#000000`
    );
    document.documentElement.style.setProperty(
      `--dominant-colors-3`,
      videoData?.dominantColors?.at(2) || `#000000`
    );
    document.documentElement.style.setProperty(
      `--dominant-colors-4`,
      videoData?.dominantColors?.at(3) || `#000000`
    );
    document.documentElement.style.setProperty(
      `--dominant-colors-5`,
      videoData?.dominantColors?.at(4) || `#000000`
    );
    console.log('fucking color for real? ', colors);
    if (colors) {
      document.documentElement.style.setProperty(
        `--dominant-colors-1`,
        colors[0]
      );
      document.documentElement.style.setProperty(
        `--dominant-colors-2`,
        colors[1]
      );
      document.documentElement.style.setProperty(
        `--dominant-colors-3`,
        colors[2]
      );
      document.documentElement.style.setProperty(
        `--dominant-colors-4`,
        colors[3]
      );
    }
  }, [colors]);

  const retrieveSections = async () => {
    const responseSections = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/sections/user/${userId}/video/${videoData._id}`,
      {
        credentials: 'include',
      }
    );
    const dataSection = await responseSections.json();
    // console.log('😊 dataSection');
    // console.log(dataSection.data);
    setAnnotations(dataSection.data);

    const justViewed = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/users/justViewed/${videoData._id}`,
      {
        credentials: 'include', // include, *same-origin, omit
      }
    );
    const viewResponse = await justViewed.json();

    const userVideoData = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/userVideoData`,
      {
        method: 'POST',

        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: userId,
          video: videoData.id,
          sectionsLength: videoData.sections.length,
          // password: enteredPassword,
        }),
      }
    );
  };

  const [videoState, setVideoState] = useState({
    playing: true,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });

  //Destructuring the properties from the videoState
  const { playing, muted, volume, playbackRate, played, seeking, buffer } =
    videoState;

  const timePadding = 0; //0.15;

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const progressHandler = (state) => {
    console.log('🦹🏽‍♀️ on progress');
    console.log(videoState);
    countForBackdrop.current++;

    const currentTime = videoPlayerRef.current.getCurrentTime();
    let barPointer = currentBar;

    // while (sectionTimeArray.current[barPointer] > currentTime) {
    //   barPointer--;
    // }
    // while (sectionTimeArray.current[barPointer + 1] < currentTime) {
    //   barPointer++;
    // }

    if (!seeking) {
      if (
        (currentTime != 0 && currentTime > looptime[1]) ||
        currentTime < looptime[0]
      ) {
        // dispatch(videoActions.updateCurrentBar(barPointer));

        // console.log('in if loop');
        videoPlayerRef.current.seekTo(looptime[0]);
      }

      // console.log('slowmoCoords.current.length: ', slowmoCoords.current.length);
      // console.log('percentageComplete: ', percentageComplete);

      setVideoState({ ...videoState, ...state });
    }
  };

  const handleSetPlayback = (e, value) => {
    // playbackRate(parseFloat(value / 100).toFixed(1));
    // slowmoCoords.current = new Array();
    setVideoState({
      ...videoState,
      playbackRate: parseFloat(value / 100).toFixed(1),
    });
  };

  const bufferStartHandler = () => {
    console.log('Bufering.......');
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    console.log('buffering stoped ,,,,,,play');
    setVideoState({ ...videoState, buffer: false });
  };

  return (
    <>
      <div
        className={`player__wrapper_preview`}
        // onMouseMove={mouseMoveHandler}
        // style={{ transform: [{ scaleX: -1 }] }}
      >
        {/* <div className="black-cover" /> */}
        <ReactPlayer
          ref={videoPlayerRef}
          className="player-preview"
          // url={`${videoData.url}/filename.m3u8`}
          url={`${videoData.url}/hls2m.m3u8`}
          loop
          config={{
            // hlsOptions: hlsConfig,
            // file: { forceVideo: true },
            file: {
              forceHLS: true,
              hlsOptions: HLSConfig,
              hlsVersion: Hls.version,
              hlsjs: Hls,
            },
          }}
          width="100%"
          height="100%"
          playing={playing}
          volume={volume}
          muted={true}
          onProgress={progressHandler}
          onBuffer={bufferStartHandler}
          onBufferEnd={bufferEndHandler}
          progressInterval={progressInterval}
          playbackRate={playbackRate}
          onReady={onReady}
          // onStart={onStart}
          // onPlay={onPlay}
        />
        {/* </div> */}
      </div>
    </>
  );
}

export default VideoPreview;
