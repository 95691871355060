import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import './InputStyles.css';
import { useScroll } from 'framer-motion';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { videoActions } from '../store/video';

export default function AutoCompleteNamesEmails({
  value,
  setValue,
  defaultLabel = '',
}) {
  React.useEffect(() => {
    // setLabel(defaultLabel);
  }, []);
  const [inputValue, setInputValue] = React.useState('');
  const namesAndEmails = useSelector((state) => state.video.namesAndEmails);

  console.log({ namesAndEmails });
  const options = namesAndEmails
    ? [...namesAndEmails].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  // const resultHandler = (e, value) => {
  //   // console.log('result input rh: ', inputValue);
  //   // console.log('result value rh: ', value);
  //   setLabel(value);
  // };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.name,
  });

  return (
    <Autocomplete
      freeSolo
      id="find-user-email"
      options={options}
      // inputValue={label}
      value={value}
      onChange={(e, val) => {
        setValue(val);
      }}
      filterOptions={filterOptions}
      multiple
      // inputValue={inputValue}
      // onInputChange={(event, newInputValue) => {
      //   console.log('result input iv: ', inputValue);
      //   console.log('result input: ', newInputValue);
      //   console.log('result value: ', label);
      //   setInputValue(newInputValue);
      //   setLabel(null);
      // }}
      //   options={options.sort((a, b) => -b.group.localeCompare(a.group))}
      // groupBy={(option) => option.group}
      getOptionLabel={(option) => option.email}
      renderOption={(props, option) => (
        <li {...props} key={option.email}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ width: '90px', textAlign: 'left' }}>
              {option.name}
            </div>
            <div>{option.email}</div>
          </div>
        </li>
      )}
      // renderOption={(props, option) => (
      //   <div
      //     style={{
      //       display: 'flex',
      //       flexDirection: 'row',
      //       justifyContent: 'flex-start',
      //     }}
      //   >
      //     <div style={{ width: '90px', textAlign: 'left' }}>{option.name}</div>
      //     <div>{option.email}</div>
      //   </div>
      // )}
      sx={{}}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Users"
          placeholder={defaultLabel}
        />
      )}
      // multiple={true}
      // onInputChange={resultHandler}
    />
  );
}
