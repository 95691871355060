import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import video from '../../store/video';
import BadgeList from '../BadgeList';
import { timeAgo } from '../VideoBoxAndThumb';
import VideoClickWrapper from '../VideoClickWrapper';
import LabelContext from './LabelContext';
import './SearchResult.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// const search = 'infinity';

export default React.memo(function SearchResult({
  videoData,
  // searchArray,
  onTreeVideoClickHandler,
  checkedVideos,
  setCheckedVideos,
  isChecked,
}) {
  const [searchArray, userRole] = useSelector((state) => [
    state.video.searchArray,
    state.auth.user?.role,
  ]);
  const [showAll, setShowAll] = useState(false);
  const limitShowCount = 4;
  // const
  // const [isChecked, setIsChecked] = useState(() => {
  //   return checkedVideos.includes(videoData.id);
  // });
  const handleChange = (event) => {
    // setIsChecked(event.target.checked);
    if (event.target.checked) {
      setCheckedVideos((prev) => [
        ...prev,
        { id: videoData.id, thumbnail: videoData.thumbnail },
      ]);
    } else {
      setCheckedVideos((prev) => prev.filter((el) => el.id !== videoData.id));
    }
  };
  // const checkedCheckboxes = document.querySelectorAll(
  //   'input[type="checkbox"].search-result-checkbox:checked'
  // );
  // const checkedIdsArray = Array.from(checkedCheckboxes).map(
  //   (checkbox) => checkbox.id
  // );
  // console.log({ checkedIdsArray });

  const search = searchArray[0];
  const sections = videoData.sections.slice();
  const admin = userRole === 'admin';
  //   const indexDEPRECATED = sections.findIndex(
  //     (x) => x.label?.match(new RegExp(search))?.index >= 0
  //   );
  const re = new RegExp(
    searchArray.reduce((acum, curr) => acum + curr + '|', '').slice(0, -1),
    'i'
  );
  //   console.log('reg expre: ', re);

  const indices = sections.reduce((acum, curr, currIndex) => {
    if (curr.label?.match(re)?.index >= 0) {
      acum.push(currIndex);
    }
    return acum;
  }, []);

  //   console.log('indices: ', indices);

  const groupedIndices = [];
  let grouping = [];
  for (let i = 0; i < indices.length; i++) {
    grouping.push(indices[i]);
    if (indices[i + 1] - indices[i] <= 2) {
      // grouping.push(indices[i+1])
      continue;
    } else {
      groupedIndices.push({
        array: grouping,
        uniqueKeywords: countMatchedKeywords(searchArray, grouping, sections),
      });
      grouping = [];
    }
  }

  //   console.log('🏘️ groupIndices: ', groupedIndices);

  function countMatchedKeywords(keywordArr, indices, sections) {
    let uniqueKeys = 0;
    keywordArr.forEach((keyword) => {
      for (let i = 0; i < indices.length; i++) {
        if (
          sections[indices[i]]?.label?.match(new RegExp(keyword, 'i'))?.index >=
          0
        ) {
          uniqueKeys++;
          break;
        }
      }
    });
    return uniqueKeys;
  }

  groupedIndices.sort((a, b) => b.uniqueKeywords - a.uniqueKeywords);
  console.log('groupedIndices: ', groupedIndices);

  return (
    <div
      className={`search-result ${admin ? 'search-result-grid' : ''}`}
      style={{ borderBottom: '1px solid rgb(200,200,200)' }}
    >
      {admin && (
        <div className="check-box-search-result">
          <input
            className="search-result-checkbox"
            type="checkbox"
            id={videoData?.id}
            name="vehicle2"
            value="Car"
            checked={isChecked}
            onChange={handleChange}
          />
        </div>
      )}
      {/* <div className="result-container"> */}
      <div
        className="title-container search-result-container"
        onClick={() => {
          onTreeVideoClickHandler(
            videoData?.id
            // sectionIndices
            // practiceIndex
          );
        }}
      >
        <div className="search-thumb">
          <img src={videoData.thumbnail} />
        </div>
        <div>
          <div className="title-line">
            {videoData.folderLocation
              ?.map((el) => {
                if (el.length > 10) {
                  return el.slice(0, 10) + '...';
                } else return el;
              })
              .join(', ')}
          </div>
          <div style={{ height: '22px', overflow: 'hidden' }}>
            {' '}
            <BadgeList
              danceStyle={videoData.style}
              primary={videoData.primaryKeywords}
              secondary={videoData.secondaryKeywords}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: 'var(--text-light-gray)',
            }}
          >
            <div style={{ position: 'relative', top: '-2px' }}>
              <AccessTimeIcon
                style={{ fontSize: '16px', marginRight: '3px' }}
              />
            </div>
            <div>{timeAgo(videoData.dateRecorded)}</div>
          </div>
        </div>
      </div>
      <div className="empty-grid-space"></div>
      <div>
        {groupedIndices &&
          // NEEDS REWOKRING
          groupedIndices.map((group, i) => {
            if (!showAll && i > limitShowCount - 1) return;
            const arr = group.array;
            return (
              <VideoClickWrapper
                className="search-result-container"
                id={videoData.id}
                videoData={videoData}
                selection={new Array(arr[arr.length - 1] - arr[0] + 1 + 2)
                  .fill(arr[0] - 1)
                  .map((el, i) => el + i)
                  .filter((el) => el >= 0 && el < videoData.sections.length)}
                //   selection={selection}
                onTreeVideoClickHandler={onTreeVideoClickHandler}
              >
                {searchArray.length > 0 && (
                  <LabelContext
                    sections={sections}
                    uniqueKeyCount={group.uniqueKeywords}
                    // indexArr={indexArr}
                    index={arr[0]}
                    indexSpan={arr[arr.length - 1] - arr[0] + 1}
                    searchArr={searchArray}
                    isLast={i === groupedIndices.length - 1}
                  />
                )}
              </VideoClickWrapper>
            );
          })}
        {groupedIndices?.length > limitShowCount && (
          <div
            className="show-all-button-container"
            onClick={() => {
              setShowAll((prev) => !prev);
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'var(--accent)',
                marginTop: `${showAll ? '2px' : '7px'}`,
              }}
            >
              {' '}
              {showAll ? (
                <>
                  <div>show less</div>
                  <KeyboardArrowUpIcon />
                </>
              ) : (
                <>
                  <div>
                    <span>show</span>
                    {/* <span style={{ fontWeight: 'bold' }}>{` ${
                      groupedIndices.length - limitShowCount
                    } `}</span> */}
                    <span> all</span>
                  </div>
                  <KeyboardArrowDownIcon />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
