import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  isAuthenticated: false,
  // isAuthenticated: true, //dev mode
  user: null,
};

const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    updateNewVideos(state, action) {
      const nextUser = { ...state.user };
      nextUser.newVideos = nextUser.newVideos.filter(
        (el) => el != action.payload
      );
      state.user = nextUser;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
