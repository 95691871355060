import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ResetPassword from './components/ResetPassword';
import MainPage from './MainPage';
import ViewSlider from './ViewSlider';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ViewSlider>
        <MainPage />
      </ViewSlider>
    ),
  },
  { path: '/resetPassword/:resetToken/:name', element: <ResetPassword /> },
  {
    path: '/demo',
    element: (
      <ViewSlider>
        <MainPage />
      </ViewSlider>
    ),
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
