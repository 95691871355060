import React, { useEffect, useMemo, useRef, useState } from 'react';
import classes from './PracticeOuterContainer.module.css';
import './CarouselStyleForPractice.css';
import './PracticeCard/PracticeCard.css';
import './Carousel.css';
import { useDispatch, useSelector } from 'react-redux';
import MenuForPractice from './PracticeCard/MenuForPractice';
import { useLocalStorage } from '../hooks/useStorage';
import CongratsModal from './PracticeCard/CongratsModal';
import { videoActions } from '../store/video';
import useFetch from '../hooks/useFetch';
import moment from 'moment-timezone';
import isEqual from 'lodash/isEqual';
import PracticeCarousel from './PracticeCard/PracticeCarousel';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import PracticeList from './PracticeList';

// 440

let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
timeZone = timeZone.replace('/', '%2F');

let count = 0;

const PracticeOuterContainer = ({
  title,
  isMobile,
  onTreeVideoClickHandler,
}) => {
  const dispatch = useDispatch();
  const [viewCarousel, setViewCarousel] = useState(false);
  const [deleteStage, setDeleteStage] = useState(null);

  const [
    completedCards,
    prevCarouselIndex,
    tomorrowsCards,
    user,
    todaysCards,
    allSavedSelections,
    practiceDictionary,
  ] = useSelector(
    (state) => [
      // state.video.savedSelections.length,
      state.video.selectionsCompletedToday,
      state.video.prevCarouselIndex,
      state.video.tomorrowsSections,
      state.auth.user,
      state.video.savedSelections,
      state.video.allSavedSelections,
      state.video.practiceDictionary,
    ],
    isEqual
  );

  console.log('🎃 practice outer container RERENDER: ', count++);
  console.log({ allSavedSelections });
  console.log({ practiceDictionary });

  const { loading: todaySectionsLoading, error: todaySectionsError } = useFetch(
    `${process.env.REACT_APP_BACKEND}/api/v1/sections/user/${user._id}/time-zone/${timeZone}`,
    (dataSection) => {
      console.log({ dataSection });
      dispatch(
        videoActions.initializeSavedSelections({
          sections: dataSection.data.sections,
          selectionsCompletedToday: dataSection.data.selectionsCompletedToday,
          tomorrowSections: dataSection.data.tomorrowSections,
        })
      );
    },
    {},
    [user]
  );

  const [completedCardsDate, setCompletedCardsDate, removeCompletedCardsDate] =
    useLocalStorage('completedCardsDate', new Date('1-1-1971'));

  const [practiceSectionsDisplayed, setPracticeSectionsDisplayed] =
    useState('todaysCards');

  const practiceSections = {
    todaysCards,
    completedCards,
    tomorrowsCards,
  };

  // console.log('result savedSeclections: ', todaysCards);

  const [currentCard, setCurrentCard] = useState(null);

  const currentDeck = useMemo(
    () => practiceSections[practiceSectionsDisplayed],
    [todaysCards, practiceSectionsDisplayed]
  );

  useEffect(() => {
    // console.log(
    //   'current  card from useeffect: ',
    //   currentDeck[prevCarouselIndex]
    // );
    setCurrentCard({
      practiceIndex: prevCarouselIndex,
      id: currentDeck[prevCarouselIndex]?.id,
      videoData: currentDeck[prevCarouselIndex]?.video,
      sectionIndices: currentDeck[prevCarouselIndex]?.sections,
      lastCompletedDate: currentDeck[prevCarouselIndex]?.lastCompletedDate,
      frequency: currentDeck[prevCarouselIndex]?.frequency,
      completedTimes: currentDeck[prevCarouselIndex]?.completedTimes,
    });
  }, [todaysCards, practiceSectionsDisplayed]);

  const completed = completedCards?.length;
  const remaining = currentDeck?.length;
  const [showCongrats, setShowCongrats] = useState(null);

  useEffect(() => {
    // console.log('date from saved: ', new Date(completedCardsDate));
    // console.log('date from now: ', new Date(moment.tz(timeZone)));

    if (
      practiceSectionsDisplayed &&
      completed > 0 &&
      remaining === 0 &&
      !isSameDay(new Date(completedCardsDate), new Date(moment.tz(timeZone)))
    ) {
      setShowCongrats(true);
      setCompletedCardsDate(new Date(moment.tz(timeZone)));
    }
  }, [completed, remaining]);

  useEffect(() => {
    if (deleteStage === 1) {
      // setDeleteStage(2);
      // setTimeout(() => {
      //   setDeleteStage(2);
      //   // the delay time has to be greater than the transition time
      // }, 700);
    }
  }, [deleteStage]);

  return (
    <div
      className={`${classes['practice-contain-all']} confetti-frame practice`}
      style={{ borderTop: 'none', height: '100%' }}
    >
      {false ? (
        // {viewCarousel ? (
        <>
          {showCongrats && (
            <CongratsModal
              completed={completed}
              setShowCongrats={setShowCongrats}
            />
          )}

          <div className={classes['practice-summary-container']}>
            <div className={classes['hamburger-menu-container']}>
              <MenuForPractice
                setPracticeSectionsDisplayed={setPracticeSectionsDisplayed}
                practiceSectionsDisplayed={practiceSectionsDisplayed}
                // sendDataFromHook={sendDataFromHook}
              />
            </div>
            <div
              className={classes['change-view-container']}
              onClick={() => {
                setViewCarousel((prev) => !prev);
              }}
            >
              {viewCarousel ? (
                <ViewListIcon style={{ fontSize: '30px' }} />
              ) : (
                <ViewCarouselIcon style={{ fontSize: '30px' }} />
              )}
            </div>
            {practiceSectionsDisplayed === 'todaysCards' && (
              <>
                {/* {!todaySectionsLoading ? ( */}
                <div className={classes['practice-heading']}>
                  {remaining !== 0 || todaySectionsLoading ? (
                    <div>Today's Sections</div>
                  ) : (
                    <div className={classes['none-sections-text']}>
                      No practice sections for today
                    </div>
                  )}
                </div>
                {/* ) : (
              <div className={classes['none-sections-text']}>
                Loading practice sections...
              </div>
            )} */}
              </>
            )}
            {practiceSectionsDisplayed === 'completedCards' && (
              <div className={classes['practice-heading']}>
                {remaining > 0 ? (
                  <div>Completed Sections</div>
                ) : (
                  <div className={classes['none-sections-text']}>
                    No completed practice sections
                  </div>
                )}
              </div>
            )}
            {practiceSectionsDisplayed === 'tomorrowsCards' && (
              <>
                <div className={classes['practice-heading']}>
                  {remaining !== 0 ? (
                    <div>{`Tomorrow's Sections`}</div>
                  ) : (
                    <div className={classes['none-sections-text']}>
                      {`${
                        //     !errorSendingTomorrow
                        //  ?
                        'No practice sections for tomorrow'
                        // : 'Error loading data. Check internet or try again.'
                      }`}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <PracticeCarousel
            currentCard={currentCard}
            currentDeck={currentDeck}
            completed={completed}
            remaining={remaining}
            deleteStage={deleteStage}
            setDeleteStage={setDeleteStage}
            onTreeVideoClickHandler={onTreeVideoClickHandler}
            prevCarouselIndex={prevCarouselIndex}
            practiceSectionsDisplayed={practiceSectionsDisplayed}
            todaySectionsLoading={todaySectionsLoading}
            setCurrentCard={setCurrentCard}
          />
        </>
      ) : (
        allSavedSelections &&
        (practiceDictionary || practiceDictionary == null) && (
          <PracticeList
            viewCarousel={viewCarousel}
            // currentDeck={currentDeck}
            onTreeVideoClickHandler={onTreeVideoClickHandler}
            practiceSectionsDisplayed={practiceSectionsDisplayed}
            setViewCarousel={setViewCarousel}
          />
        )
      )}
    </div>
  );
};

export default PracticeOuterContainer;

function isSameDay(date1, date2) {
  console.log('date 1: ', date1.getDate());
  console.log('date 2: ', date2.getDate());
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
