import React from 'react';
import { IonIcon } from '@ionic/react';
import {
  eyeOffOutline,
  refreshOutline,
  playOutline,
  pauseOutline,
} from 'ionicons/icons';
import './ControlButtons.css';
import ControlButton from '../video_components/ControlButton';

export default function ControlButtons(props) {
  const StartButton = (
    <div className="btn btn-one btn-start" onClick={props.handleStart}>
      Start
    </div>
  );
  const ActiveButtons = (
    <div className="btn-grp">
      <ControlButton
        onClickHandler={props.handleReset}
        icon={refreshOutline}
        padding={0}
        buttonScale={0.5}
        iconFontSize={20}
        background="red"
      />
      <ControlButton
        onClickHandler={props.handlePauseResume}
        icon={props.isPaused ? playOutline : pauseOutline}
        buttonScale={0.5}
        iconFontSize={20}
        // padding={0}
        background="green"
      />
      {/* <div className="btn btn-two" onClick={props.handleReset}>
        Reset
      </div> */}
      {/* <div className="btn btn-one" onClick={props.handlePauseResume}>
        {props.isPaused ? 'Resume' : 'Pause'}
      </div> */}
    </div>
  );

  return (
    <div className="Control-Buttons">
      <div className="btn-grp hide-button">
        {/* <IonIcon icon={eyeOffOutline} size="medium" color="white"></IonIcon> */}
      </div>
      <div>{props.active ? ActiveButtons : StartButton}</div>
    </div>
  );
}
