import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinearProgress from '@mui/material/LinearProgress';
import classes from './UploadFromPresigned.module.css';
import LinearProgressWithLabel from './LinearProgress';
import VideoFormStyleNStudio from './VideoFormStyleNStudio';
import VideoContainerForUpload from './video_components/VideoContainerForUpload';
// import crypto from 'crypto-browserify';
// import cryptoRandomString from 'crypto-random-string';

// const randomFileName = (bytes = 32) =>
//   crypto.randomBytes(bytes).toString('hex');

export default function UploadFromPresigned({ onModalClose }) {
  //   const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [presignedUrl, setPresignedUrl] = useState(null);
  const [showFormOne, setShowFormOne] = useState(false);
  const [videoId, setVideoId] = useState(null);
  const [annotate, setAnnotate] = useState(false);
  const [videoData, setVideoData] = useState(null);

  const videoPlayerRef = useRef(null);
  const fileInfo = useRef({});

  const hiddenFileInput = useRef(null);
  //   const filename = 'testttttt';

  const handleFileChange = (event) => {
    // setSelectedFile(event.target.files[0]);
    const file = event.target.files[0];
    handleUpload(file);
  };

  const handleUpload = async (selectedFile) => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    setUploading(true);

    try {
      //   const response = await fetch(presignedUrl, {
      //     method: 'PUT',
      //     body: selectedFile,
      //     headers: {
      //       'Content-Type': selectedFile.type,
      //     },
      //   });

      const response = await axios.put(presignedUrl, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      });

      if (response.status === 200) {
        // alert('File uploaded successfully!');
        // const info = await response.json();
        // console.log('response: ', response.config.data);
        const file = response.config.data;
        fileInfo.current.fileSize = file.size;
        fileInfo.current.dateRecorded = file.lastModifiedDate;
        createVideoDoc();
      } else {
        alert('Failed to upload file.');
      }
    } catch (error) {
      console.log('Error uploading file:', error);
      console.error('Error uploading file:', error);
      alert('Error uploading file.');
    } finally {
      setShowFormOne(true);
      //   setUploading(false);
      //   setUploadProgress(0);
    }
  };

  useEffect(() => {
    const getURL = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/videos/presigned-url`,
        {
          //   method: 'POST',
          //   body: formData,
          // headers: new Headers({
          //   'content-type': 'multipart/form-data',
          //   // Axios automatically sets 'Content-Type' for FormData
          //   // In Fetch, you need to omit 'Content-Type' to let the browser set it
          // }),
        }
      );
      //   alert(URL);
      const data = await response.json();
      //   console.log({ data });
      const { url, filename } = data.data;
      console.log({ url });
      console.log({ filename });
      fileInfo.current.url = filename;
      setPresignedUrl(url);
    };

    getURL();
  }, []);

  async function createVideoDoc() {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/videos/createPrelimVideo`,
      {
        method: 'POST',
        // mode: 'cors',
        // withCredentials: true,
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(fileInfo.current),
      }
    );
    const newVideo = await response.json();
    console.log({ newVideo });
    setVideoId(newVideo.data.id);
    setVideoData(newVideo.data);
  }

  return (
    <>
      {!annotate && (
        <div className={classes['outer-container']}>
          <>
            <h2>Upload Video</h2>
            <p>
              Choose a high quality video that will be optimized for your data
              needs
            </p>

            <Button
              sx={{ my: 2 }}
              variant="contained"
              startIcon={<FileUploadIcon />}
              onClick={() => {
                hiddenFileInput.current.click();
              }}
            >
              Upload
            </Button>
            {uploading && <LinearProgressWithLabel value={uploadProgress} />}
            <input
              type="file"
              accept="video/*"
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {showFormOne && (
              <VideoFormStyleNStudio
                videoId={videoId}
                openAnnotate={() => setAnnotate(true)}
              />
            )}
          </>
        </div>
      )}
      {annotate && (
        <VideoContainerForUpload
          ref={videoPlayerRef}
          url={videoData.url}
          // url="https://d2qxuoym2zs537.cloudfront.net/hhh3/filename.m3u8"
          videoId={videoId}
          // selectedFile={compressedVideo}
          //   selectedFile={selectedFile}
          onModalClose={onModalClose}
        />
      )}
    </>
  );
}
