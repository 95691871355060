import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { videoActions } from '../store/video';
import swipeDetect from './swipeDetect';
import './SectionBarList.css';

import Bundle from './Bundle';
import useScrollButtons from './useScrollButtons';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import FadeInOutWrapper from './FadeInOutWrapper';
import { tourActions } from '../store/tour';

let renderCount = 0;

export default React.memo(function SectionBarList({
  videoData,
  // sectionClickHandler,
  selectedSections,
  // addSavedSelections,
  seekToTime,
  // notes,
  sectionListRef,
  // setShowOutOfBoundsButton,
  showSectionBarList,
  temporarilyHideControls,
  setShowSectionBarList,
  setBarToRename,
  selectionIndices,
}) {
  console.log('🎧 SectionBarList Rerender: ', renderCount++);
  const ref = useRef(null);

  const dispatch = useDispatch();
  const addSavedSelections = [];
  const sectionListOuterRef = useRef(null);
  // const seekToTime = () => [];
  const practiceSections = useSelector(
    (state) => state.video.currentSavedSelections
  );

  const sectionListForOpacityRef = useRef(null);
  const [showOutOfBoundsButton, resolveOutOfBounds] =
    useScrollButtons(sectionListRef);
  console.log({ showOutOfBoundsButton });

  useEffect(() => {
    if (!showSectionBarList || temporarilyHideControls) return;
    swipeDetect(
      sectionListOuterRef.current,
      () => {
        dispatch(tourActions.handleLinkedAction('swipeSectionBar'));
        setShowSectionBarList(false);
      },
      sectionListForOpacityRef.current
    );
  }, [showSectionBarList, setShowSectionBarList, temporarilyHideControls]);

  // this will help with the edge case of the useDragSelection.js module where  if you drag over the last selected element you exit the module without triggering the touchEnd fucntions
  if (selectedSections?.length === 0) {
    if (sectionListRef?.current?.style) {
      sectionListRef.current.style.overflow = 'auto';
    }
  }

  const handlePlaySection = (index) => {
    console.log('pracc sectiosn inside handleplay:  ', practiceSections);

    const chosenSection = practiceSections.filter((section) =>
      section.sections.includes(index)
    )[0];
    // console.log('pracc chosenSection handleplay:  ', chosenSection);
    // setLoopTime(chosenSection.looptime);
    dispatch(videoActions.updateLoopTime(chosenSection.looptime));
    // dispatch(videoActions.playSavedSelection(chosenSection.looptime));
    // setSelectedSections([]);
  };

  const colorArray = [
    '#FF3DBF',
    '#3D7AFF',
    '#3DBFFF',
    '#3DFFA6',
    '#E4FF3D',
    '#FF853D',
    '#FF3D3D',
    '#FF3D84',
    '#FF7AE4',
    '#3DCAFF',
    '#3DFF57',
    '#57FF3D',
    '#FFE43D',
    '#FF3DFF',
    '#3DFFFF',
    '#3DFF71',
    '#FF3DE4',
    '#3DFFB8',
  ];
  //    ['#F0A7A0', '#F26CA7', '#61C9A8'];

  // console.log('practiceSections: ', practiceSections);
  const practiceSectionsSorted = [...practiceSections];
  practiceSectionsSorted.sort((a, b) => a.sections[0] - b.sections[0]);
  // console.log('practiceSectionsSorted: ', practiceSectionsSorted);
  //   console.log('practiceSectionsSorted: ', practiceSectionsSorted);
  let colorIndex = 0;
  let sectionInfo = {};
  for (let practiceSesh of practiceSectionsSorted) {
    for (let [index, section] of practiceSesh.sections.entries()) {
      //   console.log('index, section:', index, section);
      if (!sectionInfo[section]) {
        sectionInfo[section] = {
          idOfPractice: practiceSesh.id,
          isFirst: index === 0 && index !== practiceSesh.sections.length - 1,
          isLast: index == practiceSesh.sections.length - 1,
          isOnly: index === 0 && index === practiceSesh.sections.length - 1,
          isMiddle: index !== 0 && index !== practiceSesh.sections.length - 1,
          boxColorFill: colorArray[colorIndex % colorArray.length],
          hasNotes: practiceSesh.notes.length > 0,
        };
      } else break;
    }
    colorIndex++;
  }
  for (let [index, section] of selectedSections.entries()) {
    const isMiddle = sectionInfo[section]?.isMiddle;
    const isFirst =
      sectionInfo[section]?.isFirst || sectionInfo[section]?.isOnly;
    sectionInfo[section] = {
      //   idOfPractice: practiceSesh.id,
      isFirst:
        !isMiddle && index === 0 && index !== selectedSections.length - 1,
      isLast:
        !isMiddle &&
        !isFirst &&
        index == selectedSections.length - 1 &&
        index !== 0,
      isOnly: index === 0 && index === selectedSections.length - 1,
      isMiddle:
        isMiddle ||
        (isFirst && index > 0) ||
        (index > 0 && index < selectedSections.length - 1),
      //   boxColorFill: colorArray[colorIndex % colorArray.length],
    };
  }

  // how do i want to divide sections?
  // tertiary (basics,no label and talking??)
  // saved sections

  const copyOfSections = videoData.sections?.slice() ?? [];
  const labelArray = [];

  const savedSelectionIndex = 0;
  const practSectColorLookup = { length: 0 };
  let colorIndexNew = 0;
  copyOfSections.forEach((section, index) => {
    // check if there exists a section with
    // console.log(copyOfSections)
    let label;
    addSavedSelections.forEach((selection) => {
      if (selection.sections.includes(index)) {
        label = selection.id;
      }
    });

    if (label) {
      if (!practSectColorLookup[label]) {
        practSectColorLookup[label] =
          colorArray[colorIndexNew % colorArray.length];
        colorIndexNew++;
      }
    } else if (false && section.label?.length === 0) {
      // } else if (section.label.length === 0) {
      //} || section.label === 'basic') {
      label = 'tertiary';
    } else {
      label = 'primary';
    }
    labelArray.push(label);
  });

  const labelArrayCleaned = [...new Set([...labelArray])];
  // console.log(('label array cleaned', labelArrayCleaned));

  // console.log('color and labels: ', practSectColorLookup);

  // console.log('🦁 label array: ', labelArray);

  const dividedList = [];
  const bundleLabel = [];
  let prevLabel;
  let currIndex = -1;
  copyOfSections.forEach((section, index) => {
    const curLabel = labelArray[index];
    if (curLabel !== prevLabel) {
      currIndex++;
      const newArray = [
        {
          ...section,
          originalIndex: index,
          curLabel,
          // notes: notes ? notes[index] : null,
        },
      ];
      dividedList.push(newArray);
      prevLabel = curLabel;
      bundleLabel.push(curLabel);
    } else {
      dividedList[currIndex].push({
        ...section,
        originalIndex: index,
        // notes: notes ? notes[index] : null,
      });
    }
  });
  // console.log('divided list: ', dividedList);
  const [hideTertiary, setHideTertiary] = useState(true);

  // console.log('👺 divied list: ', dividedList);

  return (
    <div ref={sectionListOuterRef} className="section-list-outer-container">
      <AnimatePresence>
        {showOutOfBoundsButton === 'top' && (
          <motion.div
            className="out-of-bounds-button top"
            onClick={resolveOutOfBounds}
            key="box"
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.6 }}
            transition={{ duration: 0.3 }}
          >
            <KeyboardDoubleArrowUpIcon />
          </motion.div>
        )}
        {showOutOfBoundsButton === 'bottom' && (
          <motion.div
            className="out-of-bounds-button bottom"
            onClick={resolveOutOfBounds}
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.6 }}
            transition={{ duration: 0.3 }}
          >
            <KeyboardDoubleArrowDownIcon />
          </motion.div>
        )}
      </AnimatePresence>
      <FadeInOutWrapper sectionListRef={sectionListRef}>
        <div
          className={`section-list faded-top`}
          ref={sectionListRef}
          style={
            {
              // display: 'block', //
              // position: 'absolute',
              // top: '5%',
              // width: '150px',
              // // opacity: 0.2,
              // // transform: 'translateY(-50%)',
              // right: '00px',
              // bottom: '5%',
            }
          }
        >
          <div
            ref={sectionListForOpacityRef}
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              // background: 'linear-gradient(to right, #00000000, #000000BB)',
            }}
          >
            <LayoutGroup>
              {dividedList.map((bundle, index) => {
                const label = bundleLabel[index];
                // console.log('label when dividing: ', label);
                // console.log('correspondign color: ', practSectColorLookup[label]);
                //   const backgroundColor = colorArray[index % colorArray.length];

                const backgroundColor =
                  label === 'tertiary'
                    ? '#444444BB'
                    : label === 'primary'
                    ? '#444444BB' //'#3498ebAA'
                    : '#3498ebBB'; //practSectColorLookup[label] + 'AA';
                // : practSectColorLookup[label];
                return (
                  // (!hideTertiary || bundle[0].label?.length !== 0) && (
                  <Bundle
                    bundle={bundle}
                    index={index}
                    hideTertiary={hideTertiary}
                    seekToTime={seekToTime}
                    bundleLabel={bundleLabel[index]}
                    backgroundColor={backgroundColor}
                    sectionListRef={sectionListRef}
                    // setShowOutOfBoundsButton={setShowOutOfBoundsButton}
                    setBarToRename={setBarToRename}
                    selectionIndices={selectionIndices}
                  />
                );
              })}
            </LayoutGroup>

            <div style={{ width: '100%', height: '30px' }} />
          </div>
        </div>
      </FadeInOutWrapper>
    </div>
  );
});
