import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './PracticeCardListItem.css';
import { videoActions } from '../../store/video';
import { useDispatch } from 'react-redux';
import MenuExtraCardOptions from './MenuExtraCardOptions';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import MenuForChangeColumn from './MenuForChangeColumn';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import CountIncrement from './CountIncrement';
import Divider from '@mui/material/Divider';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { motion } from 'framer-motion';
import TimerOverlay from '../StopWatch/TimerOverlay';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';

let renderCount = 0;

function PracticeCardListItemDemo({
  item,
  onTreeVideoClickHandler,
  practiceSectionsDisplayed,
  dragHandleProps,
  columns,
  setColumns,
  onChangeColumn,
  onAddNewColumn,
  sourceIndex,
  index,
  updateCount,
  isSectionBeingTimed,
  setSectionBeingTimed,

  activeSectionTimer,
  setActiveSections,
}) {
  // const {
  //   // notes,
  //   // id,
  //   video,
  //   // sectionLabels,
  //   sections,
  //   completedTimes,
  //   looptime,
  //   count,
  // } = item;

  const label = 'test';
  const notes = 'fuck';
  const id = 1234;
  const sectionLabels = ['fuck', 'yeah', 'x', 's'];

  return (
    <motion.div
      className={`card-and-stats-demo ${
        practiceSectionsDisplayed === 'completedCards'
          ? 'filter-div-practice-card'
          : ''
      }`}
      // style={{ height: '100%' }}
    >
      <motion.div className="card-sx card-sx-specific">
        <motion.div
          className="main-content-card"
          style={{
            width: '100%',
            position: 'relative',

            display: 'flex',
            flexDirection: 'row',
            boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)`,
          }}
        >
          <div
            style={{
              position: 'relative',
              // height: '100%',
              width: '85px',
              minWidth: '85px',
            }}
          >
            <div
              // src={video.thumbnail}
              borderRadius="0px"
              style={{
                height: '100%',
                width: '100%',
                backgroundColor: 'var(--demo-color)',
                // width: '75px',
                // aspectRatio: 1,

                objectFit: 'cover',
                objectPosition: '50% 25%',
                // objectViewBox: 'inset(7% 7% 16% 0)',
                // borderRadius: '4px',
              }}
            ></div>

            <div className="play-icon-container">
              <PlayArrowRoundedIcon
                style={{
                  fontSize: '60px',
                  color: 'white',
                  // color: '#FFFFFFAA',
                  // filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.41))',
                }}
              />
            </div>
          </div>
          <CardContent
            style={{ flexGrow: 1, overflow: 'hidden', padding: '12px' }}
          >
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 500,
                // padding: 0,
              }}
            >
              {/* <MenuExtraCardOptions /> */}
            </div>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                // justifyContent: 'space-between',
                height: '100%',
                // padding: 0,
              }}
            >
              <div
                className="flex-row-align-center"
                style={{
                  marginBottom: '5px',
                  width: '80%',
                  height: '22px',
                  borderRadius: '12px',
                  backgroundColor: 'var(--demo-color)',
                }}
              >
                {/* <Typography
                  gutterBottom
                  // variant="h6"
                  component="div"
                  fontSize={15}
                  style={{ marginBottom: 0 }}
                >
                  {label}
                </Typography> */}
              </div>

              {/* <Typography variant="body2" color="text.secondary">
                {notes}
              </Typography> */}
              <div style={{ flexGrow: 1, pointerEvents: 'none' }}>
                <div
                  className="label-container-practice-card"
                  style={{ gap: '0 6px' }}
                >
                  {sectionLabels?.map((label, index) => {
                    if (index > 0 && label === sectionLabels[index - 1]) return;
                    return (
                      <>
                        <div
                          className="label-in-practice-cards-col"
                          style={{
                            width: '50px',
                            height: '18px',
                            backgroundColor: 'var(--demo-color)',
                          }}
                        >
                          {/* {label.length === 0 ? '??' : label} */}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div className="card-button-container">
                <div className="card-button-bottom-demo">
                  <TimerOutlinedIcon
                    style={{
                      color: 'var(--demo-color)',
                      fontSize: '28px',
                    }}
                    onClick={() => {
                      setActiveSections((prev) => [...prev, id]);
                      // setExpandedStopwatch((prev) => !prev);
                      setSectionBeingTimed({ label: label, id: id });
                    }}
                  />
                </div>
                <div className="card-button-bottom-demo">
                  <BarChartOutlinedIcon
                    style={{
                      color: 'var(--demo-color)',
                      fontSize: '28px',
                    }}
                  />
                </div>

                <div style={{ flexGrow: 1 }}></div>
                {/* <MenuForChangeColumn
                  item={item}
                  columns={columns}
                  setColumns={setColumns}
                  onChangeColumn={onChangeColumn}
                  sourceIndex={sourceIndex}
                  onAddNewColumn={onAddNewColumn}
                > */}
                <div
                  className="card-button-bottom-demo "
                  // className="drag-button"
                >
                  <DriveFileMoveOutlinedIcon
                    style={{
                      color: 'var(--demo-color)',
                      fontSize: '27px',
                    }}
                  />
                </div>
                {/* </MenuForChangeColumn> */}
                <div
                  className="card-button-bottom-demo "
                  //  className="drag-button"
                  {...dragHandleProps}
                >
                  <DragHandleIcon
                    style={{
                      color: 'var(--demo-color)',
                      fontSize: '30px',
                    }}
                  />
                </div>
                {/* </div> */}
              </div>
            </div>
          </CardContent>
        </motion.div>
        {false && (
          <motion.div
            initial={{ height: '0', y: '-73px' }}
            animate={{ height: 'auto', y: '0px' }}
            // initial={{ y: '-100px' }}
            // animate={{ y: 0 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="stopwatch-more-options"
          >
            <div style={{ flexGrow: 1 }} />
            <div className="laptime-container">
              <div>3:40</div>
              <div>12:05</div>
              <div>5:25</div>
            </div>
            <div className="save-time-button">save</div>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
}

// function getBrightness(hex) {
//   var rgb =
//     'rgb(' +
//     (hex = hex.replace('#', ''))
//       .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
//       .map(function (l) {
//         return parseInt(hex.length % 2 ? l + l : l, 16);
//       })
//       .join(',') +
//     ')';

//   // Get array of RGB values
//   rgb = rgb.replace(/[^\d,]/g, '').split(',');

//   var r = rgb[0],
//     g = rgb[1],
//     b = rgb[2];

//   var brightness = Math.floor((r * 299 + g * 587 + b * 114) / 1000);

//   return brightness;
// }

function indexOfSmallest(a) {
  return a.indexOf(Math.min.apply(Math, a));
}

export default PracticeCardListItemDemo;

function interpolateColor(rgb1, rgb2, percentage) {
  // Function to parse RGB string to array
  function parseRgb(rgb) {
    let result = rgb.match(/\d+/g);
    return result ? result.map(Number) : null;
  }

  // Function to format RGB array to string
  function formatRgb(rgbArray) {
    return `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})`;
  }

  // Parse the input RGB strings
  let color1 = parseRgb(rgb1);
  let color2 = parseRgb(rgb2);

  if (!color1 || !color2) {
    throw new Error('Invalid RGB input');
  }

  // Interpolate between the two colors
  let r = Math.round(color1[0] + (color2[0] - color1[0]) * percentage);
  let g = Math.round(color1[1] + (color2[1] - color1[1]) * percentage);
  let b = Math.round(color1[2] + (color2[2] - color1[2]) * percentage);

  // Format the result as an RGB string
  return formatRgb([r, g, b]);
}
