import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { shareVideo } from './store/friend';
import useLongPress from './hooks/useLongPress';
import './FriendBubble.css';
import { Thumbnail } from './components/Thumbnail';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';

export default function FriendBubble({
  name,
  photo,
  videoId,
  friendTag,
  onModalClose,
  alreadySent,
}) {
  //   console.log('🦶🏽 in FriendBubble: ', videoId, friendTag);
  const [showX, setShowX] = useState(false);

  const onLongPress = () => {
    console.log('longpress is triggered');
    setShowX(true);
  };

  const onClick = () => {
    console.log('click is triggered');
    dispatch(shareVideo(videoId, friendTag, onModalClose));
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 1000,
  };

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  const dispatch = useDispatch();
  return (
    <div
      className={alreadySent ? 'disabled' : ''}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '85px',
        // boxSizing: 'content-box',
        margin: '0 15px 15px 15px',
        // width: '200px',
      }}
      {...longPressEvent}
      //   disabled={true}
    >
      <div style={{ height: '100%' }}>
        <div style={{ position: 'relative', height: '50px', width: '50px' }}>
          {/* <Thumbnail src={}/> */}
          {photo ? (
            <Thumbnail src={photo} type="circle" />
          ) : (
            <Avatar sx={{ width: '100%', height: '100%', bgcolor: 'blue' }}>
              {name[0].toUpperCase()}
            </Avatar>
          )}
          {/* <img
            style={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
              borderRadius: '40px',
            }}
            src={
              photo ||
              'https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG-Picture.png'
            }
          /> */}
          {showX && (
            <button
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                height: '25px',
                width: '25px',
                borderRadius: '40px',
              }}
            >
              X
            </button>
          )}
        </div>
      </div>
      <div
        style={{
          //   paddingLeft: '10px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <p data-no-translation style={{ display: 'inline-block', margin: 0 }}>
          {name}
        </p>
      </div>
    </div>
  );
}
