import { Fragment } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import classes from './Modal.module.css';
import { height } from '@mui/system';

const Backdrop = ({ onClose }) => {
  return <div className={classes.backdrop} onClick={onClose} />;
};

const ModalOverlay = ({
  onClose,
  children,
  fixedHeight,
  xColor,
  hideX,
  paddingLeft,
  paddingRight,
  content100,
}) => {
  return (
    <div
      className={`${classes.modal} ${
        fixedHeight
          ? classes['modal-fixed-height']
          : classes['modal-flexible-height']
      }`}
      style={
        {}
        // left: paddingLeft,
        // right: paddingRight,
      }
    >
      <div
        className={true ? classes.content : classes['content-flexible']}
        style={content100 ? { height: '100%' } : {}}
        // className={fixedHeight ? classes.content : classes['content-flexible']}
      >
        {children}
      </div>
      {!hideX && (
        <button
          className={classes['exit-button']}
          onClick={onClose}
          style={{ color: xColor }}
        >
          <IonIcon icon={closeOutline} size="medium" color="white"></IonIcon>
        </button>
      )}
    </div>
  );
};

const portalElement = document.getElementById('overlays');

const Modal = ({
  onModalClose,
  children,
  fixedHeight = true,
  xColor = 'black',
  paddingLeft = '5%',
  paddingRight = '5%',
  hideX = false,
  content100 = false,
}) => {
  const handleUserKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === 27) {
      onModalClose();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, []);

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={onModalClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onClose={onModalClose}
          fixedHeight={fixedHeight}
          xColor={xColor}
          hideX={hideX}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          content100={content100}
        >
          {children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
