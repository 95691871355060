import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ChangeABLoop.css';
import { videoActions } from '../../store/video';
import {
  makeStyles,
  Slider,
  withStyles,
  Button,
  Tooltip,
  Popover,
  Grid,
} from '@material-ui/core';
import ABSlider from './ABSlider';

export default function ChangeABLoop({
  setShowSlider,
  onSeek,
  onSeekMouseUp,
  onMouseDown,
  duration,
}) {
  const dispatch = useDispatch();
  const looptime = useSelector((state) => state.video.looptime);
  const originalLooptime = useRef(looptime);
  // const value
  const [valueDiff, setValueDiff] = useState({ start: 0, end: 0 });

  return (
    <>
      <div style={{ flexGrow: 1, margin: 0 }}>
        <p className="option-heading">Edit Loop Endpoints</p>
        <div className="label-and-slider-container">
          <div className="slider-labels" gridArea="label1">
            Start
          </div>
          <ABSlider
            onChange={(e, value) => {
              dispatch(
                videoActions.updateLoopTime([
                  originalLooptime.current[0] + value,
                  looptime[1],
                ])
              );
              onSeek(
                e,
                ((originalLooptime.current[0] + value) * 100) / duration
              );
            }}
            onChangeCommitted={onSeekMouseUp}
            onMouseDown={onMouseDown}
            gridArea="slider1"
            // onMouseDown={onMouseSeekDown}
          />
          {/* </div> */}
          {/* <div className="label-and-slider-container"> */}
          <div className="slider-labels" gridArea="label2">
            End
          </div>
          <ABSlider
            onChange={(e, value) => {
              console.log('crop originalLooptime: ', originalLooptime.current);
              console.log('crop looptime: ', looptime);
              console.log('crop value: ', value);
              // setValueDiff((prev) => {
              //   return { ...prev, start: value };
              // });
              dispatch(
                videoActions.updateLoopTime([
                  looptime[0],
                  originalLooptime.current[1] + value,
                ])
              );
              onSeek(
                e,
                ((originalLooptime.current[1] + value) * 100) / duration
              );
            }}
            onChangeCommitted={onSeekMouseUp}
            onMouseDown={onMouseDown}
            gridArea="slider2"
            // value={valueDiff.end}
          />
        </div>
        <div className="button-container">
          <button
            className="change-AB-button"
            onClick={() => {
              dispatch(videoActions.updateLoopTime(originalLooptime.current));
              setShowSlider(false);
            }}
          >
            Cancel
          </button>
          <button
            className="change-AB-button"
            onClick={() => {
              setShowSlider(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
