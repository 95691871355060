import classes from './Header.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../store/auth';
import { IonIcon } from '@ionic/react';
import { home, arrowUpCircleOutline } from 'ionicons/icons';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import Modal from './Modal';
import FileSizeDisplay from './DataDisplay/FileSizeDisplay';
import AccountMenu from './Header/AccountMenu';
import { videoActions } from '../store/video';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { Upload } from '@mui/icons-material';
import UploadFromPresigned from './UploadFromPresigned';

import { useTour } from '@reactour/tour';

const Header = ({
  onHomeClick,
  onAddVideo,

  children,
  tabIndex,
}) => {
  // const { setIsOpen } = useTour();
  const dispatch = useDispatch();

  const [inputArray, setInputArray] = useState([]);
  const [showPresignUpload, setShowPresignUpload] = useState(false);
  // const allVideos = useSelector(state.video.allVideos);
  const [
    isAuth,
    user,
    allVideosDEP,
    sharedWithMeVideos,
    myUploads,
    currentVideo,
  ] = useSelector((state) => [
    state.auth.isAuthenticated,
    state.auth.user,
    state.video.allVideos,
    // [...state.video.sharedWithMeVideos, ...state.video.myUploads],
    state.video.sharedWithMeVideos,
    state.video.myUploads,
    state.video.currentVideo,
  ]);
  const [allVideos, setAllVideos] = useState(() => [
    ...sharedWithMeVideos,
    ...myUploads,
    ...allVideosDEP,
  ]);

  const [showUploadToolTip, setShowUploadToolTip] = useState(false);

  useEffect(() => {
    setAllVideos([...sharedWithMeVideos, ...myUploads, ...allVideosDEP]);
  }, [sharedWithMeVideos, myUploads, allVideosDEP]);

  // const allVideos = [...sharedWithMeVideos, ...myUploads];
  const singularAndPhrases = useRef({});

  useEffect(() => {
    const labelsAndCounts = {};
    if (allVideos.length > 0) {
      allVideos.forEach((vid) => {
        vid.sections?.forEach((section) => {
          const label = section.label?.trim();
          if (label?.length > 0) {
            if (labelsAndCounts[label]) {
              labelsAndCounts[label]++;
            } else {
              labelsAndCounts[label] = 1;
            }
          }
        });
      });
    }
    console.log('🧮 label and counts: ', labelsAndCounts);

    setInputArray([
      ...new Set(Object.keys(labelsAndCounts).map((el) => el.toLowerCase())),
    ]);
  }, [sharedWithMeVideos, myUploads]);

  useEffect(() => {
    //in progress test
    // want to test out having key work and then prhasese
    const labelsAndCounts = {};
    if (allVideos.length > 0) {
      allVideos.forEach((vid) => {
        vid.sections?.forEach((section) => {
          // console.log('section: ', section.label);
          if (section.label) {
            const sectionSplit = section.label.split(' ');
            // console.log('sectionSplit: ', sectionSplit);
            sectionSplit.forEach((singleWord) => {
              if (singleWord?.length > 0) {
                if (singularAndPhrases.current[singleWord.toLowerCase()]) {
                  singularAndPhrases.current[singleWord.toLowerCase()].add(
                    section.label
                  );
                } else {
                  singularAndPhrases.current[singleWord] = new Set([
                    section.label,
                  ]);
                }
              }
            });
          }
          // const label = section.label?.trim();
        });
      });
    }
    console.log('🧮 singlular and phrases: ', singularAndPhrases.current);

    // setInputArray(Object.keys(singularAndPhrases.current));
  }, [allVideos]);

  const fileSizeRef = useRef(null);
  useEffect(() => {
    fileSizeRef.current = allVideos.map((el) => el.fileSize);
  }, [allVideos]);

  const [showSize, setShowSize] = useState(false);

  function logoutHandler() {
    return async function (dispatch, getState) {
      console.log('LOGGIN OUT ✌️');
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/v1/users/logout`,
          { credentials: 'include' }
        );
        if (response.ok) {
          console.log('reponse okK!!!');
          dispatch(videoActions.logout());
          dispatch(authActions.logout());
        }
      } catch (err) {
        alert('Error logging out! Try again');
      }
    };
  }
  // const maxFileSize = fileSizeRef.current
  //   ? Math.max(...fileSizeRef.current)
  //   : 1;
  // console.log('👩‍👩‍👦 maxFileSize: ', maxFileSize);

  return (
    <div
      style={{
        zIndex: 100,
        // style={{ display: currentVideo ? 'none' : 'block', zIndex: 100
      }}
    >
      <header className={classes.header}>
        {showPresignUpload && (
          <Modal
            // fixedHeight={false}
            content100={true}
            onModalClose={() => setShowPresignUpload(false)}
          >
            <UploadFromPresigned
              onModalClose={() => setShowPresignUpload(false)}
            />
          </Modal>
        )}
        {showSize && (
          <Modal onModalClose={() => setShowSize(false)} hideX={true}>
            <div
              style={{
                height: '100%',
                width: '100%',
                overflow: 'scroll',
                // padding: '10px',
              }}
            >
              <FileSizeDisplay />
            </div>
          </Modal>
        )}
        {/* <div className={classes['left-side-header']}> */}

        <Tooltip
          title={
            user?.role === 'admin' ? '' : 'Uploading is currently disabled'
          }
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => {
            setTimeout(() => {
              setShowUploadToolTip(false);
            }, 300);
          }}
          open={showUploadToolTip}
          TransitionComponent={Zoom}
        >
          <span
            onClick={() => {
              setShowUploadToolTip(true);
            }}
          >
            <div
              className={classes['add-video-button']}
              // onClick={onAddVideo}
              onClick={() => {
                setShowPresignUpload(true);
              }}
              disabled={user?.role === 'admin' ? false : true}
              style={
                user?.role === 'admin' ? {} : { color: 'rgb(150,150,150)' }
              }
            >
              <IonIcon
                icon={arrowUpCircleOutline}
                // size="medium"
                // style={{ color: 'black' }}
                // color="black"
              ></IonIcon>
            </div>
          </span>
        </Tooltip>
        {/* <button onClick={() => setIsOpen(true)}>Open Tour</button> */}
        {/* {user?.role === 'admin' && (
          <div
            onClick={() => {
              setShowPresignUpload(true);
            }}
            style={{ backgroundColor: 'red' }}
          >
            URL
          </div>
        )} */}
        {children}
        {/* </div> */}
        {isAuth && (
          <AccountMenu
            userPhoto={user.photo}
            userName={user.name}
            userRole={user.role}
            onLogout={() => {
              dispatch(logoutHandler());
            }}
            onSettings={() => {
              setShowSize(true);
            }}
          />
        )}
      </header>
    </div>
  );
};

export default Header;
