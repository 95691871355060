import { Fragment } from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import classes from './AllVideosFromSection.module.css';
import { height } from '@mui/system';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { AnimatePresence, motion } from 'framer-motion';

const ModalOverlay = ({ onClose, children, title }) => {
  const width = window.innerWidth;
  return (
    // <AnimatePresence>
    <motion.div
      className={`${classes.modal}`}
      key="showAllVideoModalss"
      initial={width < 800 && { translateX: '100%' }}
      animate={width < 800 && { translateX: 0 }}
      exit={width < 800 && { translateX: '100%' }}
      transition={{ ease: 'easeOut', duration: 0.3 }}
      // transition={{
      //   // type: 'spring',
      //   // stiffness: 900,
      //   // damping: 40,
      //   duration: 0.5,
      // }}
    >
      <div className={classes['top-container']}>
        <div className={classes['back-arrow']} onClick={onClose}>
          <div>back</div>
          {/* <ArrowForwardOutlinedIcon style={{ fontSize: '20px' }} /> */}
        </div>
        {/* <motion.div
          layoutId={`expanded-title-${title}`}
          className={classes.title}
        > */}
        <div className={classes.title}>{title}</div>
      </div>
      <div className={classes.content}>{children}</div>

      {/* <button className={classes['exit-button']} onClick={onClose}>
        <IonIcon icon={closeOutline} size="medium" color="white"></IonIcon>
      </button> */}
    </motion.div>
    // </AnimatePresence>
  );
};

// const portalElement = document.getElementById('videoPage');

const AllVideosFromSection = ({
  onModalClose,
  children,
  title = '',
  showAllVideoRef,
}) => {
  const handleUserKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === 27) {
      onModalClose();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, []);

  return (
    <Fragment>
      {/* {ReactDOM.createPortal( */}
      <ModalOverlay onClose={onModalClose} title={title}>
        {children}
      </ModalOverlay>
      {/* // portalElement showAllVideoRef.current */}
      {/* )} */}
    </Fragment>
  );
};

export default AllVideosFromSection;
