import { createSlice } from '@reduxjs/toolkit';

// tour master
const tourDictionary = {
  usingAnnotatedList: {
    welcomeSlide: {
      title: 'Hi there! 👋',
      text: 'Would you like to take a quick tour?',
    },
    autoPlay: true,
    steps: [
      {
        title: 'Step 1',
        text: 'Tap one of the labeled bars',
        selector: '.section-bar-7',
        animation: true,
        animationClass: 'double-click-indicator',
        justifyContent: 'flex-start',
        toComplete: {
          action: 'barSingleClick',
          text: 'Awesome! You can quickly navigate using this technique',
        },
      },
      {
        title: 'Step 2',
        text: 'Double tap on a labeled bar',
        selector: '.section-bar-8',
        animation: true,
        animationClass: 'double-click-indicator',
        reducer: undefined,
        justifyContent: 'flex-start',
        toComplete: {
          action: 'barDoubleClick',
          text: 'Perfect! This will allow you to play a section on loop',
        },
      },
      {
        title: 'Step 3',
        text: 'Drag the following button down and see what happens',
        selector: '.drag-circle-8',
        animation: true,
        animationClass: 'swipe-down-indicator',
        reducer: undefined,
        justifyContent: 'flex-start',
        toComplete: {
          action: 'dragButtonBar',
          text: 'Perfect! This extends the loop section',
        },
      },
      {
        title: 'Step 4',
        text: 'Double tap on any of the high-lighted purple bars',
        selector: '.drag-circle-8',
        animation: true,
        animationClass: 'nothing',
        reducer: undefined,
        justifyContent: 'flex-start',
        toComplete: {
          action: 'barDoubleClick',
          text: 'Nice! The loop section is now cleared',
        },
      },
      {
        title: 'Step 5',
        text: 'Swipe the section display to the right',
        selector: '.section-bar-7',
        animation: true,
        animationClass: 'swipe-indicator',
        justifyContent: 'flex-end',
        toComplete: {
          action: 'swipeSectionBar',
          text: 'Great! Less clutter on the screen',
        },
      },
      {
        title: 'Step 6',
        text: 'Tap the following button to bring back the list',
        selector: '.sectionBarList-show-button',
        animation: true,
        animationClass: 'double-click-indicator',
        justifyContent: 'flex-end',
        toComplete: {
          action: 'showSectionBar',
          text: `It's back! And that is the end of this tutorial!`,
        },
      },
      // {
      //   title: 'Step 7',
      //   text: 'Select another section of 2 or more items',
      //   // selector: '.drag-circle-8',
      //   animation: createAnimation('.drag-circle-8', 'double-click-indicator'),
      //   justifyContent: 'flex-start',
      //   toComplete: {
      //     action: 'dragButtonBar',
      //     text: 'Perfect! 💃',
      //   },
      // },
      // {
      //   title: 'Step 8',
      //   text: 'You now have various options on the bottom of the screen. Tap "Practice',
      //   // selector: '.add-note-button',
      //   animation: createAnimation(
      //     '.add-note-button',
      //     'double-click-indicator'
      //   ),
      //   justifyContent: 'flex-start',
      //   toComplete: {
      //     action: 'clickAddPractice',
      //     text: 'You can now save a section to practice later',
      //   },
      // },
    ],
  },
  creatingSection: {
    // welcomeSlide: {
    //   title: 'Hi there! 👋',
    //   text: 'Would you like to take a quick tour?',
    // },
    autoPlay: false,
    steps: [
      {
        title: 'Step 1',
        text: 'Double tap on a labeled bar',
        selector: '.section-bar-8',
        animation: true,
        animationClass: 'double-click-indicator',
        reducer: undefined,
        justifyContent: 'flex-start',
        toComplete: {
          action: 'barDoubleClick',
          // text: 'Perfect! This will allow you to play a section on loop',
        },
      },
      {
        title: 'Step 2',
        text: 'Next drag the indicated button down to select multiple sections',
        selector: '.drag-circle-8',
        animation: true,
        animationClass: 'swipe-down-indicator',
        reducer: undefined,
        justifyContent: 'flex-start',
        toComplete: {
          action: 'dragButtonBar',
          // text: 'Awesome',
        },
      },

      {
        title: 'Step 3',
        text: 'Tap the Practice Button and click submit to save the section!',
        selector: '.Practice-icon',
        animation: true,
        animationClass: 'double-click-indicator',
        justifyContent: 'flex-start',
        toComplete: {
          action: 'clickAddPractice',
          // text: `It's back! And that is the end of this tutorial!`,
        },
      },
      {
        title: 'Step 4',
        // text: 'Tap the Practice Button',
        selector: '#submit-new-section',
        animation: true,
        animationClass: 'double-click-indicator',
        justifyContent: 'flex-start',
        toComplete: {
          action: 'submitPractice',
          // text: `It's back! And that is the end of this tutorial!`,
        },
      },
      {
        title: 'Step 5',
        text: 'Purrrfect! Go back to the main app to see view your saved sections',
        // selector: '.drag-circle-8',
        selector: '#back-button-controls',
        animation: true,
        animationClass: 'double-click-indicator',

        justifyContent: 'flex-end',
        toComplete: {
          action: 'homeButton',
          // text: 'Perfect! 💃',
        },
      },
    ],
  },
};

const initialTourState = {
  active: false,
  currentStep: 0,
  allowContinue: false,
  showWelcome: true,
  // currentTour: tourDictionary.usingAnnotatedList,
  currentTour: null,
  highlightCenter: null,
  // isAuthenticated: false,
  // isAuthenticated: true, //dev mode
  // user: null,
};

const tourSlice = createSlice({
  name: 'tour',
  initialState: initialTourState,
  reducers: {
    goTo(state, action) {
      const stepObj = state.currentTour?.steps?.at(action.payload);
      if (stepObj?.animation) {
        state.highlightCenter = createAnimation(
          stepObj.selector,
          stepObj.animationClass
        );
      }

      if (stepObj.toComplete) {
        state.allowContinue = false;
      } else {
        state.allowContinue = true;
      }
      state.currentStep = action.payload;
    },
    next(state, action) {
      const nextStep = Math.min(
        state.currentTour?.steps.length - 1,
        state.currentStep + 1
      );
      if (nextStep === state.currentStep) return;

      const nextStepObj = state.currentTour?.steps?.at(nextStep);
      if (nextStepObj?.animation) {
        state.highlightCenter = createAnimation(
          nextStepObj.selector,
          nextStepObj.animationClass
        );
      }

      if (nextStepObj.toComplete) {
        state.allowContinue = false;
      } else {
        state.allowContinue = true;
      }
      state.currentStep = nextStep;
    },
    prev(state, action) {
      const prevStep = Math.max(0, state.currentStep - 1);
      if (prevStep === state.currentStep) return;

      const prevStepObj = state.currentTour?.steps?.at(prevStep);
      if (prevStepObj?.animation) {
        createAnimation(prevStepObj.selector, prevStepObj.animationClass);
      }

      if (prevStepObj.toComplete) {
        state.allowContinue = false;
      } else {
        state.allowContinue = true;
      }
      state.currentStep = prevStep;
    },
    close(state, action) {
      removePresentationDivs();
      state.active = false;

      state.currentStep = 0;
      state.allowContinue = false;
      state.showWelcome = true;
      // currentTour= tourDictionary.usingAnnotatedList;
      state.currentTour = null;
      state.highlightCenter = null;
    },
    handleLinkedAction(state, action) {
      // removePresentationDivs('double-click-indicator');
      if (
        !state.allowContinue &&
        action.payload ===
          state.currentTour?.steps.at(state.currentStep).toComplete.action
      ) {
        state.allowContinue = true;
        state.showWelcome = true;
        removePresentationDivs();
      }
    },
    startTour(state, action) {
      state.active = true;
      state.showWelcome = false;
      state.currentTour = tourDictionary[action.payload];
    },
    checkForUnseenTours(state, action) {
      const toursAvailable = Object.keys(tourDictionary);
      const unSeenTours = toursAvailable.filter(
        (tour) =>
          !action.payload.includes(tour) &&
          tourDictionary[tour].autoPlay === true
      );
      const nextTour = unSeenTours[0];

      // state.active = true;
      state.active = nextTour ? true : false;
      console.log({ nextTour });
      if (nextTour) {
        state.currentTour = tourDictionary[nextTour];
      }
    },
  },
});

function removePresentationDivs() {
  // remove old indicator divs
  // Define the class name of the divs you want to remove
  const classNameToRemove = 'tour-presentation';

  // Get all elements with the specified class name
  const elements = document.querySelectorAll(`.${classNameToRemove}`);

  // Loop through the NodeList and remove each element
  elements.forEach((element) => {
    element.remove();
  });
}

function createAnimation(selector, classname) {
  // return () => {
  removePresentationDivs();
  // Select the target element
  console.log({ selector });
  const targetEl = document.querySelector(selector);
  console.log({ targetEl });
  if (!targetEl) return;
  // Get properties of the target element
  const rect = targetEl.getBoundingClientRect();
  const { top, left, width, height } = rect;

  // Create constants for the properties
  const targetTop = top;
  const targetLeft = left;
  const targetWidth = width;
  const targetHeight = height;

  // Create a new div element
  const newDiv = document.createElement('div');

  newDiv.addEventListener('click', () => {
    console.log('👅 New div clicked!');
  });

  // Add a class to the new div element
  newDiv.classList.add(classname, 'tour-presentation');

  // Set some content or properties to the new div element if needed
  // newDiv.innerHTML = 'This is a new div';

  // Append the new div element after the target element
  // targetEl.insertAdjacentElement('afterend', newDiv);
  targetEl.appendChild(newDiv);

  // Log the properties to verify
  console.log('Top:', targetTop);
  console.log('Left:', targetLeft);
  console.log('Width:', targetWidth);
  console.log('Height:', targetHeight);
  return [targetLeft + targetWidth / 2, targetTop + targetHeight / 2];
  // };
}

export const tourActions = tourSlice.actions;
export default tourSlice.reducer;
