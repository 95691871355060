import React, { useState } from 'react';
import {
  makeStyles,
  Slider,
  withStyles,
  Button,
  Tooltip,
  Popover,
  Grid,
} from '@material-ui/core';
import './SlowmoButton.css';
import ControlButton from './ControlButton';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';

const PrettoSlider = withStyles({
  root: {
    height: 20,
    '&$vertical': {
      width: 8,
    },
    color: '#9556CC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#9556CC',
    border: '2px solid currentColor',
    marginTop: -3,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 5,
    borderRadius: 4,
    width: 60,
  },
  rail: {
    height: 5,
    // width: 5,
    borderRadius: 4,
  },
  vertical: {
    '& $rail': {
      width: 8,
    },
    '& $track': {
      width: 8,
    },
    '& $thumb': {
      marginLeft: -1,
      marginBottom: -11,
    },
  },
})(Slider);

export default function SlowmoButton({
  onSetPlayback,
  playbackRate,
  setShowVisibleSpeed,
  setShowSlowmoSelect,
  isVariableSlowMo,
}) {
  //   const [playback, setPlayback] = useState(1);
  //   const [showSlider, setShowSlider] = useState(false);

  // console.log('olaybak', playbackRate);
  return (
    <>
      <div style={{ position: 'relative' }}>
        <ControlButton
          onClickHandler={() => setShowSlowmoSelect((show) => !show)}
          // source="https://cdn1.iconfinder.com/data/icons/miscellaneous-elements-7/32/playback_speed_fast_slow_video-1024.png"
          background="none"
          // imageScale={0.9}
          muiIcon={<SpeedOutlinedIcon style={{ fontSize: '35px' }} />}
          highlight={playbackRate != 1.0}
        />
        <div
          className="current-speed-label"
          style={{
            position: 'absolute',
            bottom: '-6px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '16px',
            // color: 'var(--highlight-color-1)',
            color: 'var(--highlight-color-1)',
          }}
        >
          {isVariableSlowMo
            ? 'var'
            : playbackRate != 1.0 && `${Number(playbackRate).toFixed(1)}x`}
        </div>
      </div>
    </>
  );
}

// https://icons.veryicon.com/png/o/miscellaneous/offerino-icons/list-bullet.png
