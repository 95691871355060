// import { Button } from '@mui/base';
import { Button } from '@mui/material';

import React, { useState, useRef } from 'react';
import classes from './NewGoal.module.css';
import { styled } from '@mui/material/styles';

export const CreateButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'var(--accent)',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'var(--accent)',
  },
  //   '&:disabled': {
  //     boxShadow: 'none',
  //     color: 'white',
  //     backgroundColor: 'var(--accent)',
  //     opacity: 0.6,
  //   },
  color: 'white',
}));

export default function NewGoal({ sectionIndex, setColumns, onClose }) {
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const minuteRef = useRef(null);
  const [interval, setInterval] = useState(1);
  const [intervalLabel, setIntervalLabel] = useState('weeks');

  const buttonEnabled = (hours || minutes) && interval;
  //   alert(sectionIndex);
  return (
    <>
      <div className={classes['outer-container']}>
        {/* <div className={classes.heading}>
          <div>New Goal</div>
        </div> */}
        <div className={classes['inner-container']}>
          <div className={classes.heading}>
            <div>Define Your Goal</div>
          </div>
          <div className={classes['time-increments']}>
            <div>
              <input
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                placeholder="00"
                maxLength={2}
                value={hours}
                spellcheck="false"
                onFocus={() => setHours('')}
                onChange={(e) => {
                  const value = e.target.value;
                  setHours(value.slice(0, e.target.maxLength));
                  if (value.length === 2 && !minutes) {
                    minuteRef.current?.focus();
                  }
                }}
                //   style={{ marginInline: '15px' }}
              />
              <span>h</span>
            </div>

            <div>
              <input
                ref={minuteRef}
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                placeholder="00"
                maxLength={2}
                value={minutes}
                spellcheck="false"
                onFocus={() => setMinutes('')}
                onChange={(e) => {
                  const value = e.target.value;
                  setMinutes(value.slice(0, e.target.maxLength));
                }}
                //   style={{ marginInline: '15px' }}
              />
              <span>m</span>
            </div>
          </div>
          <div className={classes['frequency-container']}>
            <span>in</span>
            <input
              //   ref={intervalCount}
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="0"
              maxLength={2}
              value={interval}
              onFocus={() => setMinutes('')}
              onChange={(e) => {
                const value = e.target.value;
                setInterval(value.slice(0, e.target.maxLength));
              }}
            />
            <select
              name="timeFrame"
              id="timeFrame"
              onChange={(e) => {
                setIntervalLabel(e.target.value);
              }}
            >
              <option>days</option>
              <option selected>weeks</option>
              <option>months</option>
            </select>
          </div>
          <div style={{ flex: '1 0 15px' }}></div>
          <div className={classes['button-container']}>
            <div className={classes.cancel} onClick={onClose}>
              cancel
            </div>
            <CreateButton
              variant="contained"
              disabled={!buttonEnabled}
              //   className={classes['create-goal']}
              onClick={() => {
                sendGoal(
                  sectionIndex,
                  hours,
                  minutes,
                  interval,
                  intervalLabel,
                  setColumns,
                  onClose
                );
              }}
            >
              Create
            </CreateButton>
          </div>
        </div>
      </div>
    </>
  );
}

export async function sendGoal(
  index,
  hours,
  minutes,
  interval,
  intervalLabel,
  setColumns,
  closingFunc,
  active = true
) {
  //   const updateObjTest = {
  //     $set: {
  //       'practiceDictionary[${index}].goal': { fuck: 'yeah' },
  //     },
  //   };
  //   console.log(JSON.stringify(updateObjTest));
  //   `{"sections.${index}.label": "${label}"}`,
  //   const updateString = `{"practiceDictionary.${index}.goal.active":true}`;
  //   console.log(JSON.parse(updateString));
  //   return;
  //   alert(index);
  const dayConversion = {
    days: 1,
    weeks: 7,
    months: 30,
  };
  const days = interval * dayConversion[intervalLabel];
  const goal = {
    active: active,
    minutes: (hours ? hours * 60 : 0) + (minutes || 0),
    endDate: addDaysToDate(days),
    duration: days,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/users/goal`,

      {
        method: 'PATCH',
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ index, goal }),
      }
    );

    console.log('rsponse from cookie check: ', response);
    if (!response.ok) {
      console.log('failed to create new goal');
    } else {
      console.log('goal created');
      const data = await response.json();
      console.log(data.data.user);

      setColumns((prev) => {
        const nextColumns = [...prev];
        nextColumns[index].goal = goal;
        return nextColumns;
      });
      if (closingFunc) {
        closingFunc();
      }

      // alert('is mobile: ', String(isMobile));
      // console.log('is mobile: ', isMobile);
    }
  } catch (err) {
    console.log(err);
  }
}

function addDaysToDate(days) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + days);
  return currentDate;
}
