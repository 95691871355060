import React from 'react';
import Avatar from '@mui/material/Avatar';

export default function AvatarPhotoOrLetters({
  userPhoto,
  userName,
  border = 'none',
}) {
  return (
    <>
      {userPhoto ? (
        <div
          className="profile-pic-purple"
          style={{
            border: border,
          }}
        >
          <Avatar
            style={{ border: border, boxSizing: 'content-box' }}
            alt="Remy Sharp"
            src={userPhoto}
          />
        </div>
      ) : (
        <Avatar
          style={{ border: border, boxSizing: 'content-box' }}
          sx={{ width: 36, height: 36, bgcolor: '#FF5C00' }}
        >
          {userName[0]}
        </Avatar>
      )}
    </>
  );
}
