import { send } from 'ionicons/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetchOnDemand from '../../hooks/useFetchOnDemand';
import { videoActions } from '../../store/video';
import AutoCompleteForNewVideo from '../AutoCompleteForNewVideo';
import ModalInline from '../ModalInline';

export default function RenameBar({
  onModalClose,
  index,
  defaultLabel = 'basic',
}) {
  const dispatch = useDispatch();
  const [label, setLabel] = useState('');
  const videoId = useSelector((state) => state.video.currentVideo.id);

  const {
    loading: sending,
    error: errorSending,
    sendData: updateLabel,
  } = useFetchOnDemand(
    `${process.env.REACT_APP_BACKEND}/api/v1/videos/update/${videoId}`,
    () => {
      dispatch(videoActions.updateSingeLabel({ label, barIndex: index }));
      onModalClose();
    },
    {
      method: 'PATCH',
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: `{"sections.${index}.label": "${label}"}`,
    },
    [label]
  );

  return (
    <ModalInline onModalClose={onModalClose}>
      <div
        style={{
          margin: '30px 20px',
          display: 'flex',
          flexDirection: 'column',
          //   alignItems: 'flex-start',
        }}
      >
        <h1>{`Change Label for index: ${index}`}</h1>
        <AutoCompleteForNewVideo
          label={label}
          setLabel={setLabel}
          defaultLabel={defaultLabel}
        />
        {sending && <p>sending...</p>}
        {errorSending && <p>{`Error sending`}</p>}

        <button
          onClick={() => {
            // alert(label);
            updateLabel();
            // onModalClose();
          }}
          style={{ alignSelf: 'flex-end' }}
        >
          Confirm
        </button>
      </div>
    </ModalInline>
  );
}
