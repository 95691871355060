import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from './components/Modal';
import FriendBubble from './FriendBubble';
import { videoActions } from './store/video';
import { submitFriend } from './store/friend';
import { IonIcon } from '@ionic/react';
import { clipboard } from 'ionicons/icons';
import ModalInline from './components/ModalInline';

const NewFriendForm = ({ videoId, onModalClose }) => {
  const [friends, yourFriendTag] = useSelector((state) => [
    state.friend.friends,
    state.auth.user.friendTag,
  ]);

  const dispatch = useDispatch();
  const describeRef = useRef(null);
  const notesRef = useRef(null);
  const [addingNewFriend, setAddingNewFriend] = useState(false);
  const [addFriendError, setAddFriendError] = useState(null);
  const [copyTagStatus, setCopyTagStatus] = useState(null);

  console.log('👯‍♂️ friends: ', friends);

  useEffect(() => {
    describeRef.current?.focus();
  }, [addingNewFriend]);

  const onClickSubmit = () => {
    console.log('valuebeing sent: ', describeRef.current.value);
    dispatch(submitFriend(describeRef.current.value, setAddFriendError));
    // submitInfo(describeRef.current.value, dispatch);
  };
  console.log('in the new form');

  return (
    <ModalInline onModalClose={onModalClose} fixedHeight={false}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          paddingTop: '20px',
          // boxSizing: 'border-box',
          justifyContent: 'space-between',
          flexDirection: 'column',
          // paddingTop: '20px',
        }}
      >
        <div style={{ flexShrink: 1, overflow: 'scroll' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(85px, 1fr))',
              minWidth: 0,
              minHeight: 0,
              maxHeight: '55vh',
              margin: '10px',
            }}
          >
            {/* {Array(80)
              .fill(friends[0])
              .map((friend) => ( */}
            {friends.map((friend) => (
              <FriendBubble
                name={friend.name}
                friendTag={friend.friendTag}
                photo={friend.photo}
                videoId={videoId}
                onModalClose={onModalClose}
                alreadySent={friend.sharedWithMeVideos.includes(videoId)}
                // onClick={() => {}}
              />
            ))}
          </div>
          {!friends[0] && (
            <p style={{ textAlign: 'center' }}>No friends yet. Add below!</p>
          )}
        </div>
        <div
          style={{
            textAlign: 'center',
            borderTop: '1px solid #999999',
            paddingTop: '10px',
            // marginTop: '15px',
            color: '#999999',
            flexShrink: 0,
            // height: '50px',
          }}
        >
          {!addingNewFriend && (
            <p onClick={() => setAddingNewFriend(true)}> + Add New Friend</p>
          )}
          {addingNewFriend && addFriendError && (
            <p style={{ textAlign: 'center', color: 'red' }}>
              {addFriendError}
            </p>
          )}
          {addingNewFriend && (
            <div style={{ width: '100%' }}>
              <input
                style={{
                  borderRadius: '5px',
                  border: '1px solid gray',
                  margin: '10px',
                }}
                ref={describeRef}
                placeholder="enter their friendTag..."
              />
              <button
                style={{
                  borderRadius: '5px',
                  color: 'white',
                  background: '#70e696',
                  border: 'none',
                  margin: '10px',
                }}
                onClick={onClickSubmit}
              >
                Confirm
              </button>
              <div
                style={{
                  width: '100%',
                  padding: '15px',
                  boxSizing: 'border-box',
                }}
                onClick={() => {
                  copyContent(yourFriendTag, yourFriendTag, setCopyTagStatus);
                }}
              >
                {!copyTagStatus && <p>→ or click to send your tag ←</p>}
                {copyTagStatus && <p>{copyTagStatus}</p>}
                {/* <span>
                  {' '}
                  <IonIcon icon={clipboard} color="black"></IonIcon>
                </span> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalInline>
  );
};

export default NewFriendForm;

async function copyContent(text, yourFriendTag, setCopyTagStatus) {
  try {
    await navigator.clipboard.writeText(text);
    console.log('Content copied to clipboard');
    setCopyTagStatus('copied to clipboard!');
    // setCopyTagStatus(`ERROR: copy manually and send: ${yourFriendTag}`);
    /* Resolved - text copied to clipboard successfully */
  } catch (err) {
    console.error('Failed to copy: ', err);
    setCopyTagStatus(`copy manually: ${yourFriendTag}`);
    /* Rejected - text failed to copy to the clipboard */
  }
}
