import React, { useState, useEffect } from 'react';
import './StopWatch.css';
import Timer from './Timer';
import ControlButtons from './ControlButtons';
import { IonProgressBar } from '@ionic/react';

// local storage element
const stopwatchExample = {
  startTime: 123213213,
  // inProgress: false,
  isActive: true,
  isPaused: true,
};

function StopWatch({ section }) {
  // console.log('😻😻😻😻 stopwatch rerender');

  const timeStorage = JSON.parse(localStorage.getItem('stopwatch'));
  let startTime = timeStorage?.startTime || 0;
  const savedTime = timeStorage?.savedTime;
  const [isActive, setIsActive] = useState(() => {
    return timeStorage?.isActive;
  });
  const [isPaused, setIsPaused] = useState(() => {
    return timeStorage?.isPaused;
  });

  const [time, setTime] = useState(() => {
    if (isActive) {
      if (isPaused) {
        return savedTime;
      } else {
        return (new Date().getTime() - startTime) / 1000;
      }
    } else return 0;
  });

  useEffect(() => {
    let interval = null;
    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
    setTime(0);
    let startTime = new Date().getTime();
    localStorage.setItem(
      'stopwatch',
      JSON.stringify({
        isActive: true,
        isPaused: false,
        startTime,
      })
    );
  };

  const handlePauseResume = () => {
    console.log('🐶 pause handler: ', isPaused);
    if (isPaused) {
      localStorage.setItem(
        'stopwatch',
        JSON.stringify({
          startTime: new Date().getTime() - time * 1000,
          isPaused: false,
          isActive,
        })
      );
    } else {
      localStorage.setItem(
        'stopwatch',
        JSON.stringify({
          startTime,
          isPaused: true,
          isActive,
          savedTime: time,
        })
      );
    }
    setIsPaused(!isPaused);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
    localStorage.setItem('stopwatch', JSON.stringify(null));
  };

  return (
    <div className="stop-watch">
      <div className="timed-section-title-container">
        <div className="timed-section-title">{section}</div>
      </div>
      {/* <div className="timed-section-title">{`${
        section.length > 20 ? section.slice(0, 19) + '..' : section
      }`}</div> */}
      <Timer time={time} />
      <ControlButtons
        active={isActive}
        isPaused={isPaused}
        handleStart={handleStart}
        handlePauseResume={handlePauseResume}
        handleReset={handleReset}
      />
    </div>
  );
}

export default StopWatch;
