import React from 'react';
import './Timer.css';

export default function Timer({ time, fontSize = '1rem' }) {
  // let time = preTime * 100;

  const hour = '0' + Math.floor(time / 3600); //.slice(-1);
  const minute = ('0' + Math.floor((time / 60) % 60)).slice(-2);
  const second = ('0' + Math.floor(time % 60)).slice(-2);

  return (
    <div className="timer" style={{ fontSize: fontSize }}>
      <div>
        {hour !== '00' && <span className="digits">{hour}:</span>}

        <span className="digits">{minute}:</span>
        <span className="digits">{second}</span>
      </div>
    </div>
  );
}
