import { TroubleshootOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import './BundleWrapper.css';

export default function BundleWrapper({ children }) {
  const [showChildren, setShowChildren] = useState(false);

  return (
    <div
      onClick={() => setShowChildren(true)}
      className="bundle-wrapper"
      showChildren={String(showChildren)}
      style={{ position: 'relative' }}
    >
      {!showChildren && (
        <div
          className="bundle-text"
          style={{ position: 'absolute', zIndex: 6, color: 'white' }}
        >
          Variations #1
        </div>
      )}

      {/* <img
        style={{
          height: '50px',
          position: 'absolute',
          zIndex: 6,
          color: 'white',
          transform: 'scaleX(-1)',
          left: '-10px',
          top: '0px',
        }}
        src="https://www.pngall.com/wp-content/uploads/2/ThumbTack-PNG-Image.png"
        alt="Thumbtack"
      /> */}
      <div className="bar-holder">{children}</div>
    </div>
  );
}
