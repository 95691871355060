import { useCallback, useRef, useState } from 'react';

const useLongPress = (
  onLongPress,
  onClick,
  { shouldPreventDefault = true, delay = 400 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const timeout = useRef();
  const target = useRef();

  const start = useCallback(
    (event) => {
      console.log('start called');
      // event.stopPropagation();
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
          // capture: false,
        });
        event.target.addEventListener('mouseup', preventDefault, {
          passive: false,
          // capture: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        // event.preventDefault();
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      console.log('clear callback');
      timeout.current && clearTimeout(timeout.current);
      // console.log({ shouldTriggerClick });
      // console.log({ longPressTriggered });
      // console.log({ isDragging });

      if (shouldTriggerClick && !longPressTriggered && !isDragging) {
        onClick(event);
      }
      setIsDragging(false);
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
        target.current.removeEventListener('mouseup', preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  const cancel = useCallback((event) => {
    console.log('we have movement');
    // console.log('long press: cancel!');
    setIsDragging(true);
    timeout.current && clearTimeout(timeout.current);
  }, []);

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),

    // onMouseMove: (e) => cancel(e),
    onMouseMove: (e) => () => {},
    onTouchMove: (e) => cancel(e),

    // onMouseLeave: (e) => clear(e, false),
    onMouseLeave: (e) => () => {},
    onMouseUp: (e) => clear(e),
    onTouchEnd: (e) => clear(e),
  };
};

const isTouchEvent = (event) => {
  return 'touches' in event;
};

const isMouseEvent = (event) => {
  console.log('mouseEvent');
  return 'button' in event && 'buttons' in event;
};

const preventDefault = (event) => {
  if (!isTouchEvent(event) && !isMouseEvent(event)) return;

  if (isTouchEvent(event)) {
    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  } else if (isMouseEvent(event)) {
    if (event.button && event.preventDefault) {
      event.preventDefault();
    }
  } else return;
};

export default useLongPress;
