import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '../Modal';
import { CreateButton } from '../NewGoal';
// import classes from '../NewGoal.module.css';
import ModalTextInput from '../ModalTextInput';

export default function MenuForChangeColumn({
  children,
  columns,
  setColumns,
  item,
  onChangeColumn,
  sourceIndex,
  onAddNewColumn,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [newName, setNewName] = useState(null);
  const [showNewNameModal, setNewNameModal] = useState(false);
  // const newColumnNameRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectionClick = (event, index) => {
    // alert(name);
    const nextColumns = [...columns];
    nextColumns[index] = setColumns([...columns]);
  };

  return (
    <div>
      {showNewNameModal && (
        <ModalTextInput
          onModalClose={() => {
            setNewNameModal(false);
          }}
          submit={(newName) => {
            onAddNewColumn(newName);
            // handleClose();
            // setNewNameModal(false);
          }}
        />
      )}
      <div
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {children}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {columns.map((el, index) => {
          if (sourceIndex === index) return undefined;
          return (
            <MenuItem
              // disabled={sourceIndex === index}
              onClick={(e) => {
                onChangeColumn({ droppableId: index });
                // handleSelectionClick(e, index);
                handleClose();
              }}
            >
              {el.name}
            </MenuItem>
          );
        })}
        <MenuItem
          // disabled={sourceIndex === index}
          onClick={(e) => {
            // onChangeColumn({ droppableId: index });
            // handleSelectionClick(e, index);

            setNewNameModal(true);
            handleClose();
            // onAddNewColumn();
            // handleClose();
          }}
        >
          + Create New
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
}
