import React, { useState, useEffect, useRef } from 'react';
import './TimerOverlay.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import VideocamIcon from '@mui/icons-material/Videocam';

export default function TimerOverlay({
  id,
  label,
  setSectionBeingTimed,
  setActiveSections,
  isSectionBeingTimed,
  playVideoHandler,
}) {
  let data = JSON.parse(localStorage.getItem('stopwatch-new'));
  const timeStorage = data ? data[id] : undefined;

  let startTime = timeStorage?.startTime || new Date().getTime();
  //   let startTime = timeStorage?.startTime || 0;
  const savedTime = timeStorage?.savedTime;
  const [isActive, setIsActive] = useState(() => {
    // return true;
    return timeStorage ? timeStorage.isActive : true;
  });
  const [isPaused, setIsPaused] = useState(() => {
    // return false;
    return timeStorage ? timeStorage.isPaused : false;
  });
  const countRef = useRef(0);

  const [time, setTime] = useState(() => {
    if (isActive) {
      if (isPaused) {
        return savedTime;
      } else {
        return (new Date().getTime() - startTime) / 1000;
      }
    } else return 0;
  });

  useEffect(() => {
    // pause section if it is not the current section being times
    console.log('🌈🌈🌈🌈🌈');
    console.log({ id });
    console.log({ isPaused });
    console.log({ isSectionBeingTimed });
    data = JSON.parse(localStorage.getItem('stopwatch-new'));
    if (!isPaused && !isSectionBeingTimed && countRef.current !== 0) {
      console.log('PAUSING: ', label);
      handlePauseResume(false);
    }
    countRef.current++;
    // console.log({ count });
  }, [isSectionBeingTimed]);

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      const onTopTimer = document.getElementById('on-top-timer');
      onTopTimer.innerText = formatTime(time);
      interval = setInterval(() => {
        setTime((time) => {
          const nextTime = time + 1;
          if (onTopTimer) {
            onTopTimer.innerText = formatTime(nextTime);
          }
          return nextTime;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  useEffect(() => {
    if (!isPaused) {
      setSectionBeingTimed({ label: label, id: id });
    }
  }, []);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
    setTime(0);
    let startTime = new Date().getTime();
    data = JSON.parse(localStorage.getItem('stopwatch-new'));
    const nextDataObj = { ...data };
    nextDataObj[id] = {
      isActive: true,
      isPaused: false,
      startTime,
      label,
    };
    localStorage.setItem('stopwatch-new', JSON.stringify(nextDataObj));
  };

  useEffect(() => {
    if (!timeStorage) handleStart();
  }, []);

  const handlePauseResume = (updateState = true) => {
    data = JSON.parse(localStorage.getItem('stopwatch-new'));
    console.log('handlePauseResume for Id: ', id);
    if (updateState) setSectionBeingTimed({ label: label, id: id });
    console.log('🐶 pause handler: ', isPaused);
    if (isPaused) {
      const nextDataObj = { ...data };
      nextDataObj[id] = {
        startTime: new Date().getTime() - time * 1000,
        isPaused: false,
        isActive,
        label,
      };
      localStorage.setItem('stopwatch-new', JSON.stringify(nextDataObj));
    } else {
      console.log('is not paused but will be for label: ', label);
      console.log({ data });
      const nextDataObj = { ...data };
      nextDataObj[id] = {
        startTime,
        isPaused: true,
        isActive,
        savedTime: time,
        label,
      };
      localStorage.setItem('stopwatch-new', JSON.stringify(nextDataObj));
      // if (!updateState) {
      //   setTimeout(() => {
      //     localStorage.setItem('stopwatch-new', JSON.stringify(nextDataObj));
      //   }, 2000);
      // }
    }
    setIsPaused(!isPaused);
    // setIsPaused((prev) => !prev);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
    const nextDataObj = { ...data };
    nextDataObj[id] = null;
    localStorage.setItem('stopwatch-new', JSON.stringify(nextDataObj));
  };

  return (
    <div className="purple-overlay">
      <div className="button-purple-overlay" />
      <ClearIcon
        onClick={() => {
          setActiveSections((prev) => prev.filter((el) => el !== id));

          const data = JSON.parse(localStorage.getItem('stopwatch-new'));
          delete data[id];
          // console.log({ data });
          localStorage.setItem('stopwatch-new', JSON.stringify(data));
          if (Object.keys(data)?.length === 0) {
            setSectionBeingTimed(null);
          }
        }}
        className="button-purple-overlay"
        style={{ pointerEvents: 'all', cursor: 'pointer', fontSize: '40px' }}
      />
      {/* <ReplayIcon
        onClick={handleStart}
        className="button-purple-overlay"
        style={{ pointerEvents: 'all', cursor: 'pointer', fontSize: '40px' }}
      /> */}
      <div style={{ width: '120px', textAlign: 'center' }}>
        {formatTime(time)}
      </div>
      {isPaused ? (
        <PlayArrowIcon
          className="button-purple-overlay"
          onClick={handlePauseResume}
          style={{ pointerEvents: 'all', cursor: 'pointer', fontSize: '40px' }}
        />
      ) : (
        <PauseIcon
          className="button-purple-overlay"
          onClick={handlePauseResume}
          style={{ pointerEvents: 'all', cursor: 'pointer', fontSize: '40px' }}
        />
      )}

      <VideocamIcon
        onClick={playVideoHandler}
        className="button-purple-overlay"
        style={{ pointerEvents: 'all', cursor: 'pointer', fontSize: '40px' }}
      />

      {/* <div className="timer-overlay-buttons">
        <PlayArrowIcon style={{ fontSize: 'inherit' }} />
        <ReplayIcon style={{ fontSize: 'inherit' }} />
      </div> */}
      {/* <div className="play-video-over-purple">play video</div> */}
    </div>
  );
}

export function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedMinutes =
    hours > 0 ? `${minutes.toString().padStart(2, '0')}` : minutes;
  const formattedSeconds = secs.toString().padStart(2, '0');

  if (hours > 0) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}
