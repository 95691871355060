import { useEffect, useState } from 'react';

const useMousePosition = (global = false) => {
  const [mouseCoords, setMouseCoords] = useState({
    x: null,
    y: null,
  });

  const handleCursorMovement = (event) => {
    // event.preventDefault();
    // event.stopPropagation();
    let rect = event.target.getBoundingClientRect();
    let clientX, clientY;

    if (event.type === 'touchmove') {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    setMouseCoords({
      x: clientX - rect.left,
      y: clientY - rect.top,
    });
  };

  useEffect(() => {
    if (global) {
      window.addEventListener('mousemove', handleCursorMovement);
      window.addEventListener('touchmove', handleCursorMovement);

      return () => {
        window.removeEventListener('mousemove', handleCursorMovement);
        window.removeEventListener('touchmove', handleCursorMovement);
      };
    }
  }, [global]);

  return [mouseCoords, handleCursorMovement];
};

export default useMousePosition;
