import React, { useEffect, useRef, useState } from 'react';
import classes from './NewVideoForm.module.css';
import useInput from '../hooks/use-input';
import axios from 'axios';
import VideoContainerForUpload from './video_components/VideoContainerForUpload';
import Modal from './Modal';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

const NewVideoForm = ({ onModalClose, src, selectedFile }) => {
  // const onNewVidClickHandler = (e) => {
  //   const reader = new FileReader();
  //   const file = e.target.files[0];
  //   doTranscode(file);
  // };

  const [compressedVideo, setComprssedVideo] = useState(null);
  // fileInput.addEventListener('change', doTranscode);

  const videoPlayerRef = useRef(null);
  const messageRef = useRef(null);

  const [videoSrc, setVideoSrc] = useState('');
  const [message, setMessage] = useState('Click Start to transcode');
  const ffmpeg = createFFmpeg({
    log: true,
  });

  const doTranscode = async () => {
    setMessage('Loading ffmpeg-core.js');
    await ffmpeg.load();
    setMessage('Start transcoding');

    // ffmpeg.FS('writeFile', 'test.avi', src);
    // const file =

    ffmpeg.FS('writeFile', 'test.avi', await fetchFile(selectedFile));
    // ffmpeg.FS('writeFile', 'test.avi', await fetchFile('./simpleVideo.mp4'));
    await ffmpeg.run('-i', 'test.avi', 'test.mp4');
    setMessage('Complete transcoding');
    const data = ffmpeg.FS('readFile', 'test.mp4');
    const videoBlob = new Blob([data.buffer], { type: 'video/mp4' });
    console.log('data: ', videoBlob);
    console.log('data for selected file: ', selectedFile);
    setComprssedVideo(videoBlob);
    setVideoSrc(
      URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }))
    );
  };
  // const [showVideo, setShowVideo] = useState(true);

  return (
    <Modal
      onModalClose={onModalClose}
      xColor={'white'}
      paddingLeft={0}
      paddingRight={0}
    >
      <main className={classes.container}>
        <VideoContainerForUpload
          ref={videoPlayerRef}
          url={src}
          // selectedFile={compressedVideo}
          selectedFile={compressedVideo || selectedFile}
          onModalClose={onModalClose}
        />
      </main>

      {/* <div className="App">
        <p />
        <video src={videoSrc} controls></video>
        <br />
        <button onClick={doTranscode}>Start</button>
        <p>{message}</p>
        <p ref={messageRef}></p>
      </div> */}
    </Modal>
  );
};

export default NewVideoForm;
