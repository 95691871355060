import classes from './Auth.module.css';
import useInput from '../hooks/use-input';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';

import { authActions } from '../store/auth';
import { useEffect, useRef, useState } from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useFetchOnDemand from '../hooks/useFetchOnDemand';
import AuthBackground from './AuthBackground';
import AuthModal from './AuthModal';
import { tourActions } from '../store/tour';
import { videoActions } from '../store/video';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Auth = ({ setShowResetPasswordPage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isDemo, setIsDemo] = useState(location?.pathname === '/demo');

  const [isMobile] = useSelector((state) => [state.settings.isMobile]);

  useEffect(() => {
    if (isDemo) {
      // alert('demo!');
      const demoEmail = `demo${Math.floor(Math.random() * 9999)}@example.com`;
      setEmail(demoEmail);
      setPassword(generatePass());
    }
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const passwordRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = (e) => {
    setShowPassword((show) => !show);
    // e.currentTarget.setSelectionRange(password.length, password.length);
    // passwordRef.current.blur();

    // passwordRef.current.style.caretColor = 'transparent';
    if (passwordRef.current) {
      passwordRef.current.style.caretColor = 'transparent';
    }

    setTimeout(() => {
      if (passwordRef.current) {
        const length = password.length;
        passwordRef.current.setSelectionRange(length, length);
        passwordRef.current.style.caretColor = 'inherit';
      }
    }, 0);
    // if (passwordRef) {
    //   console.log('passwordRef: ', passwordRef);
    //   // passwordRef.current.setSelectionRange(password.length, password.length);
    // }
  };

  useEffect(() => {
    if (isDemo && !email.includes('demo')) {
      setIsDemo(false);
      console.log('change from demo to false');
    } else if (location?.pathname === '/demo' && email.includes('demo')) {
      setIsDemo(true);
      console.log('change from demo to true');
    }
  }, [email]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // console.log({ email });
  // console.log({ password });

  const {
    enteredValue: enteredEmail,
    hasError: emailHasError,
    onBlurHandler: emailBlurHandler,
    onChangeHandler: emailChangeHandler,
    isValidValue: enteredEmailIsValid,
  } = useInput((value) => value.includes('@'));

  const {
    enteredValue: enteredPassword,
    hasError: passwordHasError,
    onBlurHandler: passwordBlurHandler,
    onChangeHandler: passwordChangeHandler,
    isValidValue: enteredPasswordIsValid,
  } = useInput((value) => value.length >= 6);

  const dispatch = useDispatch();

  let formIsValid = true;
  if (enteredPasswordIsValid && enteredEmailIsValid) formIsValid = true;

  const {
    loading: sending,
    error: error,
    sendData: authenticate,
  } = useFetchOnDemand(
    isDemo
      ? `${process.env.REACT_APP_BACKEND}/api/v1/users/signupDemo`
      : `${process.env.REACT_APP_BACKEND}/api/v1/users/login`,
    (data) => {
      // console.log('user: ', data.data.user);
      dispatch(authActions.login(data.data.user));
      dispatch(
        videoActions.initializePracticeDictionary(
          data.data.user.practiceDictionary
        )
      );
      if (isMobile) {
        dispatch(tourActions.checkForUnseenTours(data.data.user.toursViewed));
      }
      // if (isDemo) {
      navigate('/');
      // }

      // dispatch(tourActions.checkForUnseenTours(data.data.user.toursViewed));
    },
    {
      method: 'POST',
      // mode: 'cors',
      // withCredentials: true,

      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(
        isDemo
          ? {
              name: 'Demooo',
              email: email,
              password: password,
              passwordConfirm: password,
              // email: enteredEmail,
              // password: enteredPassword,
            }
          : {
              email: email,
              password: password,
              // email: enteredEmail,
              // password: enteredPassword,
            }
      ),
    },
    [email, password, isDemo]
  );

  console.log({ error });

  const loginHandler = (event) => {
    event.preventDefault();

    if (formIsValid) {
      authenticate();
    }
  };

  return (
    <AuthModal title="Login">
      <main className={classes.auth}>
        <section>
          <form className={classes['auth-form']} onSubmit={loginHandler}>
            <div className={classes['form-elements']}>
              <TextField
                value={email}
                onChange={(e) => {
                  console.log('set email');
                  setEmail(e.target.value);
                }}
                id="standard-basic"
                label="Email"
                variant="standard"
                style={{ width: '100%', marginBottom: '5px' }}
                InputLabelProps={{ shrink: true }}
              />

              <FormControl style={{ width: '100%' }} variant="standard">
                <InputLabel shrink htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  value={password}
                  inputRef={passwordRef}
                  // onFocus={(e) =>
                  //   passwordRef.current.setSelectionRange(
                  //     password.length,
                  //     password.length
                  //   )
                  // }
                  onChange={(e, val) => {
                    setPassword(e.target.value);
                  }}
                  id="standard-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <button
              disabled={sending}
              className={`${classes['login-button']} ${
                sending ? classes['logging-in'] : ''
              }`}
            >
              Login
            </button>
          </form>
          {error && (
            <div className={classes.error}>
              {error.message || 'unknown error'}
            </div>
          )}
          {/* {error && <div>{`Error logging in: ${error}`}</div>} */}
        </section>
      </main>
    </AuthModal>
  );
};

export default Auth;

/* Function to generate combination of password */
function generatePass() {
  let pass = '';
  let str =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$';

  for (let i = 0; i <= 8; i++) {
    let char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
}

console.log(generatePass());
