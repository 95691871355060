import React from 'react';
import { motion } from 'framer-motion';

const containerStyle = {
  position: 'relative',
  width: '3rem',
  height: '3rem',
  boxSizing: 'border-box',
  //   backgroundColor: '',
};

const textStyle = {
  position: 'absolute',
  // width: '100%',
  left: 0,
  right: 0,
  height: '5rem',
  top: '4rem',
  color: 'white',
  boxSizing: 'border-box',
  transform: 'translateY(50px)',
  top: '50%',
  // textAlign: 'center',
  //   backgroundColor: '',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // opacity: 0.8,
};

const circleStyle = {
  display: 'block',
  width: '3rem',
  height: '3rem',
  border: '0.5rem solid #AAAAAA44',
  borderTop: '0.5rem solid rgb(92, 37, 202)',
  borderRadius: '50%',
  position: 'absolute',
  boxSizing: 'border-box',
  top: 0,
  left: 0,
};

const spinTransition = {
  repeat: Infinity,
  ease: 'linear',
  duration: 1,
};

const outerContainerStyle = {
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  top: '50%',
  zIndex: 200,
};

export default function CircleLoader({ children }) {
  return (
    <>
      {' '}
      <div style={outerContainerStyle}>
        <div style={containerStyle}>
          <motion.span
            style={circleStyle}
            animate={{ rotate: 360 }}
            transition={spinTransition}
          />
        </div>
      </div>
      <div style={textStyle}>{children}</div>
    </>
  );
}
